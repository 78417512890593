import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@cimpress/react-components'
import { SnackbarController } from '../../common/components/SnackbarController'
import AwaitingModal from './AwaitingModal'
import styles from './CalendarDeleteModal.module.css'

export interface Props {
  calendarId: string
  calendarName: string
  children?: React.ReactNode
  onClose: () => void
  onDelete(calendarId: string): Promise<void>
}

export function CalendarDeleteModal(props: Props) {
  const { t } = useTranslation()

  const onDelete = async () => {
    try {
      await props.onDelete(props.calendarId)
      SnackbarController.show(
        t('calendars.deleteModal.success', {
          name: props.calendarName,
        }),
        'success'
      )
    } catch (err) {
      SnackbarController.show(t('calendars.deleteModal.error'), 'danger')
    }
    props.onClose()
  }

  const complexSetup = !!props.children

  const footer = [
    {
      handler: props.onClose,
      text: t('common.cancel'),
    },
    {
      type: 'primary',
      handler: onDelete,
      text: t('common.delete'),
      className: complexSetup ? '' : styles.danger,
      disabled: complexSetup,
    },
  ]

  return (
    <AwaitingModal
      footer={footer}
      onRequestHide={props.onClose}
      closeOnOutsideClick={true}
      show={true}
      title={t('calendars.deleteModal.title')}
    >
      {props.children ? (
        props.children
      ) : (
        <>
          <p>
            {t('calendars.deleteModal.confirm', {
              name: props.calendarName,
            })}
          </p>
          <Alert
            type="warning"
            dismissible={false}
            message={t('calendars.deleteModal.removeWarning')}
          />
        </>
      )}
    </AwaitingModal>
  )
}

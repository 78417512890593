import { Button, Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './FutureNonWorkingDayDialogTooltip.module.css'

interface Props {
  onClose: () => void
  children?: React.ReactNode
}

export default function FutureNonWorkingDayDialogTooltip(props: Props) {
  const { t } = useTranslation()

  const onClose = (e: any) => props.onClose()
  const tooltipContents = (
    <>
      <div>
        {t(
          'calendars.weekly.futureWorkingDayModal.convertToWorkingDayToAddPickup'
        )}
      </div>
      <div className={styles.footer}>
        <Button type="outline-secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  )

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip
        contents={tooltipContents}
        direction="top"
        variety="popover"
        show={true}
        style={{ display: 'block', width: '100%', height: '100%' }}
        onClickOutside={props.onClose}
        constraints={[
          {
            to: 'window',
            attachment: 'together',
            pin: true,
          },
        ]}
      >
        {props.children}
      </Tooltip>
    </div>
  )
}

import { Alert } from '@cimpress/react-components'
import * as React from 'react'
import { useLogisticsLocation } from '../../../locations/LocationContext'

export function VenloOnlyWarning() {
  const { logisticsLocation } = useLogisticsLocation()

  if (logisticsLocation.id === 'venlo') {
    return (
      <Alert
        type="warning"
        dismissible={false}
        message={
          <>
            Please be aware that when you are adding , moving, or removing a
            Pickup, it will impact sorting!
            <br />
            Please reach out to{' '}
            <a href="mailto:ShipSorterSupport@cimpress.com">
              ShipSorterSupport@cimpress.com
            </a>
            .
          </>
        }
      />
    )
  }

  return <></>
}

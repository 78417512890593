import * as React from 'react'
import {
  Button,
  Modal,
  Checkbox,
  RadioGroup,
  Radio,
  TextField,
  Table,
} from '@cimpress/react-components'
import IconCalendarHome from '@cimpress-technology/react-streamline-icons/lib/IconCalendarHome'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { locations as locationsClient } from '@cimpress-technology/logistics-configuration-client'
import LinkIcon from '../locations/components/view-location-page/LinkIcon'
import useToggle from '../common/hooks/useToggle'
import DatePicker from '../common/components/DatePicker'
import AnchorButton from '../common/components/AnchorButton'
import {
  getWorkingDaysCalendar,
  wdUpdater,
} from '../common/proxy/calendars-store'
import { SnackbarController } from '../common/components/SnackbarController'
import { logError } from '../common/logger'
import { CountryFlag } from '../common/components/CountryFlag'
import { useNetwork } from './NetworkContext'

export default function NetworkWorkingDays() {
  const { t } = useTranslation()

  const [toggle, setToggle] = useToggle(false)

  const onClose = () => {
    setToggle(undefined)
  }

  return (
    <>
      <li key="working-days" className="list-group-item">
        <AnchorButton
          onClick={setToggle}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <LinkIcon icon={IconCalendarHome} />
          {t('networks.editWorkingDaysCalendar')}
        </AnchorButton>
      </li>
      {toggle && <NetworkWorkingDaysEditor onClose={onClose} />}
    </>
  )
}

type UpdateType = 'working' | 'non-working'

interface Props {
  onClose: () => void
}

function NetworkWorkingDaysEditor(props: Props) {
  const { t } = useTranslation()

  const { locations } = useNetwork()
  const [date, setDate] = React.useState(moment())
  const [selectedLocationsMap, setSelectedLocationsMap] = React.useState(
    arrayToIdMap(locations)
  )
  const [updateType, setUpdateType] = React.useState<UpdateType>('working')
  const [saving, setSaving] = React.useState(false)
  const [description, setDescription] = React.useState('')

  const onDescriptionChange = (e: React.FormEvent<HTMLInputElement>) =>
    setDescription(e.currentTarget.value)

  const setCurrent = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: UpdateType
  ) => {
    setUpdateType(value)
  }

  const onSave = async () => {
    setSaving(true)
    const selectedLocations = Object.values(selectedLocationsMap)

    if (selectedLocations.length > 0) {
      const calendars = await Promise.all(
        selectedLocations.map(location =>
          getWorkingDaysCalendar(location.workingDaysCalendar.id)
        )
      )
      try {
        await Promise.all(
          calendars.filter(defined).map(calendar =>
            wdUpdater(calendar, workingDaysCalendar => {
              const currentOverwrite =
                workingDaysCalendar.overwrites[date.format('YYYY-MM-DD')]
              workingDaysCalendar.overwrites[date.format('YYYY-MM-DD')] = {
                description,
                ...currentOverwrite,
                isWorkingDay: updateType === 'working',
              }
            })
          )
        )

        SnackbarController.show('Working day calendars are modified', 'success')
      } catch (err) {
        SnackbarController.show(
          'Error while editing multiple calendars',
          'danger'
        )
        logError('Error while editing multiple calendars', err)
      }
    }

    setSaving(false)
    props.onClose()
  }

  const footer = (
    <>
      <Button
        type="default"
        onClick={props.onClose}
        key="cancel-btn"
        disabled={saving}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={onSave} key="save-btn" disabled={saving}>
        Save
      </Button>
    </>
  )

  const columns = [
    {
      Header: () => {
        const checked =
          Object.values(selectedLocationsMap).length === locations.length
        const toggleAll = () => {
          setSelectedLocationsMap(checked ? {} : arrayToIdMap(locations))
        }

        return <Checkbox checked={checked} onChange={toggleAll} />
      },
      width: 70,
      sortable: false,
      Cell: (row: any) => {
        const location = row.original

        const toggleCheckbox = () => {
          const updated = { ...selectedLocationsMap }
          if (updated[location.id]) {
            delete updated[location.id]
          } else {
            updated[location.id] = location
          }

          setSelectedLocationsMap(updated)
        }

        return (
          <Checkbox
            key={location.id}
            checked={!!selectedLocationsMap[location.id]}
            onChange={toggleCheckbox}
          />
        )
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      sortMethod: (a: string, b: string) => a.localeCompare(b),
    },
    {
      Header: 'Country',
      width: 100,
      sortable: true,
      accessor: 'address',
      Cell: (row: { original: locationsClient.models.LocationWithLinks }) => {
        const countryCode = row.original.address.countryCode

        return (
          <>
            {countryCode} <CountryFlag countryCode={countryCode} />
          </>
        )
      },
      sortMethod: (
        a: locationsClient.models.Address,
        b: locationsClient.models.Address
      ) => a.countryCode.localeCompare(b.countryCode),
    },
  ]

  return (
    <Modal
      show={true}
      closeButton={true}
      closeOnOutsideClick={false}
      onRequestHide={props.onClose}
      title={t('networks.editWorkingDaysCalendar')}
      style={{ width: '726px' }}
      footer={footer}
    >
      <div className="row">
        <div className="col-xs-4">
          <h5>{t('common.date')}</h5>
          <DatePicker currentDate={date} onDateChange={setDate}>
            {date.format('YYYY-MM-DD')}
          </DatePicker>

          <h5>{t('networks.workingDaysCalendarModal.setSelectedDateAs')}</h5>
          <RadioGroup
            onChange={setCurrent}
            defaultSelected="working"
            name="changeType"
          >
            <Radio
              label={t('networks.workingDaysCalendarModal.nonWorkingDay')}
              value="non-working"
            />
            <Radio
              label={t('networks.workingDaysCalendarModal.workingDay')}
              value="working"
            />
          </RadioGroup>
          <TextField
            value={description}
            label={t('calendars.yearly.addExceptionDialog.anyComments')}
            onChange={onDescriptionChange}
            type="textarea"
          />
        </div>
        <div className="col-xs-8">
          <h5>{t('locations.locations')}</h5>
          <Table
            columns={columns}
            data={locations}
            sortable={true}
            resizable={false}
            showPagination={false}
            noDataText="No location in this network"
            defaultSorted={[{ id: 'name', desc: false }]}
            pageSize={locations.length > 0 ? locations.length : 10}
          />
        </div>
      </div>
    </Modal>
  )
}

function arrayToIdMap(locations: locationsClient.models.LocationWithLinks[]) {
  return locations.reduce((acc, location) => {
    acc[location.id] = location

    return acc
  }, {} as { [key: string]: locationsClient.models.LocationWithLinks })
}

function defined<T>(x: T | undefined): x is T {
  return x !== undefined
}

import * as React from 'react'
import { Alert, TextField } from '@cimpress/react-components'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../common/components/ValidationContainer'
import { toBSStyle, ValidationStatus } from '../../common/helpers/validation'
import AwaitingModal from './AwaitingModal'
import CarrierAndServicesSelector from './CarrierAndServicesSelector'
import { useCalendarEdit, Props } from './calendar-edit-commons'
export default function CalendarEditModal(props: Props) {
  const { t } = useTranslation()
  const {
    calendarName,
    onCalendarNameChange,
    carrierSelection,
    onCarrierServicesChange,
    onCarrierChange,
    validation,
    validate,
  } = useCalendarEdit(
    {
      ...props.editCalendar.carrierSelection,
    },
    props.editCalendar.calendarName,
    props.carrierAccountTypes,
    props.carrierServicesToCalendarNames
  )
  const onSave = async () => {
    if (validate()) {
      await props.onSave({
        calendarName,
        carrierSelection,
      })
      props.onClose()
    }
  }
  const footer = props.editable
    ? [
        {
          handler: props.onClose,
          text: t('common.cancel'),
        },
        {
          type: 'primary',
          handler: onSave,
          savingText: t('common.saving'),
          text: t('common.saveChanges'),
          disabled: Object.values(validation)
            .map(v => v.status)
            .some(status => status !== ValidationStatus.Valid),
        },
      ]
    : [
        {
          type: 'primary',
          handler: props.onClose,
          text: t('common.close'),
        },
      ]
  const showAlert = (
    props.editCalendar.carrierSelection.carrierServices ?? []
  ).some(cs => !(carrierSelection.carrierServices ?? []).includes(cs))
  const isComplex = !!props.children

  return (
    <AwaitingModal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      title={props.title}
      style={{ width: '526px' }}
      footer={footer}
    >
      <ValidationContainer validationResult={validation.name}>
        <TextField
          name="name"
          label={t('calendars.weekly.addPickupCalendarModal.scheduleName')}
          value={calendarName}
          onChange={onCalendarNameChange}
          autoFocus={true}
          required={true}
          bsStyle={toBSStyle(validation.name)}
          maxLength={100}
          disabled={!props.editable}
        />
      </ValidationContainer>
      {isComplex ? (
        props.children
      ) : (
        <>
          <CarrierAndServicesSelector
            isDisabled={!props.editable}
            carrierSelection={carrierSelection}
            carrierAccountTypes={props.carrierAccountTypes}
            carrierServicesToCalendarNames={
              props.carrierServicesToCalendarNames
            }
            onCarrierChange={onCarrierChange}
            onCarrierServicesChange={onCarrierServicesChange}
            validation={validation}
            calendarName={calendarName}
          />
          {showAlert && (
            <Alert
              type="warning"
              dismissible={false}
              message={t('calendars.removeCarrierServiceWarning')}
            />
          )}
        </>
      )}
    </AwaitingModal>
  )
}

import * as coam from '@cimpress-technology/coam-sapidus'
import { ResourceTypes } from '@cimpress-technology/coam-sapidus/dist/coam-models'
import IconMessagesBubbleQuestion from '@cimpress-technology/react-streamline-icons/lib/IconMessagesBubbleQuestion'
import { Button, colors, Modal } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import auth from '../../common/auth'
import OpenInNewTab from '../../common/components/OpenInNewTab'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import FourOhFourPage from '../../common/FourOhFourPage'
import { CanonicalCoamGroupMember, CanonicalCoamUser } from '../models'
import UserEditorDrawer from './UserEditorDrawer'
import UserList from './UserList'

interface Props extends WithTranslation {
  resourceId: string
  isResourceEditable: boolean
  resourceGroupName: string
  resourceType: ResourceTypes
  roles: string[]
  addUser: (email: string, role: string) => Promise<void>
  removeUser: (email: string) => Promise<void>
}

interface State {
  loading: boolean
  coamGroup?: coam.models.DetailedCoamGroup
  addingUser: boolean
  userToDelete?: CanonicalCoamUser
  userToEdit?: CanonicalCoamGroupMember
  blockUI: boolean
}

class PermissionsEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { loading: true, addingUser: false, blockUI: false }
  }

  public render() {
    if (this.state.loading) {
      return <Preloader />
    }

    if (!this.state.coamGroup) {
      return <FourOhFourPage />
    }

    const startAddingUser = () => this.setState({ addingUser: true })
    const saveUser = async (user: CanonicalCoamUser, role: string) => {
      await this.saveUser(user, role)
    }

    const setUserToDelete = (user: CanonicalCoamUser) =>
      this.setState({ userToDelete: user })
    const unsetUserToDelete = () => this.setState({ userToDelete: undefined })
    const deleteUser = () => this.removeUser(this.state.userToDelete!)
    const setUserToEdit = (user: CanonicalCoamGroupMember) =>
      this.setState({ userToEdit: user })

    const userEditorOnClose = () =>
      this.setState({
        userToEdit: undefined,
        addingUser: false,
      })

    return (
      <>
        <div className="row">
          <div className="col-xs-4 col-lg-6">
            <h4 style={{ display: 'inline-block', marginRight: '16px' }}>
              {this.props.t('userManagement.userManagement')}
            </h4>
          </div>
          <div className="col-xs-8 col-lg-6 text-right">
            <OpenInNewTab href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/329254595/Managing+Logistics+Users">
              <IconMessagesBubbleQuestion
                color={colors.ocean.base}
                style={{ top: '.125em' }}
              />{' '}
              <span>{this.props.t('userManagement.learnMore')}</span>
            </OpenInNewTab>
            {this.props.isResourceEditable ? (
              <Button
                type="primary"
                onClick={startAddingUser}
                style={{ marginLeft: '24px' }}
              >
                {this.props.t('userManagement.addUser')}
              </Button>
            ) : null}
          </div>
        </div>
        <div className="card" style={{ marginTop: '20px' }}>
          <UserList
            users={this.state.coamGroup!.members as CanonicalCoamGroupMember[]}
            editable={this.props.isResourceEditable}
            onDelete={setUserToDelete}
            onEdit={setUserToEdit}
          />
          <UserEditorDrawer
            show={this.state.addingUser || this.state.userToEdit !== undefined}
            existingMembers={
              this.state.coamGroup!.members as CanonicalCoamGroupMember[]
            }
            userToEdit={this.state.userToEdit}
            onClose={userEditorOnClose}
            onSave={saveUser}
            blockUI={this.state.blockUI}
            roles={this.props.roles}
          />
          {this.state.userToDelete ? (
            <RemoveUserConfirmationModal
              user={this.state.userToDelete}
              stopDeletingUser={unsetUserToDelete}
              deleteUser={deleteUser}
              blockUI={this.state.blockUI}
            />
          ) : null}
        </div>
      </>
    )
  }

  public async componentDidMount() {
    const coamGroupOverview = await coam.group.findCoamGroupByResourceAndName(
      {
        id: this.props.resourceId,
        type: this.props.resourceType,
      },
      this.props.resourceGroupName,
      auth.getAccessToken(),
      auth.getProfile().sub
    )
    if (!coamGroupOverview) {
      this.setState({
        loading: false,
      })

      return
    }
    const coamGroup = await coam.group.getCoamGroupDetails(
      coamGroupOverview!.id,
      auth.getAccessToken(),
      auth.getProfile().sub,
      true
    )
    this.setState({
      loading: false,
      coamGroup,
    })
  }

  private async reloadCoamGroup() {
    const coamGroup = await coam.group.getCoamGroupDetails(
      this.state.coamGroup!.id,
      auth.getAccessToken(),
      auth.getProfile().sub,
      true
    )
    this.setState({
      coamGroup,
    })
  }

  private async saveUser(user: CanonicalCoamUser, role: string) {
    this.setState({ blockUI: true })
    try {
      await this.props.addUser(user.canonical_principal || user.principal, role)

      const successMessage = this.state.addingUser
        ? this.props.t('userManagement.messages.userHasBeenAdded', {
            username: getUserName(user),
            role,
          })
        : this.props.t('userManagement.messages.userHasBeenUpdated', {
            username: getUserName(user),
            role,
          })

      await this.reloadCoamGroup()
      this.setState({
        addingUser: false,
        userToEdit: undefined,
        blockUI: false,
      })

      SnackbarController.show(successMessage, 'success')
    } catch (e) {
      const errorMessage = this.state.addingUser
        ? this.props.t('userManagement.messages.failedToAddUser', {
            username: getUserName(user),
            role,
          })
        : this.props.t('userManagement.messages.failedToUpdate', {
            username: getUserName(user),
            role,
          })
      SnackbarController.show(errorMessage, 'danger')
      this.setState({ blockUI: false })
    }
  }

  private async removeUser(user: CanonicalCoamUser) {
    this.setState({ blockUI: true })
    try {
      await this.props.removeUser(user.principal)

      await this.reloadCoamGroup()

      SnackbarController.show(
        this.props.t('userManagement.messages.userHasBeenRemoved', {
          username: getUserName(user),
        })!,
        'success'
      )
      this.setState({ userToDelete: undefined, blockUI: false })
    } catch (e) {
      SnackbarController.show(
        this.props.t('userManagement.messages.failedToRemoveUser', {
          username: getUserName(user),
        })!,
        'danger'
      )
      this.setState({ blockUI: false })
    }
  }
}

interface RemoveUserConfirmationModalProps {
  user: CanonicalCoamUser
  blockUI: boolean

  stopDeletingUser(): void

  deleteUser(): void
}

function RemoveUserConfirmationModal(props: RemoveUserConfirmationModalProps) {
  const { t } = useTranslation()
  const username = getUserName(props.user)
  const label = props.user.is_client
    ? t('userManagement.removeConfirmationClient', { username })
    : t('userManagement.removeConfirmationUser', { username })

  return (
    <Modal
      show={true}
      onRequestHide={props.stopDeletingUser}
      closeButton={true}
      closeOnOutsideClick={false}
      title={t('userManagement.removeUser')}
      footer={
        <>
          {!props.blockUI ? (
            <Button type="default" onClick={props.stopDeletingUser}>
              Cancel
            </Button>
          ) : null}
          <Button
            type="primary"
            disabled={props.blockUI}
            onClick={props.deleteUser}
          >
            {!props.blockUI
              ? t('userManagement.removeUser')
              : t('userManagement.removingUser')}
          </Button>
        </>
      }
    >
      {label}
    </Modal>
  )
}

function getUserName(user: CanonicalCoamUser) {
  return user.profiles.length === 0
    ? user.principal || user.canonical_principal
    : user.profiles[0].name
}

export default withTranslation()(PermissionsEditor)

import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as models from '../common/models'
import { useLogisticsLocation } from '../locations/LocationContext'
import LocationEditor from '../locations/LocationEditor'
import { NetworkBreadCrumbItem } from './components/RouterWithBreadcrumbs'
import { SnackbarController } from './components/SnackbarController'
import FourOhFourPage from './FourOhFourPage'
import { logError } from './logger'
import { ServerError } from './models'

export default function EditLocationPageContainer() {
  const { t } = useTranslation()
  const [saving, setSaving] = React.useState(false)
  const [serverError, setServerError] = React.useState<ServerError>()
  const { logisticsLocation, updateLocation } = useLogisticsLocation()
  const history = useHistory()

  if (!logisticsLocation.editable) {
    return <FourOhFourPage />
  }

  const onSave = async (editedLocation: models.Location) => {
    setSaving(true)
    try {
      await updateLocation(editedLocation)
      SnackbarController.show(
        t('locations.logisticsLocationHasBeenUpdated'),
        'success'
      )
      history.push('/location/' + logisticsLocation.id)
    } catch (error) {
      logError('Error when saving location', error)
      setServerError(error.response.data)
      setSaving(false)
    }
  }

  const onCancel = () => {
    if (history.length === 1) {
      history.replace('')
    } else {
      history.goBack()
    }
  }

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <LocationEditor
        location={logisticsLocation}
        saving={saving}
        onSave={onSave}
        serverError={serverError}
        onCancel={onCancel}
      />
    </>
  )
}

import {
  Button,
  colors,
  shapes,
  Tag,
  Tooltip,
} from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import useHover from '../../../../common/hooks/useHover'
import {
  Interval,
  PickupCalendar,
  PickupCalendarDisplay,
} from '../../../models'
import EditPickupModal from '../EditPickupModal'
import RemovePickupModal from '../RemovePickupModal'
import { ModalTypes } from '../WeeklyCalendarContext'
import { mapTag } from './tag-mapper'
import styles from './WeeklyCalendar.module.css'

export default function WrapperPickupEvent(props: PickupEventProps) {
  const { hovered, bind } = useHover()
  const { modalType, setModal } = props

  const tooltipKey = `${modalType}-${props.start}-${props.calendarView.calendar.name}`

  const pickupEvent = (
    <PickupEvent {...props} style={{ cursor: 'pointer' }} onClick={setModal} />
  )

  const close = () => setModal()

  if (modalType === 'tooltip') {
    return (
      <Tooltip
        key={tooltipKey}
        show={true}
        style={{ display: 'block' }}
        contents={<TooltipContent {...props} />}
        onClickOutside={close}
        variety="popover"
      >
        {pickupEvent}
      </Tooltip>
    )
  } else if (modalType === 'remove') {
    return (
      <>
        {pickupEvent}
        <RemovePickupModal
          onClose={setModal}
          startTime={props.start}
          calendar={props.calendarView.calendar}
          tags={props.tags}
        />
      </>
    )
  } else if (modalType === 'edit') {
    return (
      <>
        {pickupEvent}
        <EditPickupModal
          onClose={setModal}
          startTime={props.start}
          calendar={props.calendarView.calendar}
          tags={props.tags}
          tagsEnabled={props.tagsEnabled}
        />
      </>
    )
  }

  const openTooltip = () => {
    setModal(props.start, 'tooltip', props.calendarView.calendar.id)
  }
  const defaultPickupEvent = (
    <PickupEvent
      {...props}
      onClick={openTooltip}
      style={{ cursor: 'pointer' }}
      {...bind}
    />
  )

  if (hovered) {
    const tagCount = props.tags!.length

    const contents = (
      <>
        {`${props.calendarView.calendar.name} - ${props.start.format('HH:mm')}`}
        {tagCount > 0 ? (
          <div>
            Tag{tagCount > 1 ? 's' : ''}:{' '}
            {`${props.tags!.map(mapTag).join(', ')}`}
          </div>
        ) : (
          ''
        )}
      </>
    )

    return (
      <Tooltip
        key={tooltipKey}
        tooltipStyle={{ position: 'fixed' }}
        contents={contents}
        style={{ display: 'block' }}
      >
        {defaultPickupEvent}
      </Tooltip>
    )
  } else {
    return defaultPickupEvent
  }
}

function generateDescription(calendar: PickupCalendar, start: moment.Moment) {
  const exceptionData = calendar.overwrites[start.format('YYYY-MM-DD')]
  if (exceptionData) {
    return <div>Extra pickup at {start.format('HH:mm')}</div>
  }

  return (
    <div>
      <b>Every {start.format('dddd')}</b> at <b>{start.format('HH:mm')}</b>
    </div>
  )
}

function generateTags(tagArray: string[]) {
  if (tagArray.length === 0) {
    return <></>
  }

  return (
    <div className="padding-top">
      <h5>Tags:</h5>
      <div>
        {tagArray.map(mapTag).map(tag => (
          <Tag value={tag} key={tag} />
        ))}
      </div>
    </div>
  )
}

function TooltipContent(props: PickupEventProps) {
  const { editable, setModal, start, calendarView: calendar, tags } = props

  const close = () => setModal()
  const openRemove = () => setModal(start, 'remove', calendar.calendar.id)
  const openEdit = () => setModal(start, 'edit', calendar.calendar.id)
  const disableButtons = isInPast(props.timeInLocationTimezone, start)
  const showTooltip = disableButtons ? undefined : false

  const buttonsRow = (
    <div className="modal-footer">
      <span style={{ marginRight: '16px' }}>
        <Tooltip show={showTooltip} contents="Can't change past pickups">
          <Button
            type="outline-secondary"
            onClick={openRemove}
            disabled={disableButtons}
          >
            <div>
              <i className="fa fa-trash" />
              Remove
            </div>
          </Button>
        </Tooltip>
      </span>
      <span>
        <Tooltip show={showTooltip} contents="Can't change past pickups">
          <Button onClick={openEdit} disabled={disableButtons}>
            <div>
              <i className="fa fa-pencil" />
              Edit
            </div>
          </Button>
        </Tooltip>
      </span>
    </div>
  )
  const buttons = editable ? buttonsRow : <></>

  return (
    <div>
      <div className="modal-header">
        <div className="modal-title">{calendar.calendar.name}</div>
        <button className="close" onClick={close}>
          <shapes.hamburger.Close
            width="18px"
            color={colors.granite.base}
            cropped={true}
          />
        </button>
      </div>
      <div className="modal-body">
        <div>{generateDescription(calendar.calendar, start)}</div>
        {generateTags(tags!)}
      </div>
      {buttons}
    </div>
  )
}

function isInPast(
  timeInLocationTimezone: moment.Moment,
  startTime: moment.Moment
) {
  return timeInLocationTimezone.isAfter(startTime, 'm')
}

export interface PickupEventProps extends Interval {
  timeInLocationTimezone: moment.Moment
  calendarView: PickupCalendarDisplay
  color: string
  active: boolean
  tags: string[]
  tagsEnabled: boolean
  style?: React.CSSProperties
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void

  modalType?: ModalTypes
  setModal: (
    selectedStartTime?: moment.Moment,
    modalType?: ModalTypes,
    event?: any
  ) => void
  editable: boolean
}

class PickupEvent extends React.Component<PickupEventProps> {
  public render() {
    if (this.props.active) {
      const tagCount =
        this.props.tags && this.props.tags.length > 0 ? (
          <span className="pull-right">
            {this.props.tags!.length} tag
            {this.props.tags!.length > 1 ? 's' : ''}
          </span>
        ) : undefined
      const classes: string[] = [styles.pickupEvent]
      if (isInPast(this.props.timeInLocationTimezone, this.props.start)) {
        classes.push(styles.rdtOld)
      }

      return (
        <div
          className={classes.join(' ')}
          style={{ ...this.props.style }}
          onClick={this.props.onClick}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          <h5 className={styles.carrierName}>
            {this.props.calendarView.calendar.name}
          </h5>
          {this.props.start.format('HH:mm')}
          {tagCount}
        </div>
      )
    }

    return <></>
  }

  public componentDidMount() {
    this.updateColor()
  }

  public componentDidUpdate() {
    this.updateColor()
  }

  private updateColor() {
    const node = ReactDOM.findDOMNode(this) as HTMLElement
    if (node) {
      node.style.setProperty('--color', this.props.color)
    }
  }
}

import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { logError } from '../../common/logger'
import { SnackbarController } from '../../common/components/SnackbarController'
import useQuerystring from '../../common/hooks/useQuerystring'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { checkPermission, wdUpdater } from '../../common/proxy/calendars-store'
import { CalendarType, CreateTransitCalendar } from '../models'
import {
  EditTransitCalendar,
  loadCalendarsData,
  TransitCalendar,
  TransitCalendarWithData,
} from './transit-calendar-commons'
import TransitCalendarView from './TransitCalendarView'

interface Props {
  canCreate: boolean
  children?: React.ReactNode
  transitCalendars: TransitCalendar[]
  forceDisabled?: boolean

  getCaasProfiles: () => Promise<EnhancedCaasProfile[]>
  changeCalendarView(type: CalendarType): void
  updateOwner(
    calendarId: string,
    carrierServices: string[],
    correlationId: string
  ): Promise<void>

  createTransitCalendar(
    data: CreateTransitCalendar,
    correlationId: string
  ): Promise<string | undefined>
  deleteTransitCalendar(calendarId: string): Promise<void>
}

export default function TransitCalendarContainer(props: Props) {
  const [loading, setLoading] = React.useState(true)
  const [querystring, setQuerystring] = useQuerystring()
  const { t } = useTranslation()
  const [editable, setEditable] = React.useState(false)
  const [transitCalendars, setTransitCalendars] = React.useState<
    TransitCalendarWithData[]
  >([])
  const [caasProfiles, setCaasProfiles] = React.useState<EnhancedCaasProfile[]>(
    []
  )

  React.useEffect(() => {
    const fetchData = async () => {
      if (querystring.calendar) {
        const isEditable = await checkPermission(
          querystring.calendar,
          coam.models.CalendarPermissions.Update
        )

        setEditable(props.forceDisabled ? false : isEditable)
      }
    }

    fetchData()
  }, [props.forceDisabled, querystring.calendar])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedCaasProfiles = await props.getCaasProfiles()
      setCaasProfiles(fetchedCaasProfiles)

      const fetchedCalendars = await loadCalendarsData(
        props.transitCalendars,
        fetchedCaasProfiles
      )
      fetchedCalendars.sort((a, b) =>
        a.workingDaysCalendar.name!.localeCompare(b.workingDaysCalendar.name!)
      )

      const firstCalendar =
        fetchedCalendars.length > 0 ? fetchedCalendars[0].calendarId : undefined
      const nextCurrentCalendar = fetchedCalendars.some(
        c => c.calendarId === querystring.calendar
      )
        ? querystring.calendar
        : firstCalendar
      if (querystring.calendar !== nextCurrentCalendar) {
        setQuerystring({ calendar: nextCurrentCalendar })
      }
      setTransitCalendars(fetchedCalendars)
      setLoading(false)
    }

    fetchData()
  }, [props, props.transitCalendars, querystring.calendar, setQuerystring])

  const updateFromLegend = async (data: EditTransitCalendar) => {
    if (!data.calendar) {
      return
    }

    try {
      const correlationId = uuid.v4()
      if (data.calendar.workingDaysCalendar!.name !== data.calendarName) {
        const updated = await wdUpdater(
          data.calendar.workingDaysCalendar,
          calendar => {
            calendar.name = data.calendarName
          }
        )
        const updatedTransitCalendars = transitCalendars.map(c =>
          c.calendarId === updated.id
            ? { ...c, workingDaysCalendar: updated }
            : c
        )
        setTransitCalendars(updatedTransitCalendars)
      }

      await props.updateOwner(
        data.calendar.calendarId,
        data.carrierSelection.carrierServices!,
        correlationId
      )

      SnackbarController.show(
        t('calendars.transit.transitCalendarUpdated', {
          name: data.calendar.workingDaysCalendar.name,
        }),
        'success'
      )
    } catch (error) {
      SnackbarController.show(
        t('calendars.transit.updatingTransitCalendarFailed', {
          name: data.calendar.workingDaysCalendar.name,
        }),
        'danger'
      )
      logError(
        `Error when updating calendar: ${data.calendar.calendarId}`,
        error
      )
    }
  }

  const create = async (data: CreateTransitCalendar) => {
    try {
      const correlationId = uuid.v4()
      const calendarId = await props.createTransitCalendar(data, correlationId)

      if (!calendarId) {
        return
      }

      await props.updateOwner(
        calendarId,
        data.carrierSelection.carrierServices!,
        correlationId
      )

      SnackbarController.show(
        t('calendars.transit.transitCalendarCreated', {
          name: data.calendarName,
        }),
        'success'
      )
      setQuerystring({ calendar: calendarId })
    } catch (error) {
      SnackbarController.show(
        t('calendars.transit.creatingTransitCalendarFailed', {
          name: data.calendarName,
        }),
        'danger'
      )
      logError(`Error when creating calendar: ${data}`, error)
    }
  }
  const currentCalendar = transitCalendars.find(
    tc => tc.calendarId === querystring.calendar
  )

  const updateCalendarOnly = async (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => {
    if (!currentCalendar) {
      return
    }
    const updated = await wdUpdater(
      currentCalendar.workingDaysCalendar,
      updater
    )
    const updatedTransitCalendars = transitCalendars.map(c =>
      c.calendarId === updated.id ? { ...c, workingDaysCalendar: updated } : c
    )
    setTransitCalendars(updatedTransitCalendars)
  }

  return (
    <TransitCalendarView
      updateCalendar={updateCalendarOnly}
      canCreate={props.canCreate}
      editable={editable}
      selectedCalendar={currentCalendar}
      transitCalendars={transitCalendars}
      loading={loading}
      changeCalendarView={props.changeCalendarView}
      changeSchedule={changeCurrentCalendar}
      caasProfiles={caasProfiles}
      onUpdate={updateFromLegend}
      onCreate={create}
      onDelete={props.deleteTransitCalendar}
    >
      {props.children}
    </TransitCalendarView>
  )

  function changeCurrentCalendar(selected: string) {
    setQuerystring({ calendar: selected })
  }
}

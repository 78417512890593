import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { filterAvailableCaasProfiles } from '../../common/proxy/carrier-services-store'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { CalendarValidation, CalendarCarrierSelection } from '../models'
import { getCarrierServicesOptions } from './carrier-services-options'
import CarrierServicesSelector from './weekly/legend/pickup-calendar-editor/CarrierServicesSelector'
import CarrierSelector from './weekly/legend/pickup-calendar-editor/CarrierSelector'

interface Props {
  calendarName?: string
  carrierSelection: CalendarCarrierSelection
  isDisabled: boolean

  carrierAccountTypes: EnhancedCaasProfile[]
  carrierServicesToCalendarNames: { [key: string]: string }

  validation: CalendarValidation

  onCarrierServicesChange: (carrierServices: string[]) => void

  onCarrierChange: (carrier?: string) => void
}

export default function CarrierAndServicesSelector(props: Props) {
  const {
    carrierSelection,
    carrierAccountTypes,
    onCarrierChange,
    onCarrierServicesChange,
    carrierServicesToCalendarNames,
    validation,
    calendarName,
  } = props
  const { t } = useTranslation()

  const filteredCaasProfiles = filterAvailableCaasProfiles(
    props.carrierAccountTypes
  )
  const options = carrierSelection.carriers[0]
    ? getCarrierServicesOptions(
        t,
        carrierSelection.carrierServices ?? [],
        filteredCaasProfiles,
        props.carrierServicesToCalendarNames,
        carrierSelection.carriers
      )
    : []

  return (
    <div style={{ marginTop: '20px', marginBottom: '12px' }}>
      <h5>
        {t('calendars.weekly.addPickupCalendarModal.applyToCarrierServices')}
      </h5>
      <CarrierSelector
        isDisabled={props.isDisabled}
        caasProfiles={carrierAccountTypes}
        carriers={carrierSelection.carriers}
        onChange={onCarrierChange}
        carrierValidation={validation.carrier}
      />
      <CarrierServicesSelector
        isDisabled={props.isDisabled}
        selectedCarrierServices={props.carrierSelection.carrierServices || []}
        options={options}
        calendarName={calendarName}
        carrierServicesToCalendarNames={carrierServicesToCalendarNames}
        onChange={onCarrierServicesChange}
        carrierServicesValidation={validation.carrierServices}
      />
    </div>
  )
}

import { colors, TextField } from '@cimpress/react-components'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import Preloader from '../../common/components/Preloader'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { useShowElement } from '../../common/hooks/useShowElement'
import {
  CalendarType,
  CalendarCarrierSelection,
  CreateTransitCalendar,
  EditCalendar,
} from '../models'
import CalendarCreateModal from './CalendarCreateModal'
import CalendarEditModal from './CalendarEditModal'
import ComplexSetupView from './ComplexSetupView'
import DayOfTheWeekSelector from './DayOfTheWeekSelector'
import { TransitCalendarLegend } from './legend/CalendarLegend'
import TransitCalendarLegendRow from './legend/TransitCalendarLegendRow'
import { SelectCalendar } from './SelectCalendar'
import {
  EditTransitCalendar,
  TransitCalendarWithData,
} from './transit-calendar-commons'
import styles from './TransitCalendarView.module.css'
import { getDefaultSchedule } from './weekly/legend/pickup-calendar-editor/schedule-helpers'
import YearlyCalendar from './YearlyCalendar'
import NewCalendarButton from './NewCalendarButton'
import { CalendarDeleteModal } from './CalendarDeleteModal'

interface Props {
  updateCalendar: (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => Promise<void>
  children?: React.ReactNode
  canCreate: boolean

  editable: boolean
  selectedCalendar: TransitCalendarWithData | undefined
  transitCalendars: TransitCalendarWithData[]
  loading: boolean
  caasProfiles: EnhancedCaasProfile[]
  changeCalendarView(type: CalendarType): void
  changeSchedule(selected: string): void
  onUpdate(data: EditTransitCalendar): Promise<void>
  onCreate(data: CreateTransitCalendar): Promise<void>
  onDelete(calendarId: string): Promise<void>
}

export default function TransitCalendarView(props: Props) {
  const { t } = useTranslation()
  const [calendarToEdit, setCalendarToEdit] = React.useState<
    TransitCalendarWithData
  >()
  const openEditModal = (calendar: TransitCalendarWithData) =>
    setCalendarToEdit(calendar)
  const closeEditModal = () => setCalendarToEdit(undefined)

  const [showCreateModal, openCreateModal, closeCreateModal] = useShowElement()
  const [calendarToDelete, setCalendarToDelete] = React.useState<
    TransitCalendarWithData
  >()
  const openDeleteModal = (calendar: TransitCalendarWithData) =>
    setCalendarToDelete(calendar)
  const closeDeleteModal = () => setCalendarToDelete(undefined)

  const carrierServicesToCalendarNames = props.transitCalendars.reduce(
    (acc, curr) => {
      for (const csKey of curr.carrierServices) {
        if (curr.workingDaysCalendar) {
          acc[csKey] = curr.workingDaysCalendar.name
        }
      }

      return acc
    },
    {}
  )

  const carrierAccountData: CalendarCarrierSelection = {
    carriers: calendarToEdit?.profiles?.map(profile => profile.key) ?? [],
    carrierServices:
      calendarToEdit && calendarToEdit ? calendarToEdit.carrierServices : [],
  }

  const yearlyCalendar = !props.selectedCalendar ? (
    <div className="col-sm-8 col-md-8 col-lg-9 flex">
      {props.loading ? (
        <div className="card" style={{ width: '100%', height: '100%' }}>
          <Preloader />
        </div>
      ) : (
        <div
          className="robot-figure"
          style={{ textAlign: 'center', paddingTop: '50px', width: '100%' }}
        >
          <ReactSVG src="https://static.ux.cimpress.io/assets/images/robot-success-3.svg" />
          <p
            style={{ fontSize: '16px', color: colors.shale, marginTop: '20px' }}
          >
            {t('calendars.transit.noCalendars')}
          </p>
        </div>
      )}
    </div>
  ) : (
    <YearlyCalendar
      updateCalendar={props.updateCalendar}
      editable={props.editable}
      calendar={props.selectedCalendar.workingDaysCalendar}
      changeCalendarView={props.changeCalendarView}
      calenderView={'transit'}
    >
      {props.children}
    </YearlyCalendar>
  )

  const onUpdate = async (data: EditCalendar) => {
    if (calendarToEdit) {
      await props.onUpdate({ ...data, calendar: calendarToEdit })
    }
  }

  return (
    <>
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 calendar-legend container padding-bottom-full">
        <div
          className="card"
          style={{
            height: '100%',
          }}
        >
          <div className="card-block flex-vertical calendar-content">
            <SelectCalendar disabled={props.loading} />
            <TransitCalendarLegend />
            <SelectTransitSchedule
              selectedCalendar={props.selectedCalendar}
              transitCalendars={props.transitCalendars}
              onChange={props.changeSchedule}
              onEdit={openEditModal}
              onCreate={openCreateModal}
              loading={props.loading}
              editable={props.editable}
              canCreate={props.canCreate}
              onDelete={openDeleteModal}
            />
          </div>
        </div>
      </div>
      {yearlyCalendar}
      {calendarToEdit && (
        <CalendarEditModal
          title={t('calendars.transit.editTransitCalendar')}
          editCalendar={{
            calendarName: calendarToEdit.workingDaysCalendar.name!,
            carrierSelection: carrierAccountData,
          }}
          carrierAccountTypes={props.caasProfiles}
          carrierServicesToCalendarNames={carrierServicesToCalendarNames}
          onClose={closeEditModal}
          onSave={onUpdate}
          editable={props.editable}
        >
          {calendarToEdit.hasComplexSetup && (
            <ComplexSetupView
              carrierName="Generic"
              carrierServices={calendarToEdit.carrierServices}
            />
          )}
        </CalendarEditModal>
      )}
      {showCreateModal && (
        <TransitCalendarCreateModal
          carrierAccountTypes={props.caasProfiles}
          carrierServicesToCalendarNames={carrierServicesToCalendarNames}
          onClose={closeCreateModal}
          onSave={props.onCreate}
        />
      )}
      {calendarToDelete && (
        <CalendarDeleteModal
          calendarId={calendarToDelete.calendarId}
          calendarName={calendarToDelete.workingDaysCalendar.name ?? ''}
          onClose={closeDeleteModal}
          onDelete={props.onDelete}
        />
      )}
    </>
  )
}

interface SelectTransitScheduleProps {
  loading: boolean
  selectedCalendar: TransitCalendarWithData | undefined
  transitCalendars: TransitCalendarWithData[]

  canCreate: boolean
  editable: boolean
  onChange: (transitCalendarId: string) => void
  onEdit: (calendarToEdit: TransitCalendarWithData) => void
  onCreate: () => void
  onDelete: (calendarToDelete: TransitCalendarWithData) => void
}

function SelectTransitSchedule(props: SelectTransitScheduleProps) {
  const { t } = useTranslation()
  const [search, setSearch] = React.useState('')

  const searchChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }

  const filtered = props.transitCalendars
    .filter(tc =>
      (tc.workingDaysCalendar.name || '')
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase())
    )
    .sort((tc1, tc2) =>
      (tc1.workingDaysCalendar.name || '').localeCompare(
        tc2.workingDaysCalendar.name || ''
      )
    )

  const noCalendars = props.transitCalendars.length > 0 && filtered.length === 0

  return (
    <div className={styles.selectTransitSchedule}>
      <h5>{t('calendars.transit.transitCalendars')}</h5>
      {props.loading ? (
        <div className="overflow-y-only">
          <Preloader small={true} />
        </div>
      ) : (
        <>
          <TextField
            name="search"
            label={t('common.search')}
            onChange={searchChange}
            value={search}
          />
          <div className="overflow-y-only">
            {noCalendars ? (
              <p style={{ textAlign: 'center' }}>
                <small>{t('calendars.noMatchingCalendars')}</small>
              </p>
            ) : (
              filtered.map(tc => (
                <TransitCalendarLegendRow
                  key={tc.calendarId}
                  transitCalendar={tc}
                  {...props}
                />
              ))
            )}
          </div>
        </>
      )}
      {props.canCreate && (
        <NewCalendarButton
          onClick={props.onCreate}
          text={t('calendars.transit.newTransitCalendars')}
        />
      )}
    </div>
  )
}

interface TransitCalendarCreateModalProps {
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierAccountTypes: EnhancedCaasProfile[]

  onSave: (data: CreateTransitCalendar) => Promise<void>
  onClose: () => void
}

function TransitCalendarCreateModal(props: TransitCalendarCreateModalProps) {
  const { t } = useTranslation()
  const [weeklySchedule, setWeeklySchedule] = React.useState(
    getDefaultSchedule().weeklySchedule.schedule
  )

  const onSave = async (data: EditCalendar) => {
    await props.onSave({ ...data, weeklySchedule })
  }

  return (
    <CalendarCreateModal
      title={t('calendars.transit.createTransitCalendar')}
      primaryButtonText={t('calendars.transit.createCalendar')}
      primaryButtonSavingText={t('calendars.transit.creatingCalendar')}
      carrierAccountTypes={props.carrierAccountTypes}
      carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
      onClose={props.onClose}
      onSave={onSave}
    >
      <div style={{ marginTop: '20px', marginBottom: '12px' }}>
        <h5>{t('calendars.transit.doingTransitEvery')}</h5>
        <DayOfTheWeekSelector
          weeklySchedule={weeklySchedule}
          onChangeSchedule={setWeeklySchedule}
        />
      </div>
    </CalendarCreateModal>
  )
}

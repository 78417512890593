import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { Location } from '../../common/models'

export function getInitialPickupCalendarState(
  location: Location
): calendars.models.CreatePickupCalendar {
  return {
    name: '',
    weeklySchedules: [],
    overwrites: {},
    workingDaysCalendar: { id: location.workingDaysCalendar.id },
    timezone: location.localeSettings.timezone,
    owner: { logisticsLocationId: location.id },
  }
}

export function hasComplexCalendarSetup(
  location: Location,
  pickupCalendarId: string
): boolean {
  for (const csKey of Object.keys(location.pickupCalendars.mapping)) {
    const calendarMapping = location.pickupCalendars.mapping[csKey]
    if (
      calendarMapping.additionalCalendars &&
      [calendarMapping, ...calendarMapping.additionalCalendars].some(
        cal => cal.id === pickupCalendarId
      )
    ) {
      return true
    }
  }

  return false
}

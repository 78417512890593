import { Tooltip } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import * as models from '../models'
import { getCellClasses } from './cell-class'
import ExceptionModal from './ExceptionModal'
import './Modal.css'
import { useYearlyCalendarContext } from './YearlyCalendarContext'
export type OnClick = (date: models.DayDate) => void

interface Props {
  year: number
  month: number
  dayCell?: models.DayCell
}

export function DayCellView(props: Props) {
  const context = useYearlyCalendarContext()

  const selectedDay =
    context.showModalFor && context.showModalFor.month === props.month
      ? context.showModalFor.day
      : undefined

  const cellClass = getCellClasses(
    context.today,
    props.year,
    props.month,
    'rdtDay',
    context.editable,
    props.dayCell,
    selectedDay
  )

  if (props.dayCell === undefined) {
    return <td className={cellClass} />
  }

  const day = props.dayCell.day

  const id = `${props.year}-${props.month}-${day}`
  const current = moment([props.year, props.month - 1, props.dayCell.day])

  const dateInPast = current.isBefore(context.today, 'day')
  const onClick = !dateInPast
    ? () => {
        context.openModal({ year: props.year, month: props.month, day })
      }
    : // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}

  const showPopover =
    context.showModalFor &&
    context.showModalFor.day === day &&
    context.showModalFor.month === props.month &&
    context.showModalFor.year === props.year &&
    ((dateInPast && props.dayCell.dayInformation.exceptionInformation) ||
      !dateInPast)

  if (showPopover) {
    const date = context.showModalFor!

    const addException = (modifySchedule: boolean, explanation: string) =>
      context.addException(date, modifySchedule, explanation)
    const removeException = () => context.removeException(date)

    return (
      <td id={id} className={cellClass}>
        <Tooltip
          direction="bottom"
          variety="popover"
          contents={
            <ExceptionModal
              dayDate={date}
              dayInformation={props.dayCell.dayInformation}
              addException={addException}
              removeException={removeException}
            />
          }
          show={true}
          key="dialog-tooltip"
          onClickOutside={context.closeModal}
        >
          {props.dayCell.day}
        </Tooltip>
      </td>
    )
  }

  if (
    props.dayCell.dayInformation.exceptionInformation &&
    props.dayCell.dayInformation.exceptionInformation.reason
  ) {
    return (
      <td id={id} onClick={onClick} className={cellClass}>
        <Tooltip
          contents={props.dayCell.dayInformation.exceptionInformation.reason}
          key="message-tooltip"
        >
          {props.dayCell.day}
        </Tooltip>
      </td>
    )
  }

  return (
    <td id={id} className={cellClass} onClick={onClick}>
      {props.dayCell.day}
    </td>
  )
}

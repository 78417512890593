import moment from 'moment-timezone'
import * as React from 'react'
import { CalendarDisplay } from '../../../common/models'
import {
  AddPickupHandler,
  EditEventHandler,
  MultiyearInformation,
  PickupCalendarDaysWithLegend,
  RemoveEventHandler,
} from '../../models'

export type ModalTypes =
  | 'addPickup'
  | 'tooltip'
  | 'edit'
  | 'remove'
  | 'futureNonWorkingDay'

export interface Context {
  calendarDisplay: CalendarDisplay
  currentDate: moment.Moment
  multiyearInformation: MultiyearInformation
  pickupCalendarDaysWithLegend: PickupCalendarDaysWithLegend[]
  modalData: {
    modalType?: ModalTypes
    selectedStartTime?: moment.Moment
    selectedCalendarId?: any
  }
  setModal: (
    selectedStartTime?: moment.Moment,
    modalType?: ModalTypes,
    selectedCalendarId?: string
  ) => void
  onPickupCalendarToggle: (id: string) => void
  onPickupCalendarSelectAll: () => void
  onPickupCalendarDeselectAll: () => void
  removePickupEvent: RemoveEventHandler
  editPickupEvent: EditEventHandler
  addPickupEvent: AddPickupHandler
  timezone: string
  changeWeek: (direction: number) => void
  canAddCalendar: boolean
  tagsEnabled: boolean
  loadCalendarData: () => Promise<void>
  changeToYearly(): void
}

export const Context = React.createContext<Context>({} as Context)

export const Provider = Context.Provider
export const Consumer = Context.Consumer

export default function useWeeklyCalendarContext() {
  return React.useContext(Context)
}

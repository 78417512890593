import { colors, TextField } from '@cimpress/react-components'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import Preloader from '../../common/components/Preloader'
import { CalendarType, CreateDeliveryCalendar } from '../models'
import { useShowElement } from '../../common/hooks/useShowElement'
import DayOfTheWeekSelector from './DayOfTheWeekSelector'
import { DeliveryCalendarLegend } from './legend/CalendarLegend'
import { SelectCalendar } from './SelectCalendar'
import styles from './TransitCalendarView.module.css'
import { getDefaultSchedule } from './weekly/legend/pickup-calendar-editor/schedule-helpers'
import YearlyCalendar from './YearlyCalendar'
import DeliveryCalendarLegendRow from './legend/DeliveryCalendarLegendRow'
import SimpleCalendarModal from './SimpleCalendarModal'
import NewCalendarButton from './NewCalendarButton'
import { CalendarDeleteModal } from './CalendarDeleteModal'

interface Props {
  updateCalendar: (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => Promise<void>
  children?: React.ReactNode
  canCreate: boolean

  editable: boolean
  selectedCalendar: calendars.models.WorkingDaysCalendar | undefined
  deliveryCalendars: calendars.models.WorkingDaysCalendar[]
  loading: boolean

  changeCalendarView(type: CalendarType): void
  changeSelectedCalendar(selected: string): void
  onCreate(data: CreateDeliveryCalendar): Promise<void>
  onUpdate(
    calendar: calendars.models.WorkingDaysCalendar,
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ): Promise<void>
  onDelete(calendarId: string): Promise<void>
}

export default function DeliveryCalendarView(props: Props) {
  const { t } = useTranslation()
  const [calendarToEdit, setCalendarToEdit] = React.useState<
    calendars.models.WorkingDaysCalendar
  >()
  const openEditModal = (calendar: calendars.models.WorkingDaysCalendar) =>
    setCalendarToEdit(calendar)
  const closeEditModal = () => setCalendarToEdit(undefined)

  const [calendarToDelete, setCalendarToDelete] = React.useState<
    calendars.models.WorkingDaysCalendar
  >()
  const openDeleteModal = (calendar: calendars.models.WorkingDaysCalendar) =>
    setCalendarToDelete(calendar)
  const closeDeleteModal = () => setCalendarToDelete(undefined)

  const [showCreateModal, openCreateModal, closeCreateModal] = useShowElement()

  const yearlyCalendar = !props.selectedCalendar ? (
    <div className="col-sm-8 col-md-8 col-lg-9 flex">
      {props.loading ? (
        <div className="card" style={{ width: '100%', height: '100%' }}>
          <Preloader />
        </div>
      ) : (
        <div
          className="robot-figure"
          style={{ textAlign: 'center', paddingTop: '50px', width: '100%' }}
        >
          <ReactSVG src="https://static.ux.cimpress.io/assets/images/robot-success-3.svg" />
          <p
            style={{ fontSize: '16px', color: colors.shale, marginTop: '20px' }}
          >
            {t('calendars.delivery.noCalendars')}
          </p>
        </div>
      )}
    </div>
  ) : (
    <YearlyCalendar
      updateCalendar={props.updateCalendar}
      editable={props.editable}
      calendar={props.selectedCalendar}
      changeCalendarView={props.changeCalendarView}
      calenderView={'delivery'}
    >
      {props.children}
    </YearlyCalendar>
  )

  const onUpdate = async (name: string) => {
    if (calendarToEdit) {
      await props.onUpdate(calendarToEdit, dc => (dc.name = name))
    }
  }

  return (
    <>
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 calendar-legend container padding-bottom-full">
        <div
          className="card"
          style={{
            height: '100%',
          }}
        >
          <div className="card-block flex-vertical calendar-content">
            <SelectCalendar disabled={props.loading} />
            <DeliveryCalendarLegend />
            <SelectDeliverySchedule
              selectedCalendar={props.selectedCalendar}
              deliveryCalendars={props.deliveryCalendars}
              onChange={props.changeSelectedCalendar}
              onEdit={openEditModal}
              onCreate={openCreateModal}
              loading={props.loading}
              editable={props.editable}
              canCreate={props.canCreate}
              onDelete={openDeleteModal}
            />
          </div>
        </div>
      </div>
      {yearlyCalendar}
      {calendarToEdit && (
        <SimpleCalendarModal
          title={t('calendars.delivery.editDeliveryCalendar')}
          calendarName={calendarToEdit.name!}
          onClose={closeEditModal}
          onSave={onUpdate}
          editable={props.editable}
        />
      )}
      {showCreateModal && (
        <DeliveryCalendarCreateModal
          onSave={props.onCreate}
          onClose={closeCreateModal}
        />
      )}
      {calendarToDelete && (
        <CalendarDeleteModal
          calendarId={calendarToDelete.id}
          calendarName={calendarToDelete.name ?? ''}
          onClose={closeDeleteModal}
          onDelete={props.onDelete}
        />
      )}
    </>
  )
}

interface SelectDeliveryScheduleProps {
  loading: boolean
  selectedCalendar: calendars.models.WorkingDaysCalendar | undefined
  deliveryCalendars: calendars.models.WorkingDaysCalendar[]

  canCreate: boolean
  editable: boolean
  onChange: (calendarId: string) => void
  onEdit: (calendarToEdit: calendars.models.WorkingDaysCalendar) => void
  onCreate: () => void
  onDelete: (calendarToDelete: calendars.models.WorkingDaysCalendar) => void
}

function SelectDeliverySchedule(props: SelectDeliveryScheduleProps) {
  const { t } = useTranslation()
  const [search, setSearch] = React.useState('')

  const searchChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }

  const filtered = props.deliveryCalendars
    .filter(dc =>
      (dc.name || '').toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
    .sort((dc1, dc2) => (dc1.name || '').localeCompare(dc2.name || ''))

  const noCalendars =
    props.deliveryCalendars.length > 0 && filtered.length === 0

  return (
    <div className={styles.selectTransitSchedule}>
      <h5>{t('calendars.delivery.deliveryCalendars')}</h5>
      {props.loading ? (
        <div className="overflow-y-only">
          <Preloader small={true} />
        </div>
      ) : (
        <>
          <TextField
            name="search"
            label={t('common.search')}
            onChange={searchChange}
            value={search}
          />
          <div className="overflow-y-only">
            {noCalendars ? (
              <p style={{ textAlign: 'center' }}>
                <small>{t('calendars.noMatchingCalendars')}</small>
              </p>
            ) : (
              filtered.map(dc => (
                <DeliveryCalendarLegendRow
                  key={dc.id}
                  deliveryCalendar={dc}
                  {...props}
                />
              ))
            )}
          </div>
        </>
      )}
      {props.canCreate && (
        <NewCalendarButton
          onClick={props.onCreate}
          text={t('calendars.delivery.newDeliveryCalendars')}
        />
      )}
    </div>
  )
}

interface DeliveryCalendarCreateModalProps {
  onSave: (data: CreateDeliveryCalendar) => Promise<void>
  onClose: () => void
}

function DeliveryCalendarCreateModal(props: DeliveryCalendarCreateModalProps) {
  const { t } = useTranslation()
  const [weeklySchedule, setWeeklySchedule] = React.useState(
    getDefaultSchedule().weeklySchedule.schedule
  )

  const onSave = async (calendarName: string) => {
    await props.onSave({ calendarName, weeklySchedule })
  }

  return (
    <SimpleCalendarModal
      title={t('calendars.delivery.createDeliveryCalendar')}
      calendarName={''}
      editable={true}
      onClose={props.onClose}
      onSave={onSave}
    >
      <div style={{ marginTop: '20px', marginBottom: '12px' }}>
        <h5>{t('calendars.delivery.doingDeliveryEvery')}</h5>
        <DayOfTheWeekSelector
          weeklySchedule={weeklySchedule}
          onChangeSchedule={setWeeklySchedule}
        />
      </div>
    </SimpleCalendarModal>
  )
}

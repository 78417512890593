import { Checkbox, Tag, TextField } from '@cimpress/react-components'
import * as React from 'react'
import { declaredToUsCustoms, mapTag } from './tag-mapper'

const usCustomsLocation = ['windsor', 'l7JB--FOb']

interface Props {
  locationId: string
  tags: string[]
  onTagsChanged: (updatedTags: string[]) => void
}

export function TagEditor(props: Props) {
  const [currentTag, setCurrentTag] = React.useState('')

  if (usCustomsLocation.includes(props.locationId)) {
    const onChange = (event: any, payload: boolean) => {
      if (props.tags.includes(declaredToUsCustoms.value)) {
        props.onTagsChanged([])
      } else {
        props.onTagsChanged([declaredToUsCustoms.value])
      }
    }

    return (
      <>
        <h5>Tags</h5>
        <Checkbox
          label={declaredToUsCustoms.label}
          checked={props.tags.includes(declaredToUsCustoms.value)}
          onChange={onChange}
        />
      </>
    )
  }

  const onCurrentTagChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTag(event.currentTarget.value)
  }

  const onAdd = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onTagsChanged([...props.tags, currentTag])
    setCurrentTag('')
  }

  const onRemove = (value?: React.ReactNode) => {
    props.onTagsChanged(props.tags.filter(tag => mapTag(tag) !== value))
  }

  const tagView =
    props.tags.length === 0
      ? 'No tags are setup for this pickup'
      : props.tags
          .map(mapTag)
          .map(tag => (
            <Tag
              value={tag}
              key={tag}
              removable={true}
              onRemoveClick={onRemove}
            />
          ))

  return (
    <>
      <h5>Tags</h5>
      <TextField
        name="btnMid"
        label="Tag"
        value={currentTag}
        onChange={onCurrentTagChange}
        rightAddon={
          <button onClick={onAdd} className="btn btn-default">
            Add
          </button>
        }
      />
      <div style={{ paddingBottom: '16px' }}>{tagView}</div>
    </>
  )
}

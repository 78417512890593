import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../../../common/components/ValidationContainer'
import { ValidationResult } from '../../../../../common/helpers/validation'
import {
  Disabled,
  Option,
  reactSelectPortalId,
} from '../../../../../common/models'

interface Props {
  calendarName?: string
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierServicesValidation: ValidationResult
  isDisabled: boolean
  options: Array<Option & Disabled>
  selectedCarrierServices: string[]
  onChange(carrierServices: string[]): void
}

export default function CarrierServicesSelector(props: Props) {
  const { t } = useTranslation()

  const onCarrierServicesChange = (changeData?: Option[]) => {
    props.onChange((changeData ?? []).map(cd => cd.value))
  }

  return (
    <ValidationContainer validationResult={props.carrierServicesValidation}>
      <Select
        label={t('carrierAccounts.addCarrierFlow.selectCarrierServicesLabel')}
        options={props.options}
        required={true}
        isDisabled={props.isDisabled || props.options.length === 0}
        value={props.options.filter(o =>
          props.selectedCarrierServices.includes(o.value)
        )}
        isMulti={true}
        onChange={onCarrierServicesChange}
        menuPortalTarget={document.getElementById(reactSelectPortalId)}
      />
    </ValidationContainer>
  )
}

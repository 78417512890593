import * as client from '@cimpress-technology/logistics-configuration-client'
import { TransitCalendarMapping } from '@cimpress-technology/logistics-configuration-client/js/models/locations'
import * as uuid from 'uuid'
import { filterAvailableCaasProfiles } from '../../common/proxy/carrier-services-store'
import { bearerToken } from '../../common/auth'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { EditCalendar } from '../models'

export interface EditTransitCalendar extends EditCalendar {
  calendar: TransitCalendarWithData
}

export interface TransitCalendar {
  calendarId: string
  carrierServices: string[]
}

export interface TransitCalendarWithData extends TransitCalendar {
  workingDaysCalendar: client.calendars.models.WorkingDaysCalendar
  profiles: EnhancedCaasProfile[]
  hasComplexSetup?: boolean
}

export function getTransitCalendars(
  mapping: TransitCalendarMapping
): TransitCalendar[] {
  const transitCalendarsMap = new Map<string, string[]>()
  for (const [csKey, refObject] of Object.entries(mapping)) {
    if (!transitCalendarsMap.has(refObject.id)) {
      transitCalendarsMap.set(refObject.id, [csKey])
    } else {
      const carrierServicesList = transitCalendarsMap.get(
        refObject.id
      ) as string[]
      carrierServicesList.push(csKey)
    }
  }

  return [...transitCalendarsMap].map(([calendarId, carrierServices]) => ({
    calendarId,
    carrierServices,
  }))
}

export function loadCalendarsData(
  transitCalendars: TransitCalendar[],
  caasProfiles: EnhancedCaasProfile[]
): Promise<TransitCalendarWithData[]> {
  const cid = uuid.v4()

  const carrierTypesReverseMap = getCarrierTypeMap(caasProfiles)

  return Promise.all(
    transitCalendars.map(async ({ calendarId, carrierServices }) => {
      const wd = await client.calendars.getWorkingDaysCalendar(
        bearerToken(),
        cid,
        calendarId
      )
      const profiles = addProfile(carrierTypesReverseMap, carrierServices)

      return { calendarId, workingDaysCalendar: wd!, carrierServices, profiles }
    })
  )
}

function addProfile(
  profilesReverseMap: Map<string, Set<EnhancedCaasProfile>>,
  carrierServices: string[]
): EnhancedCaasProfile[] {
  const profileSets = carrierServices
    .map(cs => profilesReverseMap.get(cs))
    .reduce((acc, curr) => {
      if (curr) {
        const currentProfile = [...curr.values()][0]
        acc?.add(currentProfile)
      }

      return acc
    }, new Set<EnhancedCaasProfile>()) as Set<EnhancedCaasProfile>

  return [...profileSets]
}

function getCarrierTypeMap(profiles: EnhancedCaasProfile[]) {
  const carrierTypesReverseMap = new Map<string, Set<EnhancedCaasProfile>>()
  for (const profile of filterAvailableCaasProfiles(profiles)) {
    for (const caas of profile.carrierServices) {
      const profilesList =
        carrierTypesReverseMap.get(caas.key) || new Set<EnhancedCaasProfile>()
      profilesList.add(profile)

      carrierTypesReverseMap.set(caas.key, profilesList)
    }
  }

  return carrierTypesReverseMap
}

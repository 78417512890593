import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import {
  locations,
  calendars,
} from '@cimpress-technology/logistics-configuration-client'
import * as uuid from 'uuid'
import { useTranslation } from 'react-i18next'
import { CalendarType, CreateDeliveryCalendar } from '../models'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { bearerToken } from '../../common/auth'
import useQuerystring from '../../common/hooks/useQuerystring'
import {
  getDefaultWorkingDaysCalendar,
  wdUpdater,
  checkPermission,
  deleteWorkingDaysCalendar,
} from '../../common/proxy/calendars-store'
import { SnackbarController } from '../../common/components/SnackbarController'
import { logError } from '../../common/logger'
import DeliveryCalendarView from './DeliveryCalendarView'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export default function DeliveryCalendarContainer(props: Props) {
  const { t } = useTranslation()
  const { logisticsLocation, updateLocation } = useLogisticsLocation()
  const [loading, setLoading] = React.useState(true)
  const [querystring, setQuerystring] = useQuerystring()
  const [deliveryCalendars, setDeliveryCalendars] = React.useState<
    calendars.models.WorkingDaysCalendar[]
  >([])
  const [editable, setEditable] = React.useState(false)

  const currentCalendar = deliveryCalendars.find(
    dc => dc.id === querystring.calendar
  )

  React.useEffect(() => {
    const fetchData = async () => {
      if (querystring.calendar) {
        const isEditable = await checkPermission(
          querystring.calendar,
          coam.models.CalendarPermissions.Update
        )

        setEditable(isEditable)
      }
    }

    fetchData()
  }, [querystring.calendar])

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedCalendars: calendars.models.WorkingDaysCalendar[] = (
        await locations.getAllDeliveryCalendarsByLocation(
          bearerToken(),
          uuid.v4(),
          logisticsLocation.id
        )
      ).items

      fetchedCalendars.sort((a, b) => a.name!.localeCompare(b.name!))

      const firstCalendar =
        fetchedCalendars.length > 0 ? fetchedCalendars[0].id : undefined
      const nextCurrentCalendar = fetchedCalendars.some(
        c => c.id === querystring.calendar
      )
        ? querystring.calendar
        : firstCalendar
      if (querystring.calendar !== nextCurrentCalendar) {
        setQuerystring({ calendar: nextCurrentCalendar })
      }

      setDeliveryCalendars(fetchedCalendars)
      setLoading(false)
    }

    fetchData()
  }, [
    logisticsLocation.id,
    querystring.calendar,
    setQuerystring,
    logisticsLocation.deliveryCalendars,
  ])

  function changeCurrentCalendar(selected: string) {
    setQuerystring({ calendar: selected })
  }

  const createCalendar = async (data: CreateDeliveryCalendar) => {
    try {
      const correlationId = uuid.v4()
      const wdCalendar = getDefaultWorkingDaysCalendar(
        logisticsLocation.localeSettings.timezone,
        logisticsLocation.address.countryCode,
        { logisticsLocationId: logisticsLocation.id }
      )
      wdCalendar.weeklySchedules[0].schedule = data.weeklySchedule

      const deliveryCalendar = {
        ...wdCalendar,
        name: data.calendarName,
      }
      const deliveryCalendarId = await calendars.createWorkingDaysCalendar(
        bearerToken(),
        correlationId,
        deliveryCalendar
      )

      await updateLocation(location => {
        location.deliveryCalendars.calendars.push({ id: deliveryCalendarId })
      })

      SnackbarController.show(
        t('calendars.delivery.deliveryCalendarCreated', {
          name: data.calendarName,
        }),
        'success'
      )
      setQuerystring({ calendar: deliveryCalendarId })
    } catch (error) {
      SnackbarController.show(
        t('calendars.delivery.creatingDeliveryCalendarFailed', {
          name: data.calendarName,
        }),
        'danger'
      )
      logError(`Error when creating calendar: ${data}`, error)
    }
  }

  const updateFromLegend = async (
    calendar: calendars.models.WorkingDaysCalendar,
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => {
    if (!calendar) {
      return
    }

    try {
      const updated = await wdUpdater(calendar, updater)
      const updatedDeliveryCalendars = deliveryCalendars.map(dc =>
        dc.id === updated.id ? updated : dc
      )
      setDeliveryCalendars(updatedDeliveryCalendars)

      SnackbarController.show(
        t('calendars.delivery.deliveryCalendarUpdated', {
          name: calendar.name,
        }),
        'success'
      )
    } catch (error) {
      SnackbarController.show(
        t('calendars.delivery.updatingDeliveryCalendarFailed', {
          name: calendar.name,
        }),
        'danger'
      )
      logError(`Error when updating calendar: ${calendar.id}`, error)
    }
  }

  const updateCalendarSchedule = async (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => {
    if (!currentCalendar) {
      return
    }
    const updated = await wdUpdater(currentCalendar, updater)
    const updatedDeliveryCalendars = deliveryCalendars.map(c =>
      c.id === updated.id ? updated : c
    )
    setDeliveryCalendars(updatedDeliveryCalendars)
  }

  const onDelete = async (calendarId: string) => {
    const correlationId = uuid.v4()
    await Promise.all([
      deleteWorkingDaysCalendar(calendarId, correlationId),
      updateLocation(location => {
        location.deliveryCalendars.calendars = location.deliveryCalendars.calendars.filter(
          ref => ref.id !== calendarId
        )
      }, correlationId),
      locations.unlinkResourceFromLocationGroup(
        bearerToken(),
        correlationId,
        logisticsLocation.id,
        calendarId,
        coam.models.ResourceTypes.Calendar
      ),
    ])
  }

  return (
    <DeliveryCalendarView
      loading={loading}
      selectedCalendar={currentCalendar}
      editable={editable}
      canCreate={logisticsLocation.editable}
      updateCalendar={updateCalendarSchedule}
      onCreate={createCalendar}
      onUpdate={updateFromLegend}
      deliveryCalendars={deliveryCalendars}
      changeCalendarView={props.changeCalendarView}
      changeSelectedCalendar={changeCurrentCalendar}
      onDelete={onDelete}
    />
  )
}

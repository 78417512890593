import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CalendarValidation,
  nameValidationField,
  carrierServicesValidationField,
  carrierValidationField,
  EditCalendar,
  CalendarCarrierSelection,
} from '../models'
import {
  validateField,
  ValidationStatus,
  validateList,
} from '../../common/helpers/validation'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import { filterAvailableCaasProfiles } from '../../common/proxy/carrier-services-store'
import { getCarrierServicesOptions } from './carrier-services-options'

export function useCalendarEdit(
  initialCarrierSelection: CalendarCarrierSelection,
  initialCalendarName: string,
  carrierAccountTypes: EnhancedCaasProfile[],
  carrierServicesToCalendarNames: {
    [key: string]: string
  }
) {
  const { t } = useTranslation()

  const [calendarName, setCalendarName] = useState(initialCalendarName)

  const [carrierSelection, setCarrierSelection] = useState<
    CalendarCarrierSelection
  >(initialCarrierSelection)

  const [validation, setValidation] = useState<CalendarValidation>(
    getInitialEditCalendarValidationStatus()
  )

  const validate = async () => {
    const newValidationState = {
      name: validateField(nameValidationField, calendarName),
      carrier: validateField(carrierValidationField, carrierSelection.carriers),
      carrierServices: validateList(
        carrierServicesValidationField,
        carrierSelection.carrierServices
      ),
    }

    setValidation(newValidationState)

    return Object.values(newValidationState).every(
      field => field.status === ValidationStatus.Valid
    )
  }

  const onCalendarNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCalendarName(e.currentTarget.value)
    setValidation({
      ...validation,
      name: validateField(nameValidationField, e.currentTarget.value),
    })
  }

  const onCarrierServicesChange = (selectedCarrierServices: string[]) => {
    setCarrierSelection({
      ...carrierSelection,
      carrierServices: selectedCarrierServices,
    })

    setValidation({
      ...validation,
      carrierServices: validateList(
        carrierServicesValidationField,
        selectedCarrierServices
      ),
    })
  }

  const onCarrierChange = (carrier?: string) => {
    const filteredCaasProfiles = filterAvailableCaasProfiles(
      carrierAccountTypes
    )

    const carrierServicesOptions = carrier
      ? getCarrierServicesOptions(
          t,
          carrierSelection.carrierServices ?? [],
          filteredCaasProfiles,
          carrierServicesToCalendarNames,
          [carrier],
          calendarName
        )
      : []
    const preselectedCarrierServices =
      carrierServicesOptions.length === 1
        ? carrierServicesOptions
            .filter(o => !o.isDisabled)
            .map(cso => cso.value as string)
        : []

    const newCarrierAccountData = {
      carriers: carrier ? [carrier] : [],
      carrierServices:
        carrierSelection.carriers.length === 1 &&
        carrierSelection.carriers[0] === carrier
          ? carrierSelection.carrierServices
          : preselectedCarrierServices,
    }
    setCarrierSelection(newCarrierAccountData)
    const newValidationState = {
      ...validation,
      carrier: validateField(carrierValidationField, carrier),
    }

    newValidationState.carrierServices = validateList(
      carrierServicesValidationField,
      newCarrierAccountData.carrierServices
    )
    setValidation(newValidationState)
  }

  return {
    calendarName,
    onCalendarNameChange,
    carrierSelection,
    onCarrierServicesChange,
    onCarrierChange,
    validation,
    validate,
  }
}

function getInitialEditCalendarValidationStatus(): CalendarValidation {
  return {
    name: {
      fieldName: 'name',
      status: ValidationStatus.Valid,
    },
    carrier: {
      fieldName: 'carrier',
      status: ValidationStatus.Valid,
    },
    carrierServices: {
      fieldName: 'carrierServices',
      status: ValidationStatus.Valid,
    },
  }
}

export interface Props {
  title: string
  children?: React.ReactNode
  editCalendar: EditCalendar
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierAccountTypes: EnhancedCaasProfile[]
  editable: boolean

  onSave: (data: EditCalendar) => Promise<void>
  onClose: () => void
}

import * as React from 'react'
import styles from './NewCalendarButton.module.css'

interface Props {
  text: string
  onClick: () => void
}

export default function NewCalendarButton(props: Props) {
  return (
    <div className={styles.buttonWrapper}>
      <button
        className={`btn btn-link ${styles.linkButton}`}
        onClick={props.onClick}
      >
        <i className="fa fa-fw fa-plus" /> {props.text}
      </button>
    </div>
  )
}

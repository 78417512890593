import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory, useParams } from 'react-router'
import { useLogisticsLocation } from '../../locations/LocationContext'
import LocationTransitCalendarContainer from '../../locations/LocationTransitCalendarContainer'
import { CalendarType } from '../models'
import useWideContainer from '../../common/components/useContainer'
import { CountryCalendarContainer } from './country/CountryCalendarContainer'
import LearnMoreAboutCalendars from './LearnMoreAboutCalendars'
import WeeklyCalendarContainer from './weekly/WeeklyCalendarContainer'
import WorkingDaysCalendar from './WorkingDaysCalendar'
import DeliveryCalendarContainer from './DeliveryCalendarContainer'

interface Props {
  routerProps: RouteComponentProps<{ calendarType: CalendarType }>
}

export default function CalendarsPage(props: Props) {
  useWideContainer()
  const { t } = useTranslation()
  const history = useHistory()
  const { calendarType } = useParams<{ calendarType: CalendarType }>()
  const { logisticsLocation } = useLogisticsLocation()

  return (
    <>
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {t('common.calendars.calendars')}
      </BreadcrumbsItem>
      <div className="row flex-align-items-center">
        <div className="col-xs-8 col-lg-8">
          <h4>{getTitle()}</h4>
        </div>
        <div className="col-xs-4 col-lg-4 text-right">
          <LearnMoreAboutCalendars />
        </div>
      </div>
      <div
        className="row display-flex"
        style={{
          marginTop: '20px',
        }}
      >
        {getCalendar(calendarType as CalendarType)}
      </div>
    </>
  )

  function getCalendar(calendar: CalendarType) {
    switch (calendar) {
      case 'working-days':
        return <WorkingDaysCalendar changeCalendarView={changeCalendarView} />

      case 'transit':
        return (
          <LocationTransitCalendarContainer
            changeCalendarView={changeCalendarView}
          />
        )

      case 'pickup':
        return (
          <WeeklyCalendarContainer
            changeCalendarView={changeCalendarView}
            calendarView={calendar}
            routerProps={props.routerProps}
            location={logisticsLocation}
          />
        )

      case 'country':
        return (
          <CountryCalendarContainer changeCalendarView={changeCalendarView} />
        )

      case 'delivery':
        return (
          <DeliveryCalendarContainer changeCalendarView={changeCalendarView} />
        )
    }
  }

  function changeCalendarView(type: CalendarType) {
    history.push(`/location/${logisticsLocation.id}/calendars/${type}`)
  }

  function getTitle(): React.ReactNode {
    let translationKey
    switch (calendarType) {
      case 'pickup':
        translationKey = 'calendars.calendarsContainer.locationsPickupCalendar'
        break
      case 'transit':
        translationKey = 'calendars.transit.transitCalendar'
        break
      case 'country':
        translationKey = 'calendars.country.countryCalendar'
        break
      case 'delivery':
        translationKey = 'calendars.delivery.deliveryCalendar'
        break
      default:
        translationKey = 'calendars.calendarsContainer.locationsCalendar'
    }

    return t(translationKey, { locationName: logisticsLocation.name })
  }
}

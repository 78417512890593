import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'
import LearnMoreAboutCalendars from '../calendars/components/LearnMoreAboutCalendars'
import { CalendarType } from '../calendars/models'
import useWideContainer from '../common/components/useContainer'
import NetworkTransitCalendarContainer from './NetworkTransitCalendarContainer'
import { useNetwork } from './NetworkContext'

export default function NetworkCalendarsPage() {
  useWideContainer()
  const { t } = useTranslation()
  const history = useHistory()
  const match = useRouteMatch()
  const { network } = useNetwork()

  const changeCalendarView = (type: CalendarType) => {
    history.push(`/network/${network.apiNetwork.id}/calendars/${type}`)
  }

  return (
    <Switch>
      <Route path={`${match.path}/:calendarType`}>
        <div className="row flex-align-items-center">
          <div className="col-xs-8 col-lg-8">
            <h4>
              {t('calendars.transit.transitCalendar', {
                locationName: network.apiNetwork.name,
              })}
            </h4>
          </div>
          <div className="col-xs-4 col-lg-4 text-right">
            <LearnMoreAboutCalendars />
          </div>
        </div>

        <NetworkTransitCalendarContainer
          changeCalendarView={changeCalendarView}
        />
      </Route>
    </Switch>
  )
}

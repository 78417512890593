import moment from 'moment-timezone'
import * as React from 'react'
import { Interval, PickupCalendar, PickupEventProps } from '../../../models'
import { findCurrentSchedule, getLowercaseWeekday } from '../pickup-calendar'
import useWeeklyCalendarContext, * as WeeklyCalendarContext from './../WeeklyCalendarContext'
import { DayCell } from './DayCell'
import HeaderRow from './HeaderRow'
import * as utils from './utils'
import styles from './WeeklyCalendar.module.css'
import WrappedPickupEvent from './WrappedPickupEvent'

export interface Props {
  firstDay: moment.Moment // the first day in the calendar
  numberOfDays: number
  dayFormat: string // header day format

  startTime: moment.Moment // the start time of the scale and calendar
  endTime: moment.Moment // the end time of the scale and calendar

  pickupEvents: PickupEventProps[]
  intervalLength: number
  tagsEnabled: boolean
}

export default class WeeklyCalendar extends React.Component<Props> {
  public static contextType = WeeklyCalendarContext.Context
  public context: React.ContextType<typeof WeeklyCalendarContext.Context>
  private ref = React.createRef<HTMLDivElement>()
  private currentTimeRowRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      columnDimensions: [],
    }
  }

  public render() {
    const intervals = utils.getIntervalsByDuration(
      this.props.intervalLength,
      this.props.startTime,
      this.props.endTime
    )
    const startDate = this.props.firstDay.format('YYYY-MM-DD')
    const endDate = this.props.firstDay
      .clone()
      .add(this.props.numberOfDays - 1, 'days')
      .format('YYYY-MM-DD')
    const pickupsByDayAndStart = utils.groupPickups(
      this.props.pickupEvents,
      startDate,
      endDate
    )

    const now = moment().tz(this.context.timezone).local(true)

    const timeRows = intervals.map((interval, index) => {
      const isCurrentInterval = now.isBetween(interval.start, interval.end)

      return (
        <TimeRow
          timeInLocationTimezone={now}
          currentTimeRowRef={
            isCurrentInterval ? this.currentTimeRowRef : undefined
          }
          key={index}
          dateTimePickupEventsMap={pickupsByDayAndStart}
          interval={interval}
          firstDate={startDate}
          lastDate={endDate}
          isLast={intervals.length === index + 1}
          tagsEnabled={this.props.tagsEnabled}
        />
      )
    })

    return (
      <div className={`${styles.weeklyCalendar} flex-vertical flex-stretch`}>
        <div className={styles.scrollWrapper} ref={this.ref}>
          <div className={styles.calendarBody}>
            <HeaderRow
              firstDay={this.props.firstDay}
              numberOfDays={this.props.numberOfDays}
              dayFormat={this.props.dayFormat}
            />
            {timeRows}
          </div>
        </div>
      </div>
    )
  }

  public async componentDidMount() {
    if (this.currentTimeRowRef.current) {
      this.currentTimeRowRef.current.scrollIntoView({ block: 'center' })
    }
  }
}

interface TimeRowProps {
  timeInLocationTimezone: moment.Moment
  dateTimePickupEventsMap: Map<string, Map<string, PickupEventProps[]>>
  interval: Interval
  firstDate: string
  lastDate: string
  isLast: boolean
  tagsEnabled: boolean
  currentTimeRowRef?: React.RefObject<HTMLDivElement>
}

function TimeRow(props: TimeRowProps) {
  const {
    setModal,
    modalData,
    multiyearInformation,
  } = useWeeklyCalendarContext()

  const startTime = props.interval.start.format('HH:mm')
  const endTime = props.interval.end.format('HH:mm')

  const scaleHeaderStyles = [styles.scaleHeader]
  if (props.currentTimeRowRef) {
    scaleHeaderStyles.push(styles.thickText)
  }

  const timeDiv = (
    <div
      ref={props.currentTimeRowRef}
      key={`scaleCell-${startTime}`}
      className={styles.scaleCell}
    >
      <div className={scaleHeaderStyles.join(' ')}>{startTime}</div>
    </div>
  )
  const dates = utils.generateDatesInRange(props.firstDate, props.lastDate)
  const results = [timeDiv]

  for (const date of dates) {
    const momentDate = moment(date)
    const cellStart = moment(`${date} ${startTime}`)
    const dayInformation =
      multiyearInformation[momentDate.year()][momentDate.month() + 1][
        momentDate.date()
      ]
    const dayMap =
      props.dateTimePickupEventsMap.get(date) ||
      new Map<string, PickupEventProps[]>()
    const applicableTimes = Array.from(dayMap.keys()).filter(
      t => t >= startTime && (t < endTime || (props.isLast && t <= endTime))
    )
    const events = applicableTimes.reduce((acc, time) => {
      return acc.concat(dayMap.get(time) || [])
    }, new Array<PickupEventProps>())
    events.sort((e1, e2) => {
      const timeDiff = e1.start.diff(e2.start)
      if (timeDiff !== 0) {
        return timeDiff
      }

      return e1.calendarDisplay.calendar.name.localeCompare(
        e2.calendarDisplay.calendar.name
      )
    })
    const allEventsStartLater =
      events.length > 0 && events[0].start.isAfter(cellStart)
    const onClickValidClass = 'onClickValidClass'
    const dataDivs = events.map(e => {
      const key = `${e.calendarDisplay.calendar.id}-${e.start.format()}`
      if (e.active) {
        return (
          <WrappedPickupEvent
            timeInLocationTimezone={props.timeInLocationTimezone}
            calendarView={e.calendarDisplay}
            color={e.color}
            active={e.active}
            start={e.start}
            end={e.end}
            tags={tags(e.calendarDisplay.calendar, e.start)}
            tagsEnabled={props.tagsEnabled}
            key={key}
            modalType={
              e.start.isSame(modalData.selectedStartTime) &&
              modalData.selectedCalendarId === e.calendarDisplay.calendar.id
                ? modalData.modalType
                : undefined
            }
            setModal={setModal}
            editable={e.calendarDisplay.editable}
          />
        )
      } else {
        return (
          <div
            key={key}
            className={onClickValidClass}
            style={{ height: '52px' }}
          />
        )
      }
    })
    results.push(
      <DayCell
        dayInformation={dayInformation}
        allEventsStartLater={allEventsStartLater}
        key={cellStart.format()}
        startTime={cellStart}
        isLastRow={props.isLast}
        onClickValidClass={onClickValidClass}
      >
        {dataDivs}
      </DayCell>
    )
  }

  return <>{results}</>
}

function tags(calendar: PickupCalendar, start: moment.Moment) {
  const startTime = start.format('HH:mm')
  const overwrite = calendar.overwrites[start.format('YYYY-MM-DD')]
  const overwritePickup = (overwrite || []).find(t => t.time === startTime)

  if (overwritePickup) {
    return overwritePickup.tags
  }

  const currentSchedule = findCurrentSchedule(calendar.weeklySchedules, start)!
  const times = currentSchedule.schedule[getLowercaseWeekday(start)]
  const pickup = times.find(t => t.time === startTime)

  return pickup ? pickup.tags : []
}

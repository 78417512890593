import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { colors, shapes } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AnchorButton from '../../../../../common/components/AnchorButton'
import { EditorPickupSchedule } from '../../../../models'
import DayOfTheWeekSelector from '../../../DayOfTheWeekSelector'
import { PickupTimeEditor } from '../../PickupTimeEditor'
import './PickupScheduleEditor.css'
import {
  getDefaultSchedule,
  mapScheduleToEditor,
  weekdays,
} from './schedule-helpers'

interface Props {
  initialSchedules?: calendars.models.PickupWeeklySchedule[]

  onChange(pickupSchedule: calendars.models.PickupWeeklySchedule): void
}

interface State {
  editorPickupSchedules: EditorPickupSchedule[]
}

export class PickupScheduleEditorContainer extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)
    this.state = {
      editorPickupSchedules:
        props.initialSchedules && props.initialSchedules.length > 0
          ? mapScheduleToEditor(
              props.initialSchedules[props.initialSchedules.length - 1]
            )
          : [getDefaultSchedule()],
    }
    this.generateSchedule()
  }

  public render() {
    const onAddSchedule = () => {
      this.setState(
        {
          editorPickupSchedules: [
            ...this.state.editorPickupSchedules,
            getDefaultSchedule(),
          ],
        },
        this.generateSchedule
      )
    }
    const onRemoveSchedule = (index: number) => {
      this.setState(
        {
          editorPickupSchedules: this.state.editorPickupSchedules.filter(
            (_: any, i: number) => index !== i
          ),
        },
        this.generateSchedule
      )
    }
    const onChangeSchedule = (
      index: number,
      schedule: EditorPickupSchedule
    ) => {
      this.setState(
        {
          editorPickupSchedules: Object.assign(
            [],
            this.state.editorPickupSchedules,
            { [index]: schedule }
          ),
        },
        this.generateSchedule
      )
    }

    return (
      <PickupScheduleEditor
        pickupSchedules={this.state.editorPickupSchedules}
        onAddSchedule={onAddSchedule}
        onRemoveSchedule={onRemoveSchedule}
        onChangeSchedule={onChangeSchedule}
      />
    )
  }

  private generateSchedule() {
    const schedule: any = {}
    weekdays.forEach(day => (schedule[day] = []))
    this.state.editorPickupSchedules.forEach(eps => {
      weekdays.forEach(day => {
        if (eps.weeklySchedule.schedule[day]) {
          schedule[day].push({ time: eps.time, tags: eps.tags })
        }
      })
    })

    this.props.onChange({
      schedule,
      validFrom: moment().format('YYYY-MM-DD'),
    })
  }
}

interface PickupScheduleEditorProps {
  pickupSchedules: EditorPickupSchedule[]

  onAddSchedule(): void

  onRemoveSchedule(index: number): void

  onChangeSchedule(index: number, schedule: EditorPickupSchedule): void
}

export function PickupScheduleEditor(props: PickupScheduleEditorProps) {
  const { t } = useTranslation()

  const handleAddSchedule = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    props.onAddSchedule()
  }

  return (
    <div className="pickup-schedule-editor">
      {props.pickupSchedules.map((pickupSchedule, index) => (
        <PickupScheduleEditorRow
          key={index}
          schedule={pickupSchedule}
          onChangeSchedule={props.onChangeSchedule.bind(null, index)}
          removable={props.pickupSchedules.length > 1}
          onRemove={props.onRemoveSchedule.bind(null, index)}
        />
      ))}
      <AnchorButton onClick={handleAddSchedule}>
        {t('calendars.weekly.pickupScheduleEditor.addPickup')}
      </AnchorButton>
    </div>
  )
}

interface PickupScheduleEditorRowProps {
  schedule: EditorPickupSchedule
  removable: boolean

  onChangeSchedule(schedule: EditorPickupSchedule): void

  onRemove(): void
}

function PickupScheduleEditorRow(props: PickupScheduleEditorRowProps) {
  const { t } = useTranslation()

  const onTimeChange = (time: string) => {
    const newSchedule = { ...props.schedule }
    newSchedule.time = time
    props.onChangeSchedule(newSchedule)
  }
  const onRemove = () => {
    props.onRemove()
  }
  const removeButton = (
    <button onClick={onRemove} className="btn remove" name="remove-schedule">
      <shapes.hamburger.Close
        width="18px"
        color={colors.ocean.base}
        cropped={true}
      />
    </button>
  )

  const onChangeSchedule = (
    schedule: calendars.models.WeekdayData<boolean>
  ) => {
    const newSchedule = { ...props.schedule }
    newSchedule.weeklySchedule.schedule = schedule
    props.onChangeSchedule(newSchedule)
  }

  return (
    <div className="pickup-editor-row">
      <PickupTimeEditor value={props.schedule.time} onChange={onTimeChange} />
      <span style={{ margin: '16px' }}>{t('every')}</span>
      <DayOfTheWeekSelector
        weeklySchedule={props.schedule.weeklySchedule.schedule}
        onChangeSchedule={onChangeSchedule}
      />
      {props.removable ? removeButton : undefined}
    </div>
  )
}

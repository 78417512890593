import {
  colors,
  shapes,
  Button,
  Checkbox,
  TextField,
} from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { DayDate } from '../models'
import { useYearlyCalendarContext } from './YearlyCalendarContext'

interface Props {
  message: React.ReactNode
  actionText: string
  addException: (modifySchedule: boolean, reason: string) => void
  dayDate: DayDate
}

export default function AddExceptionDialog(props: Props) {
  const [repeat, setRepeat] = React.useState(false)
  const [reason, setReason] = React.useState('')
  const { t } = useTranslation()
  const context = useYearlyCalendarContext()

  const textFieldOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    setReason(e.currentTarget.value)
  }

  const onClick = () => {
    props.addException(repeat, reason)
  }

  const onClickRepeat = () => {
    setRepeat(!repeat)
  }

  const buttonsRow = (
    <div className="modal-footer">
      <Button
        type="default"
        disabled={context.savingException}
        onClick={context.closeModal}
      >
        {t('common.close')}
      </Button>
      <Button
        type="primary"
        disabled={context.savingException}
        onClick={onClick}
      >
        {context.savingException
          ? t('calendars.yearly.addExceptionDialog.changing')
          : t('calendars.yearly.addExceptionDialog.change')}
      </Button>
    </div>
  )
  const buttons = context.editable ? buttonsRow : undefined

  const commentsSection = (
    <>
      <div
        style={{
          fontWeight: 'bold',
          marginTop: '16px',
          marginBottom: '4px',
        }}
      >
        {props.actionText}
      </div>
      <TextField
        value={repeat ? '' : reason}
        label={t('calendars.yearly.addExceptionDialog.anyComments')}
        onChange={textFieldOnChange}
        disabled={repeat}
        type="textarea"
      />
      <div className="form-group">
        <Checkbox
          label={t('calendars.yearly.addExceptionDialog.repeatEvery', {
            day: toMoment(props.dayDate),
          })}
          checked={repeat}
          onChange={onClickRepeat}
        />
      </div>
    </>
  )

  const comments = context.editable ? commentsSection : undefined

  return (
    <div>
      <div className="modal-header">
        <div className="modal-title">
          {t('calendars.yearly.addExceptionDialog.title', {
            date: toMoment(props.dayDate),
          })}
        </div>
        <button className="close" onClick={context.closeModal}>
          <shapes.hamburger.Close
            width="18px"
            color={colors.ocean.base}
            cropped={true}
          />
        </button>
      </div>
      <div className="modal-body">
        <div>{props.message}</div>
        {comments}
      </div>
      {buttons}
    </div>
  )
}

function toMoment(dayDate: DayDate) {
  const { year, month, day } = dayDate

  return moment({ year, month: month - 1, day })
}

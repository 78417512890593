import {
  calendars,
  locations,
} from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { v4 } from 'uuid'
import { getTransitCalendars } from '../calendars/components/transit-calendar-commons'
import TransitCalendarContainer from '../calendars/components/TransitCalendarContainer'
import { CalendarType, CreateTransitCalendar } from '../calendars/models'
import auth from '../common/auth'
import {
  getDefaultWorkingDaysCalendar,
  filterCarrierServicesForCalendar,
  addCalendarToMapping,
  deleteWorkingDaysCalendar,
} from '../common/proxy/calendars-store'
import { getCaasProfiles } from '../common/proxy/carrier-services-store'
import { useNetwork } from './NetworkContext'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export default function NetworkTransitCalendarContainer(props: Props) {
  const { network, updateNetwork } = useNetwork()

  const doUpdateNetwork = async (
    calendarId: string,
    carrierServices: string[],
    correlationId: string
  ) => {
    const mutator = (n: locations.models.LogisticsNetworkWithLinks) => {
      filterCarrierServicesForCalendar(
        n.transitCalendars.mapping,
        calendarId,
        carrierServices
      )
      addCalendarToMapping(
        n.transitCalendars.mapping,
        calendarId,
        carrierServices
      )
    }

    await updateNetwork(mutator)
  }

  const createTransitCalendar = async (
    data: CreateTransitCalendar,
    correlationId: string
  ) => {
    const wdCalendar = getDefaultWorkingDaysCalendar(
      network.apiNetwork.localeSettings.timezone,
      undefined,
      {
        logisticsNetworkId: network.apiNetwork.id,
      }
    )
    wdCalendar.weeklySchedules[0].schedule = data.weeklySchedule

    const transitCalendar = {
      ...wdCalendar,
      name: data.calendarName,
    }
    const transitCalendarId = await calendars.createWorkingDaysCalendar(
      auth.getAccessToken(),
      correlationId,
      transitCalendar
    )

    return transitCalendarId
  }

  const getNetworkCaasProfiles = () => {
    return getCaasProfiles()
  }
  const onDelete = async (calendarId: string) => {
    const correlationId = v4()
    await Promise.all([
      deleteWorkingDaysCalendar(calendarId, correlationId),
      updateNetwork(net => {
        Object.entries(net.transitCalendars.mapping)
          .filter(([, ref]) => ref.id === calendarId)
          .forEach(([key]) => {
            delete net.transitCalendars.mapping[key]
          })
      }, correlationId),
    ])
  }

  return (
    <div
      className="row display-flex"
      style={{
        marginTop: '20px',
      }}
    >
      <TransitCalendarContainer
        transitCalendars={getTransitCalendars(
          network.apiNetwork.transitCalendars.mapping
        )}
        getCaasProfiles={getNetworkCaasProfiles}
        changeCalendarView={props.changeCalendarView}
        updateOwner={doUpdateNetwork}
        createTransitCalendar={createTransitCalendar}
        canCreate={network.editable}
        deleteTransitCalendar={onDelete}
      />
    </div>
  )
}

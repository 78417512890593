import { Button, Drawer, Radio, RadioGroup } from '@cimpress/react-components'
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CanonicalCoamGroupMember, CanonicalCoamUser } from '../models'
import styles from './permissions.module.css'
import { RoleDescription } from './RoleDescription'
import { UserProfile } from './UserList'
import UserLookup from './UserLookup'

interface Props extends WithTranslation {
  roles: string[]
  show: boolean
  userToEdit?: CanonicalCoamGroupMember
  existingMembers: CanonicalCoamGroupMember[]
  blockUI: boolean
  onClose(): void
  onSave(user: CanonicalCoamUser, role: string): void
}

interface State {
  selectedUser?: CanonicalCoamUser
  selectedRole: string
}

class UserEditorDrawer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      selectedRole: '',
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.show && this.props.show) {
      this.setState({
        selectedUser: this.props.userToEdit,
        selectedRole: this.props.userToEdit
          ? this.props.userToEdit.roles[0]
          : '',
      })
    }
  }

  public render() {
    const onUserSelect = (user?: CanonicalCoamUser) =>
      this.setState({ selectedUser: user, selectedRole: '' })
    const onRoleSelect = (role: string) => this.setState({ selectedRole: role })

    const drawerContents = (
      <>
        <div style={{ marginBottom: '32px' }}>
          <UserSelector
            selectedUser={this.state.selectedUser}
            deselectable={!this.props.userToEdit}
            existingMembers={this.props.existingMembers}
            onSelect={onUserSelect}
          />
        </div>
        {this.state.selectedUser ? (
          <RoleSelector
            key={'role_selector_' + this.state.selectedUser.principal}
            defaultValue={this.state.selectedRole}
            onChange={onRoleSelect}
            roles={this.props.roles}
          />
        ) : null}
      </>
    )

    return (
      <Drawer
        show={this.props.show}
        header={
          this.props.userToEdit
            ? this.props.t('userManagement.editUser')
            : this.props.t('userManagement.addUser')
        }
        footer={this.getFooter()}
        onRequestHide={this.props.onClose}
        className={styles['user-editor-drawer']}
      >
        {this.props.show ? drawerContents : null}
      </Drawer>
    )
  }

  private getClientLabel(): string {
    return this.props.userToEdit
      ? this.props.blockUI
        ? this.props.t('userManagement.editingClient')
        : this.props.t('userManagement.editClient')
      : this.props.blockUI
      ? this.props.t('userManagement.addingClient')
      : this.props.t('userManagement.addClient')
  }

  private getUserLabel(): string {
    return this.props.userToEdit
      ? this.props.blockUI
        ? this.props.t('userManagement.editingUser')
        : this.props.t('userManagement.editUser')
      : this.props.blockUI
      ? this.props.t('userManagement.addingUser')
      : this.props.t('userManagement.addUser')
  }

  private getSaveButton() {
    const canAdd = this.state.selectedUser && this.state.selectedRole
    const onSave = () =>
      this.props.onSave(this.state.selectedUser!, this.state.selectedRole)
    const clientSelected =
      this.state.selectedUser && this.state.selectedUser.is_client
    const saveButtonLabel = clientSelected
      ? this.getClientLabel()
      : this.getUserLabel()

    return (
      <Button
        type="primary"
        disabled={!canAdd || this.props.blockUI}
        onClick={onSave}
      >
        {saveButtonLabel}
      </Button>
    )
  }

  private getFooter() {
    return (
      <>
        {!this.props.blockUI ? (
          <Button type="default" onClick={this.props.onClose}>
            Cancel
          </Button>
        ) : null}
        {this.getSaveButton()}
      </>
    )
  }
}

function UserSelector(props: {
  selectedUser?: CanonicalCoamUser
  deselectable: boolean
  existingMembers: CanonicalCoamGroupMember[]
  onSelect: (user?: CanonicalCoamUser) => void
}) {
  if (props.selectedUser) {
    const clearSelection = () => {
      props.onSelect()
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <UserProfile user={props.selectedUser} />
        <span style={{ marginLeft: '16px' }}>
          {props.deselectable ? (
            <Button type="link" onClick={clearSelection}>
              Change
            </Button>
          ) : null}
        </span>
      </div>
    )
  } else {
    return (
      <UserLookup
        onSelect={props.onSelect}
        existingMembers={props.existingMembers}
      />
    )
  }
}

function RoleSelector(props: {
  defaultValue: string
  roles: string[]
  onChange(value: string): void
}) {
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => props.onChange(value)

  return (
    <>
      <h5>Select role</h5>
      <RadioGroup
        name="roles"
        defaultSelected={props.defaultValue}
        onChange={onChange}
      >
        {props.roles.map(role => (
          <Radio
            key={role}
            label={
              <>
                <strong>{role}</strong>
                <div>
                  <RoleDescription role={role} />
                </div>
              </>
            }
            className={styles.roleCheckbox}
            value={role}
          />
        ))}
      </RadioGroup>
    </>
  )
}

export default withTranslation()(UserEditorDrawer)

import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import { getWorkingDaysPreview } from '../../common/proxy/calendars-store'
import {
  CalendarType,
  DayDate,
  DayInformation,
  YearInformation,
} from '../models'
import YearlyCalendarCard from './YearlyCalendarCard'
import { generateYearInformation } from './calendar-information'
import { modifySchedules } from './modifySchedules'
import NavigationBar from './NavigationBar'
import { Context, YearlyCalendarContextProvider } from './YearlyCalendarContext'
import YearlyCalendarView from './YearlyCalendarView'

interface Props {
  children?: React.ReactNode
  editable: boolean
  calendar: calendars.models.WorkingDaysCalendar
  updateCalendar: (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => Promise<void>
  calenderView?: CalendarType
  changeCalendarView: (type: CalendarType) => void
}

export default function YearlyCalendar(props: Props) {
  const { t } = useTranslation()
  const [year, setYear] = React.useState<number>(moment().year())
  const [yearData, setYearData] = React.useState<YearInformation>()
  const [showModalFor, setShowModalFor] = React.useState<DayDate>()
  const [savingException, setSavingException] = React.useState(false)

  const calendarId = props.calendar?.id
  React.useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      if (calendarId) {
        setYearData(undefined)
        const workingDays = await fetchWorkingDays(calendarId, year)

        if (mounted) {
          setYearData(workingDays!.yearData)
        }
      }
    }

    fetchData()

    return () => {
      mounted = false
    }
  }, [year, calendarId])

  if (!yearData) {
    return (
      <YearlyCalendarCard>
        <Preloader />
      </YearlyCalendarCard>
    )
  }

  const changeYear = async (yearToSet: number) => {
    if (year !== yearToSet) {
      setYear(yearToSet)
    }
  }

  const openModal = (day: DayDate) => setShowModalFor(day)
  const closeModal = () => setShowModalFor(undefined)

  const changeToWeek = () => props.changeCalendarView('pickup')
  const changeToYear = () => props.changeCalendarView('working-days')

  const incrementYear = () => changeYear(year + 1)
  const decrementYear = () => changeYear(year - 1)
  const goToCurrentYear = () => changeYear(moment().year())

  const addException = (
    date: DayDate,
    modifySchedule: boolean,
    explanation: string
  ): void => {
    const dayInformation = yearData[date.month][date.day]

    if (modifySchedule) {
      // intentionally not awaited:
      updateSchedules(date, dayInformation)

      return
    }

    const desiredState = !dayInformation.isWorkingDay
    const newDayInformation = {
      isWorkingDay: desiredState,
      exceptionInformation: {
        reason: explanation,
      },
    }

    // intentionally not awaited:
    updateDayInformation(date.month, date.day, newDayInformation)
  }

  const removeException = (date: DayDate): void => {
    const dayInformation = yearData[date.month][date.day]
    const desiredState = !dayInformation.isWorkingDay
    const newDayInformation = {
      isWorkingDay: desiredState,
    }
    updateDayInformation(date.month, date.day, newDayInformation)
  }

  const updateDayInformation = async (
    month: number,
    day: number,
    dayInformation: DayInformation
  ) => {
    setSavingException(true)
    const formattedDate = moment([year, month - 1, day]).format('YYYY-MM-DD')

    if (props.calendar.overwrites[formattedDate]) {
      await patchAndReloadCalendar(c => {
        delete c.overwrites[formattedDate]
      })
    } else {
      await patchAndReloadCalendar(c => {
        c.overwrites[formattedDate] = {
          isWorkingDay: dayInformation.isWorkingDay,
          description: dayInformation.exceptionInformation
            ? dayInformation.exceptionInformation.reason
            : undefined,
        }
      })
    }
  }

  const patchAndReloadCalendar = async (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => {
    try {
      await props.updateCalendar(updater)
      const workingDays = await fetchWorkingDays(props.calendar.id, year)
      setYearData(workingDays!.yearData)
    } catch (exception) {
      if (exception.response.status === 412) {
        SnackbarController.show(
          <span>{t('calendars.calendarsContainer.conflictMessage')}</span>,
          'danger'
        )
      }
      if (exception.response.status === 400) {
        const errorMessage =
          exception.response.data?.errors?.length === 1 ? (
            exception.response.data.errors[0].message
          ) : (
            <ul>
              {exception.response.data?.errors?.map((err: any) => (
                <li key={err.message}>{err.message}</li>
              ))}
            </ul>
          )
        SnackbarController.show(
          <span>
            {t('calendars.calendarsContainer.validationError', {
              errorMessage,
            })}
          </span>,
          'danger'
        )
      } else {
        throw exception
      }
    }

    setSavingException(false)
    setShowModalFor(undefined)
  }

  const updateSchedules = async (
    date: DayDate,
    dayInformation: DayInformation
  ): Promise<void> => {
    await patchAndReloadCalendar(c => {
      c.weeklySchedules = modifySchedules(
        c.weeklySchedules,
        date,
        !dayInformation.isWorkingDay
      )
    })
  }

  const context: Context = {
    addException,
    removeException,
    savingException,
    showModalFor,
    openModal,
    closeModal,
    calendarView: props.calenderView || 'working-days',
    editable: props.editable,
    today: moment(),
  }

  return (
    <YearlyCalendarCard>
      <NavigationBar
        toggleWeek={changeToWeek}
        toggleYear={changeToYear}
        prevButton={decrementYear}
        nextButton={incrementYear}
        goToToday={goToCurrentYear}
      >
        {year}
      </NavigationBar>
      <YearlyCalendarContextProvider value={context}>
        {props.children}
        <YearlyCalendarView year={year} yearData={yearData} />
      </YearlyCalendarContextProvider>
    </YearlyCalendarCard>
  )
}

const fetchWorkingDays = async (calendarId: string, currentYear: number) => {
  const workingDays = await getWorkingDaysPreview(
    calendarId,
    moment([currentYear]),
    moment([currentYear + 1])
  )
  if (!workingDays) {
    return undefined
  }

  return {
    yearData: generateYearInformation(workingDays, currentYear),
    workingDaysCalendar: workingDays.workingDaysCalendar,
  }
}

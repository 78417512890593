import { colors, Tooltip } from '@cimpress/react-components'
import { Moment } from 'moment-timezone/moment-timezone'
import React from 'react'
import DateTimeField from 'react-datetime'
import useToggle from '../hooks/useToggle'
import styles from './DatePicker.module.css'

interface Props {
  onDateChange: (date: Moment) => void
  currentDate: Moment
  children?: React.ReactNode
}

export default function DatePicker(props: Props) {
  const [areHiddenItemsVisible, toggleItems] = useToggle()
  const close = () => areHiddenItemsVisible && toggleItems(undefined)
  const show = () => !areHiddenItemsVisible && toggleItems(undefined)
  const onDateChange = (date: Moment) => {
    props.onDateChange(date)
    close()
  }

  return (
    <div
      className="datetime"
      onClick={show}
      style={{
        cursor: 'pointer',
        color: colors.info.darker,
      }}
    >
      {props.children}
      <Tooltip
        className={styles.tooltip}
        show={areHiddenItemsVisible}
        onClickOutside={close}
        variety="popover"
        tooltipStyle={{
          minWidth: '302px',
          padding: 0,
          margin: 0,
        }}
        style={{
          padding: 0,
        }}
        contents={
          <DateTimeField
            value={props.currentDate}
            className={'styles.datePicker'}
            timeFormat={false}
            input={false}
            inputProps={{ disabled: true }}
            onChange={onDateChange}
          />
        }
      >
        <i
          className="fa fa-calendar"
          style={{
            marginLeft: 10,
            marginRight: 10,
          }}
          aria-hidden="true"
        />
      </Tooltip>
    </div>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import { SnackbarController } from '../../../../../common/components/SnackbarController'
import { Location } from '../../../../../common/models'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'
import {
  updatePickupCalendar,
  filterCarrierServicesForCalendar,
  addCalendarToMapping,
} from '../../../../../common/proxy/calendars-store'
import { useLogisticsLocation } from '../../../../../locations/LocationContext'
import { EditCalendar, CalendarCarrierSelection } from '../../../../models'
import useWeeklyCalendarContext from '../../WeeklyCalendarContext'
import { hasComplexCalendarSetup } from '../../../pickup-calendar-modal-commons'
import { PickupCalendarEditorModal } from './PickupCalendarEditorModal'

interface Props {
  pickupCalendarId: string
  carrierServicesToCalendarNames: { [key: string]: string }
  caasProfiles: EnhancedCaasProfile[]
  onClose: () => void
}

export default function PickupCalendarEditModalContainer(props: Props) {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()
  const { logisticsLocation, updateLocation } = useLogisticsLocation()

  const calendar = context.pickupCalendarDaysWithLegend.find(
    pc => pc.pickupCalendarDisplay.calendar.id === props.pickupCalendarId
  )

  const pickupCalendar = calendar!.pickupCalendarDisplay.calendar

  const carrierTypesReverseMap = new Map<string, Set<EnhancedCaasProfile>>()
  for (const profile of props.caasProfiles) {
    for (const caas of profile.carrierServices) {
      const profiles =
        carrierTypesReverseMap.get(caas.key) || new Set<EnhancedCaasProfile>()
      profiles.add(profile)

      carrierTypesReverseMap.set(caas.key, profiles)
    }
  }

  const carrierAccountData = getCarrierAccountData(
    logisticsLocation,
    props.pickupCalendarId,
    carrierTypesReverseMap
  )

  const save = async (data: EditCalendar) => {
    try {
      const carrierServices = data.carrierSelection.carrierServices
      const correlationId = uuid.v4()

      if (data.calendarName !== pickupCalendar.name) {
        await updatePickupCalendar(
          pickupCalendar.id!,
          pickupCalendar.etag!,
          [
            {
              op: 'replace',
              path: '/name',
              value: data.calendarName,
            },
          ],
          correlationId
        )
      }
      await updateLocation(locationToUpdate => {
        filterCarrierServicesForCalendar(
          locationToUpdate.pickupCalendars.mapping,
          props.pickupCalendarId,
          carrierServices!
        )
        addCalendarToMapping(
          locationToUpdate.pickupCalendars.mapping,
          props.pickupCalendarId,
          carrierServices!
        )
      })
      await context.loadCalendarData()
      SnackbarController.show(
        t('calendars.weekly.pickupCalendarsLegend.pickupCalendarUpdated', {
          name: data.calendarName,
        }),
        'success'
      )

      props.onClose()
    } catch (e) {
      SnackbarController.show(
        t(
          'calendars.weekly.pickupCalendarsLegend.updatingPickupCalendarFailed',
          { name: pickupCalendar.name }
        ),
        'danger'
      )
    }
  }

  return (
    <PickupCalendarEditorModal
      hasComplexSetup={hasComplexCalendarSetup(
        logisticsLocation,
        pickupCalendar.id
      )}
      pickupCalendar={pickupCalendar}
      onClose={props.onClose}
      onSave={save}
      carrierAccountTypes={props.caasProfiles}
      carrierAccountData={carrierAccountData}
      carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
    />
  )
}

function getCarrierAccountData(
  location: Location,
  pickupCalendarId: string,
  carrierTypesReverseMap: Map<string, Set<EnhancedCaasProfile>>
): CalendarCarrierSelection {
  const carrierServices = []
  for (const csKey of Object.keys(location.pickupCalendars.mapping)) {
    const calendarMapping = location.pickupCalendars.mapping[csKey]
    if (
      calendarMapping.id === pickupCalendarId ||
      (calendarMapping.additionalCalendars &&
        calendarMapping.additionalCalendars.some(
          cal => cal.id === pickupCalendarId
        ))
    ) {
      carrierServices.push(csKey)
    }
  }

  const profilesSets = carrierServices.map(
    cs => carrierTypesReverseMap.get(cs) || new Set<EnhancedCaasProfile>()
  )
  if (profilesSets.length === 0) {
    return { carriers: [] }
  }
  const possibleProfiles = profilesSets.reduce((acc, curr) =>
    intersection(acc, curr)
  )
  if (possibleProfiles.size === 0) {
    const carriers = [
      ...new Set(
        profilesSets.flatMap(profiles =>
          [...profiles].map(profile => profile.key)
        )
      ),
    ]

    return { carriers, carrierServices }
  }
  const carrier = [...possibleProfiles.values()][0]

  return {
    carriers: [carrier.key],
    carrierServices,
  }
}

function intersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  const result = new Set<T>()
  for (const elem of setB) {
    if (setA.has(elem)) {
      result.add(elem)
    }
  }

  return result
}

import * as React from 'react'
import CalendarContainer from 'react-datetime/src/CalendarContainer'
import onClickOutside from 'react-onclickoutside'

interface WrappedCalendarContainerProps {
  view: any
  viewProps: any
  onClickOutside(): void
}

class WrappedCalendarContainerInner extends React.Component<
  WrappedCalendarContainerProps,
  {}
> {
  public handleClickOutside = (evt: any) => {
    this.props.onClickOutside()
  }

  public render() {
    return (
      <CalendarContainer
        view={this.props.view}
        viewProps={this.props.viewProps}
      />
    )
  }
}
export const WrappedCalendarContainer = onClickOutside(
  WrappedCalendarContainerInner
)

import { Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

function isTextOverflow(element: HTMLElement) {
  return element.offsetWidth < element.scrollWidth
}

interface Props {
  children: React.ReactElement<any>

  tooltip: any

  overflowTarget?: (instance: HTMLElement) => ChildNode
}

interface State {
  overflow: boolean
  hovered: boolean
}

export default class OverflowTooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      overflow: false,
      hovered: false,
    }
  }

  public componentDidMount() {
    this.checkOverflow()
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.children !== this.props.children) {
      this.checkOverflow()
    }
  }

  public checkOverflow() {
    const element = ReactDOM.findDOMNode(this) as HTMLElement

    const target = (this.props.overflowTarget
      ? this.props.overflowTarget(element)
      : element) as HTMLElement
    const overflow = isTextOverflow(target)

    if (overflow !== this.state.overflow) {
      this.setState({ overflow })
    }
  }

  public render() {
    if (this.state.overflow) {
      if (this.state.hovered) {
        return (
          <Tooltip
            contents={this.props.tooltip}
            containerClassName="overflow-tooltip"
          >
            <div
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
            >
              {this.props.children}
            </div>
          </Tooltip>
        )
      } else {
        return (
          <div
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            className="overflow-tooltip"
          >
            {this.props.children}
          </div>
        )
      }
    }

    return <div className="overflow-tooltip">{this.props.children}</div>
  }

  private onMouseEnter = () => {
    this.setState({ hovered: true })
  }

  private onMouseLeave = () => {
    this.setState({ hovered: false })
  }
}

import moment from 'moment-timezone'
import * as React from 'react'
import { CalendarType, DayDate } from '../models'

export interface Context {
  addException: (date: DayDate, modifySchedule: boolean, reason: string) => void
  removeException: (date: DayDate) => void
  savingException: boolean
  showModalFor?: DayDate
  closeModal: () => void
  openModal: (day: DayDate) => void
  calendarView: CalendarType
  editable: boolean
  today: moment.Moment
}

const Context = React.createContext<Context>({} as Context)

export const YearlyCalendarContextProvider = Context.Provider
export const YearlyCalendarContextConsumer = Context.Consumer

export function useYearlyCalendarContext() {
  return React.useContext(Context)
}

import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'

export interface Context {
  countryCalendarMapping: locations.models.CountryCalendarsMapping
  selectedCountry: string
  changeSelectedCountry(country: string): Promise<void>
}

const Context = React.createContext<Context>({} as Context)

export const CountryCalendarContextProvider = Context.Provider

export function useCountryCalendarContext() {
  return React.useContext(Context)
}

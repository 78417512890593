import * as React from 'react'
import { useTranslation } from 'react-i18next'
import IconMessagesBubbleQuestion from '@cimpress-technology/react-streamline-icons/lib/IconMessagesBubbleQuestion'
import OpenInNewTab from '../../common/components/OpenInNewTab'

export default function LearnMoreAboutCalendars() {
  const { t } = useTranslation()

  return (
    <OpenInNewTab href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/342294667/Managing+Calendars">
      <span style={{ verticalAlign: 'middle' }}>
        <IconMessagesBubbleQuestion />
      </span>{' '}
      {t('calendars.calendarsContainer.learnMore')}
    </OpenInNewTab>
  )
}

import * as React from 'react'
import moment from 'moment-timezone'
import * as uuid from 'uuid'
import * as client from '@cimpress-technology/logistics-configuration-client'
import { clone } from '../../../common/helpers/clone'
import * as calendarsStore from '../../../common/proxy/calendars-store'
import { useLogisticsLocation } from '../../../locations/LocationContext'
import {
  CalendarType,
  CountryCalendarCustomData,
  LastAccessedList,
  COUNTRY_CALENDARS_CUSTOM_DATA_ID,
} from '../../models'
import { bearerToken } from '../../../common/auth'
import { CountryCalendarContextProvider } from './CountryCalendarContext'
import CountryCalendarView from './CountryCalendarView'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export function CountryCalendarContainer(props: Props) {
  const { logisticsLocation, updateLocation } = useLogisticsLocation()
  const [selectedCountry, setSelectedCountry] = React.useState(
    logisticsLocation.address.countryCode
  )
  const [selectedCalendar, setSelectedCalendar] = React.useState<
    client.calendars.models.WorkingDaysCalendar
  >()
  const [
    countryCalendarCustomData,
    setCountryCalendarCustomData,
  ] = React.useState<CountryCalendarCustomData>()

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await client.locations.getCustomData<LastAccessedList>(
        bearerToken(),
        uuid.v4(),
        logisticsLocation.id,
        COUNTRY_CALENDARS_CUSTOM_DATA_ID
      )
      const customData = result ?? { data: { lastAccessed: [] } }
      setCountryCalendarCustomData(customData)
      setSelectedCountry(
        customData.data.lastAccessed[0] ?? logisticsLocation.address.countryCode
      )
    }

    fetchData()
  }, [logisticsLocation.address.countryCode, logisticsLocation.id])

  const selectedCalendarId =
    logisticsLocation.countryCalendars.mapping[selectedCountry].id
  React.useEffect(() => {
    const fetchData = async () => {
      const calendar = await calendarsStore.getWorkingDaysCalendar(
        selectedCalendarId
      )
      setSelectedCalendar(calendar)
    }
    fetchData()
  }, [selectedCalendarId])

  const context = {
    countryCalendarMapping: logisticsLocation.countryCalendars.mapping,
    selectedCountry,
    changeSelectedCountry: async (countryCode: string) => {
      if (!countryCalendarCustomData) {
        return
      }
      setSelectedCountry(countryCode)
      const updatedLastAccess = {
        lastAccessed: [
          countryCode,
          ...countryCalendarCustomData.data.lastAccessed.filter(
            la => la !== countryCode
          ),
        ],
      }
      const etag = await client.locations.putCustomData(
        bearerToken(),
        uuid.v4(),
        logisticsLocation.id,
        COUNTRY_CALENDARS_CUSTOM_DATA_ID,
        updatedLastAccess,
        countryCalendarCustomData.etag
      )

      setCountryCalendarCustomData({ data: updatedLastAccess, etag })
    },
    lastAccessedCalendars: countryCalendarCustomData?.data?.lastAccessed,
  }
  const calendarUpdater = async (
    updater: (calendar: client.calendars.models.WorkingDaysCalendar) => void
  ) => {
    if (selectedCalendar) {
      const [prefix, countryCode] = selectedCalendar.id.split('-')
      if (prefix === 'default') {
        const updated = clone(selectedCalendar)
        updater(updated)
        const { id, etag, ...cleanedCalendar } = updated

        cleanedCalendar.owner = { logisticsLocationId: logisticsLocation.id }

        const correlationId = uuid.v4()

        const countryCalendarId = await calendarsStore.createWorkingDaysCalendar(
          cleanedCalendar,
          correlationId
        )

        try {
          const startDate = moment().startOf('isoWeek')
          const endDate = moment().endOf('isoWeek')
          const [calendarData] = await Promise.all([
            calendarsStore.getWorkingDaysPreview(
              countryCalendarId,
              startDate,
              endDate
            ),
            await updateLocation(location => {
              location.countryCalendars.mapping[countryCode] = {
                id: countryCalendarId,
              }
            }),
          ])
          setSelectedCalendar(calendarData?.workingDaysCalendar)
        } catch (e) {
          await calendarsStore.deleteWorkingDaysCalendar(
            countryCalendarId,
            correlationId
          )
          throw e
        }
      } else {
        const updated = await calendarsStore.wdUpdater(
          selectedCalendar!,
          updater
        )
        setSelectedCalendar(updated)
      }
    }
  }

  return (
    <CountryCalendarContextProvider value={context}>
      <CountryCalendarView
        lastAccessedCalendars={countryCalendarCustomData?.data?.lastAccessed}
        calendar={selectedCalendar}
        updateCalendar={calendarUpdater}
        changeCalendarView={props.changeCalendarView}
      />
    </CountryCalendarContextProvider>
  )
}

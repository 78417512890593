import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { Link, useRouteMatch } from 'react-router-dom'
import IconReceiptRegisterPrint from '@cimpress-technology/react-streamline-icons/lib/IconReceiptRegisterPrint'
import { useLogisticsLocation } from '../../LocationContext'
import RegionUnits from '../RegionUnits'
import Address from '../../../common/components/Address'
import EditLocationButton from './EditLocationButton'
import styles from './LocationDetails.module.css'
import PrimaryContact from './PrimaryContact'
import ConfigureCard from './ConfigureCard'

export default function LocationDetails() {
  const { t } = useTranslation()
  const match = useRouteMatch()
  const { logisticsLocation } = useLogisticsLocation()

  const fulfillmentLocationLabel = t('common.fulfillers.fulfillmentLocation', {
    count: logisticsLocation.fulfillmentLocations.length,
  })

  const fulfillmentLocations = logisticsLocation.fulfillmentLocations
    .map(loc => ({
      FulfillmentLocationId: loc.id,
      FulfillmentLocationName: loc.name,
      FulfillerId: loc.fulfiller.fulfillerId,
      FulfillerName:
        loc.fulfiller.name ||
        `${t('common.fulfillers.fulfiller')} ${loc.fulfiller.fulfillerId}`,
      isArchived: loc.isArchived,
    }))
    .sort((a, b) => {
      if (a.isArchived) {
        return 1
      }
      if (b.isArchived) {
        return -1
      }

      return `${a.FulfillerName} ${a.FulfillmentLocationName}`.localeCompare(
        `${b.FulfillerName} ${b.FulfillmentLocationName}`
      )
    })

  return (
    <>
      <BreadcrumbsItem to="#">{logisticsLocation.name}</BreadcrumbsItem>
      <div className="row">
        <div className="col-xs-9 col-lg-9">
          <div>
            <h4>{logisticsLocation.name}</h4>
          </div>
        </div>
        <div className="col-xs-3 col-lg-3" />
      </div>
      <div className={`${styles.locationDetails} row`}>
        <div className="col-xs-12 col-sm-4 col-lg-4">
          <ConfigureCard />
        </div>
        <div className="col-xs-12 col-sm-8 col-lg-8">
          <div className="card card-minimal">
            <div className="card-header flex">
              <h5 className="card-title flex-stretch">
                <span style={{ verticalAlign: 'middle' }}>
                  <IconReceiptRegisterPrint
                    style={{
                      marginRight: '8px',
                      height: '21px',
                      fill: 'var(--alloy)',
                    }}
                  />
                </span>{' '}
                {t('locations.viewLocationPage.aboutLocation')}
              </h5>
              <div style={{ marginTop: '4px' }}>
                {logisticsLocation.editable ? (
                  <EditLocationButton path={`${match.url}/edit`} />
                ) : null}
              </div>
            </div>
            <div>
              <div className="card-block">
                <div className="row">
                  <div className="col-xs-12 col-sm-5 col-lg-6">
                    <Address address={logisticsLocation.address} />
                    <h5>{fulfillmentLocationLabel}</h5>
                    <ul>
                      {fulfillmentLocations.map(fl => {
                        const flName = `${fl.FulfillerName} • ${fl.FulfillmentLocationName}`
                        const formatted = fl.isArchived ? (
                          <i>{flName}</i>
                        ) : (
                          flName
                        )

                        return (
                          <li key={fl.FulfillmentLocationId}>{formatted}</li>
                        )
                      })}
                    </ul>
                    <Network />
                    <PrimaryContact contact={logisticsLocation.contact} />
                  </div>
                  <div className="col-xs-12 col-sm-7 col-lg-6">
                    <RegionUnits
                      localeSettings={logisticsLocation.localeSettings}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function Network() {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  if (logisticsLocation._links && logisticsLocation._links.network) {
    return (
      <>
        <h5>{t('locations.viewLocationPage.network')}</h5>
        <p>
          <Link to={`/network/${logisticsLocation._links.network.id}`}>
            {logisticsLocation._links.network.name}
          </Link>
        </p>
      </>
    )
  }

  return <></>
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import './CalendarLegend.css'

export function PlantCalendarsLegend() {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('calendars.calendarLegend.title')}</h5>
      <ul className="fa-ul">
        <li>
          <i className="legend-glyph working-days" />{' '}
          {t('calendars.calendarLegend.workingDays')}
        </li>
        <li>
          <i className="legend-glyph nonworking-days" />{' '}
          {t('calendars.calendarLegend.nonWorkingDays')}
        </li>
        <li>
          <i className="legend-glyph public-holidays" />{' '}
          {t('calendars.calendarLegend.publicHolidays')}
        </li>
      </ul>
    </>
  )
}

export function TransitCalendarLegend() {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('calendars.calendarLegend.title')}</h5>
      <ul className="fa-ul">
        <li>
          <i className="legend-glyph working-days" />{' '}
          {t('calendars.calendarLegend.transitDay')}
        </li>
        <li>
          <i className="legend-glyph nonworking-days" />{' '}
          {t('calendars.calendarLegend.nonTransitDay')}
        </li>
      </ul>
    </>
  )
}

export function DeliveryCalendarLegend() {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('calendars.calendarLegend.title')}</h5>
      <ul className="fa-ul">
        <li>
          <i className="legend-glyph working-days" />{' '}
          {t('calendars.calendarLegend.deliveryDay')}
        </li>
        <li>
          <i className="legend-glyph nonworking-days" />{' '}
          {t('calendars.calendarLegend.nonDeliveryDay')}
        </li>
      </ul>
    </>
  )
}

import * as React from 'react'

export default function YearlyCalendarCard(props: {
  children?: React.ReactNode
}) {
  return (
    <div className="col-sm-8 col-md-8 col-lg-9 flex padding-bottom-full">
      <div className="card" style={{ width: '100%', height: '100%' }}>
        <div className="card-block year-calendar calendar-content flex-vertical">
          {props.children}
        </div>
      </div>
    </div>
  )
}

import * as React from 'react'
import { v4 } from 'uuid'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import { ResourceTypes } from '@cimpress-technology/coam-sapidus/dist/coam-models'
import { Alert } from '@cimpress/react-components'
import { Trans } from 'react-i18next'
import { bearerToken } from '../../common/auth'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { deletePickupCalendar } from '../../common/proxy/calendars-store'
import useWeeklyCalendarContext from './weekly/WeeklyCalendarContext'
import { Props, CalendarDeleteModal } from './CalendarDeleteModal'
import { hasComplexCalendarSetup } from './pickup-calendar-modal-commons'

export default function PickupCalendarDeleteModal(
  props: Omit<Props, 'onDelete' | 'calendarName'>
) {
  const { updateLocation, logisticsLocation } = useLogisticsLocation()
  const context = useWeeklyCalendarContext()

  const calendar = context.pickupCalendarDaysWithLegend.find(
    pc => pc.pickupCalendarDisplay.calendar.id === props.calendarId
  )
  const onDelete = async (calendarId: string) => {
    const correlationId = v4()
    await Promise.all([
      deletePickupCalendar(calendarId, correlationId),
      updateLocation(location => {
        Object.entries(location.pickupCalendars.mapping)
          .filter(([, ref]) => ref.id === calendarId)
          .forEach(([key]) => {
            delete location.pickupCalendars.mapping[key]
          })
      }, correlationId),
      locations.unlinkResourceFromLocationGroup(
        bearerToken(),
        correlationId,
        logisticsLocation.id,
        calendarId,
        ResourceTypes.Calendar
      ),
    ])
    await context.loadCalendarData()
  }

  const complexSetup = hasComplexCalendarSetup(
    logisticsLocation,
    props.calendarId
  )

  return (
    <CalendarDeleteModal
      calendarId={props.calendarId}
      calendarName={calendar?.pickupCalendarDisplay.calendar.name ?? ''}
      onDelete={onDelete}
      onClose={props.onClose}
    >
      {complexSetup && (
        <Alert
          dismissible={false}
          message={
            <Trans i18nKey="calendars.deleteModal.complexMapping">
              This calendar has custom setup, please contact{' '}
              <a href="mailto:logisticssupport@cimpress.com">
                logisticssupport@cimpress.com
              </a>{' '}
              to delete it.
            </Trans>
          }
        />
      )}
    </CalendarDeleteModal>
  )
}

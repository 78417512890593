import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import {
  getWorkingDaysCalendar,
  checkPermission,
  wdUpdater,
} from '../../common/proxy/calendars-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { CalendarType } from '../models'
import YearlyCalendarCard from './YearlyCalendarCard'
import { PlantCalendarsLegend } from './legend/CalendarLegend'
import { SelectCalendar } from './SelectCalendar'
import YearlyCalendar from './YearlyCalendar'

interface Props {
  changeCalendarView(type: CalendarType): void
}

export default function WorkingDaysCalendar(props: Props) {
  const { t } = useTranslation()
  const [editable, setEditable] = React.useState(false)
  const [calendar, setCalendar] = React.useState<
    calendars.models.WorkingDaysCalendar
  >()
  const { logisticsLocation } = useLogisticsLocation()

  React.useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      const isEditable = await checkPermission(
        logisticsLocation.workingDaysCalendar.id,
        coam.models.CalendarPermissions.Update
      )

      if (mounted) {
        setEditable(isEditable)
      }
      const loadedCalendar = await getWorkingDaysCalendar(
        logisticsLocation.workingDaysCalendar.id
      )
      setCalendar(loadedCalendar)
    }

    fetchData()

    return () => {
      mounted = false
    }
  }, [logisticsLocation.workingDaysCalendar])

  const update = async (
    updater: (calendar: calendars.models.WorkingDaysCalendar) => void
  ) => {
    if (!calendar) {
      return
    }
    const updated = await wdUpdater(calendar, updater)
    setCalendar(updated)
  }

  return (
    <>
      <div
        className="col-xs-12 col-sm-4 col-md-4 col-lg-3 calendar-legend container"
        style={{ paddingBottom: '16px' }}
      >
        <div className="card" style={{ marginBottom: '16px' }}>
          <div className="card-block plant-calendar-legend">
            <SelectCalendar />
            <PlantCalendarsLegend />
          </div>
        </div>
        <div className="card card-minimal">
          <div className="card-header">
            <h5 className="card-title">Advanced</h5>
          </div>
          <div className="card-block">
            <Link
              to={`/location/${logisticsLocation.id}/history?resourceId=${logisticsLocation.workingDaysCalendar.id}`}
            >
              {t('common.history')}{' '}
              <span className="badge badge-info">beta</span>
            </Link>
          </div>
        </div>
      </div>
      {calendar ? (
        <YearlyCalendar
          editable={editable}
          calendar={calendar}
          updateCalendar={update}
          changeCalendarView={props.changeCalendarView}
        />
      ) : (
        <YearlyCalendarCard>
          <Preloader />
        </YearlyCalendarCard>
      )}
    </>
  )
}

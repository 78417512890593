import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'
import {
  EditCalendar,
  PickupCalendar,
  CalendarCarrierSelection,
} from '../../../../models'
import CalendarEditModal from '../../../CalendarEditModal'
import ComplexSetupView from '../../../ComplexSetupView'

interface Props {
  hasComplexSetup: boolean
  pickupCalendar: PickupCalendar
  carrierAccountTypes: EnhancedCaasProfile[]
  carrierAccountData: CalendarCarrierSelection
  carrierServicesToCalendarNames: { [key: string]: string }
  onClose: () => void
  onSave: (data: EditCalendar) => Promise<void>
}

export function PickupCalendarEditorModal(props: Props) {
  const { t } = useTranslation()

  const carrierProfiles = props.carrierAccountTypes.filter(a =>
    props.carrierAccountData.carriers.includes(a.key)
  )

  const carrierName = carrierProfiles.map(profile => profile.name).join(', ')

  const complexSetup = props.hasComplexSetup ? (
    <ComplexSetupView
      carrierName={carrierName}
      carrierServices={props.carrierAccountData.carrierServices!}
    />
  ) : undefined

  return (
    <CalendarEditModal
      title={t('calendars.weekly.editPickupCalendarDialogButton')}
      editCalendar={{
        calendarName: props.pickupCalendar.name,
        carrierSelection: props.carrierAccountData,
      }}
      carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
      carrierAccountTypes={props.carrierAccountTypes}
      onSave={props.onSave}
      onClose={props.onClose}
      editable={true}
    >
      {complexSetup}
    </CalendarEditModal>
  )
}

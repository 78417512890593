import { Alert } from '@cimpress/react-components'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import * as jsonPatch from 'fast-json-patch'
import moment from 'moment-timezone'
import * as queryString from 'query-string'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { PickupCalendarWithIncludes } from '@cimpress-technology/logistics-configuration-client/js/models/calendars/calendar-models.v0'
import Preloader from '../../../common/components/Preloader'
import * as models from '../../../common/models'
import {
  getPickupCalendarsForLocation,
  getWorkingDaysPreview,
  updatePickupCalendar,
  updatePickupCalendarsColors,
  getPickupCalendarsColors,
} from '../../../common/proxy/calendars-store'
import {
  AddPickupHandler,
  CalendarType,
  ChangeType,
  EditEventHandler,
  PickupCalendar,
  PickupCalendarDaysWithLegend,
  PickupCalendarsColors,
} from '../../models'
import { generateMultiyearInformation } from '../calendar-information'
import { PlantCalendarsLegend } from '../legend/CalendarLegend'
import NavigationBar from '../NavigationBar'
import { pickupColors } from '../pickupColors'
import { SelectCalendar } from '../SelectCalendar'
import * as updater from './pickup-calendar-updater'
import CalendarView from './weekly-calendar/CalendarView'
import * as WeeklyCalendarContext from './WeeklyCalendarContext'

interface State {
  calendarDisplay?: models.CalendarDisplay
  pickupCalendarDaysWithLegend?: PickupCalendarDaysWithLegend[]
  loading: boolean
  error?: boolean
  currentDate: moment.Moment
  selectedStartTime?: moment.Moment
  selectedCalendarId?: string
  modalType?: WeeklyCalendarContext.ModalTypes
}

interface Props extends WithTranslation {
  location: models.Location
  calendarView: CalendarType
  routerProps: RouteComponentProps

  changeCalendarView(type: CalendarType): void
}

class WeeklyCalendarContainer extends React.Component<Props, State> {
  private mounted = false

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      currentDate: moment(),
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <div style={{ width: '100%' }}>
          <Alert
            dismissible={false}
            title={this.props.t(
              'calendars.weekly.weeklyCalendarContainer.errorLoadingTitle'
            )}
            message={
              <div>
                <span>
                  {this.props.t(
                    'calendars.weekly.weeklyCalendarContainer.errorLoadingComment'
                  ) + ' '}
                </span>
                <a href="mailto:logisticssupport@cimpress.com">
                  logisticssupport@cimpress.com
                </a>
              </div>
            }
          />
        </div>
      )
    }

    if (this.state.loading) {
      return (
        <>
          <div className="col-md-4 col-lg-3 calendar-legend container">
            <div className="card">
              <div className="card-block pickup-calendar-legend flex-vertical">
                <SelectCalendar disabled={true} />
                <PlantCalendarsLegend />
                <h5 style={{ display: 'inline-block' }}>
                  {this.props.t('common.calendars.pickupCalendars')}
                </h5>
                <Preloader small={true} />
              </div>
            </div>
          </div>
          <div className="col-md-8 col-lg-9 flex">
            <div className="card weekCalendar-group" style={{ width: '100%' }}>
              <div className="card-block flex-vertical">
                <NavigationBar
                  hideButtons={true}
                  toggleWeek={this.props.changeCalendarView.bind(
                    undefined,
                    'pickup'
                  )}
                  toggleYear={this.props.changeCalendarView.bind(
                    undefined,
                    'working-days'
                  )}
                />
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Preloader />
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    const currentYear = this.state.currentDate.year()
    const multiyearInformation = generateMultiyearInformation(
      this.state.calendarDisplay!,
      [currentYear - 1, currentYear, currentYear + 1]
    )

    const timezone = this.props.location
      ? this.props.location.localeSettings.timezone
      : ''

    const setModal = (
      selectedStartTime?: moment.Moment,
      modalType?: WeeklyCalendarContext.ModalTypes,
      selectedCalendarId?: string
    ) => {
      this.setState({ modalType, selectedStartTime, selectedCalendarId })
    }

    const context: WeeklyCalendarContext.Context = {
      calendarDisplay: this.state.calendarDisplay!,
      pickupCalendarDaysWithLegend: this.state.pickupCalendarDaysWithLegend!,
      modalData: {
        selectedStartTime: this.state.selectedStartTime,
        selectedCalendarId: this.state.selectedCalendarId,
        modalType: this.state.modalType,
      },
      setModal,
      onPickupCalendarToggle: this.onPickupCalendarToggle,
      onPickupCalendarSelectAll: this.onPickupCalendarSelectAll,
      onPickupCalendarDeselectAll: this.onPickupCalendarDeselectAll,
      removePickupEvent: this.removePickupEvent,
      editPickupEvent: this.editPickupEvent,
      addPickupEvent: this.addPickupEvent,
      changeWeek: this.changeWeek,
      currentDate: this.state.currentDate,
      changeToYearly: () => this.props.changeCalendarView('working-days'),
      canAddCalendar: this.props.location
        ? this.props.location.editable
        : false,
      multiyearInformation,
      timezone,
      tagsEnabled: this.props.location!.tagsEnabled,
      loadCalendarData: () => this.loadCalendarData(),
    }

    return (
      <WeeklyCalendarContext.Provider value={context}>
        <CalendarView />
      </WeeklyCalendarContext.Provider>
    )
  }

  public async componentDidMount() {
    this.mounted = true
    await this.loadCalendarData()
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public async componentDidUpdate(prevProps: {}, prevState: State) {
    if (!prevState.currentDate.isSame(this.state.currentDate)) {
      this.loadCalendarData()
    }
  }

  public async loadCalendarData(date?: moment.Moment): Promise<void> {
    try {
      const calendarData = await debouncedFetchCalendarData(
        this.props,
        this.state,
        date
      )
      if (this.mounted) {
        this.setState({
          ...calendarData,
          loading: false,
        })
      }
    } catch (e) {
      this.setState({ error: e.message || 'unknown error' })
    }
  }

  public onPickupCalendarToggle = (id: string) => {
    const calendar = this.state
      .pickupCalendarDaysWithLegend!.map(pc => pc.pickupCalendarDisplay)
      .find(pc => pc.calendar.id === id)!
    calendar.active = !calendar.active

    const activePickupCalendars = this.state
      .pickupCalendarDaysWithLegend!.map(pc => pc.pickupCalendarDisplay)
      .filter(pcd => pcd.active)

    if (
      activePickupCalendars.length ===
      this.state.pickupCalendarDaysWithLegend!.length
    ) {
      this.props.routerProps.history.replace({
        pathname: this.props.routerProps.location.pathname,
      })
    } else {
      const pickupCalendar =
        activePickupCalendars.length === 0
          ? ['']
          : activePickupCalendars.map(pc => pc.calendar.id!)
      this.props.routerProps.history.replace({
        pathname: this.props.routerProps.location.pathname,
        search: queryString.stringify({ pickupCalendar }),
      })
    }

    this.forceUpdate()
  }

  public onPickupCalendarSelectAll = () => {
    this.state
      .pickupCalendarDaysWithLegend!.map(pc => pc.pickupCalendarDisplay)
      .forEach(c => (c.active = true))

    this.props.routerProps.history.replace({
      pathname: this.props.routerProps.location.pathname,
    })
    this.forceUpdate()
  }

  public onPickupCalendarDeselectAll = () => {
    this.state
      .pickupCalendarDaysWithLegend!.map(pc => pc.pickupCalendarDisplay)
      .forEach(c => (c.active = false))

    this.props.routerProps.history.replace({
      pathname: this.props.routerProps.location.pathname,
      search: queryString.stringify({ pickupCalendar: [''] }),
    })
    this.forceUpdate()
  }

  public updatePickupCalendar = async (
    oldPickupCalendar: PickupCalendar,
    newPickupCalendar: PickupCalendar
  ) => {
    const patch = jsonPatch.compare(oldPickupCalendar, newPickupCalendar)
    await updatePickupCalendar(
      oldPickupCalendar.id!,
      oldPickupCalendar.etag!,
      patch
    )

    return this.loadCalendarData()
  }
  public changeWeek = (direction: number) => {
    const currentDate = moment(this.state.currentDate).add(direction, 'week')

    const formattedDate = currentDate.format('YYYY-MM-DD')
    const currentDay = this.state.calendarDisplay!.days[formattedDate]
    if (!currentDay) {
      this.setState({ loading: true })
    }

    this.setState({
      currentDate,
    })
  }

  private removePickupEvent = (
    startTime: moment.Moment,
    calendar: PickupCalendar,
    changeType: ChangeType,
    tags: string[]
  ): Promise<void> => {
    const updated = updater.removePickup(calendar, changeType, startTime, tags)

    return this.updatePickupCalendar(calendar, updated)
  }

  private editPickupEvent: EditEventHandler = (
    startTime: moment.Moment,
    calendar: PickupCalendar,
    changeType: ChangeType,
    tags: string[],
    targetTime: string,
    updatedTags: string[]
  ): Promise<void> => {
    const updated = updater.editPickup(
      calendar,
      changeType,
      startTime,
      tags,
      targetTime,
      updatedTags
    )

    return this.updatePickupCalendar(calendar, updated)
  }

  private addPickupEvent: AddPickupHandler = async (
    calendar: PickupCalendar,
    changeType: ChangeType,
    date: moment.Moment,
    tags: string[]
  ): Promise<void> => {
    const updated = updater.addPickup(calendar, changeType, date, tags)
    await this.updatePickupCalendar(calendar, updated)
    this.setState({ selectedStartTime: undefined, modalType: undefined })
  }
}

export default withTranslation()(WeeklyCalendarContainer)

const debouncedFetchCalendarData = AwesomeDebouncePromise(
  fetchCalendarData,
  300
)

async function fetchCalendarData(
  props: Props,
  state: State,
  date?: moment.Moment
) {
  const dateToLoad = date || state.currentDate
  const startDate = moment(dateToLoad).add(-10, 'weeks').startOf('isoWeek')
  const endDate = moment(dateToLoad).add(10, 'weeks').endOf('isoWeek')
  const [
    calendarDisplay,
    pickupCalendarAndDays,
    pickupCalendarsColorsCustomData,
  ] = await Promise.all([
    getWorkingDaysPreview(
      props.location.workingDaysCalendar.id,
      startDate,
      endDate
    ),
    getPickupCalendarsForLocation(props.location.id, startDate, endDate),
    getPickupCalendarsColors(props.location.id),
  ])

  if (!calendarDisplay || !pickupCalendarAndDays) {
    return
  }

  const pickupCalendarsColors = pickupCalendarsColorsCustomData ?? {
    data: { colorMap: {} },
    etag: undefined,
  }

  const mappedColors = mapPickupCalendarsColors(
    pickupCalendarsColors.data,
    pickupCalendarAndDays
  )

  updatePickupCalendarsColors(
    props.location.id,
    pickupCalendarsColors,
    mappedColors
  )

  const { showAll, pickupCalendarIds } = parsePickupCalendarId(
    props.routerProps.location.search
  )

  const pickupCalendarDaysWithLegend = pickupCalendarAndDays.map(pc => {
    const active =
      showAll ||
      (pickupCalendarIds.find(pci => pci === pc.id!) === undefined
        ? false
        : true)

    return {
      days: (pc._includes!.days || []).map(pd => {
        return {
          ...pd,
          time: pd.time.replace(/[+-]\d\d:\d\d/, ''),
        }
      }),
      pickupCalendarDisplay: {
        calendar: pc,
        active,
        color: mappedColors.colorMap[pc.id],
        editable: pc._includes!.editable!,
      },
    }
  })

  return { calendarDisplay, pickupCalendarDaysWithLegend }
}

function parsePickupCalendarId(search: string) {
  const queryInput = queryString.parse(search).pickupCalendar
  if (!queryInput) {
    return { showAll: true, pickupCalendarIds: [] }
  }

  const pickupCalendarIds =
    typeof queryInput === 'string' ? [queryInput] : queryInput

  return { showAll: false, pickupCalendarIds }
}

function mapPickupCalendarsColors(
  pickupCalendarsColors: PickupCalendarsColors,
  calendars: PickupCalendarWithIncludes[]
) {
  const used = Object.values(pickupCalendarsColors.colorMap)
  const unused = pickupColors.filter(color => !used.includes(color))
  const allowed = unused.length === 0 ? pickupColors : unused
  let index = 0

  const result: PickupCalendarsColors = calendars.reduce(
    (acc, calendar) => {
      if (pickupCalendarsColors.colorMap[calendar.id]) {
        acc.colorMap[calendar.id] = pickupCalendarsColors.colorMap[calendar.id]
      } else {
        acc.colorMap[calendar.id] = allowed[index % allowed.length]
        index++
      }

      return acc
    },
    { colorMap: {} }
  )

  return result
}

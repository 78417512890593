import { Radio, RadioGroup } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { SnackbarController } from '../../../common/components/SnackbarController'
import { logError } from '../../../common/logger'
import { ChangeType, PickupCalendar } from '../../models'
import AwaitingModal from '../AwaitingModal'
import { VenloOnlyWarning } from './VenloOnlyWarning'
import * as WeeklyCalendarContext from './WeeklyCalendarContext'

interface Props extends WithTranslation {
  calendar: PickupCalendar
  startTime: moment.Moment
  tags: string[]
  onClose: () => void
}

interface State {
  changeType: ChangeType
}

class RemovePickupModal extends React.Component<Props, State> {
  public static contextType = WeeklyCalendarContext.Context

  public context: React.ContextType<typeof WeeklyCalendarContext.Context>

  constructor(props: Props) {
    super(props)
    this.state = { changeType: 'current' }
  }

  public render() {
    const onRemove = async () => {
      try {
        await this.context.removePickupEvent(
          this.props.startTime,
          this.props.calendar,
          this.state.changeType,
          this.props.tags
        )

        SnackbarController.show(
          <span>{this.getPickupRemovedSnackbarMessage()}</span>,
          'success'
        )
      } catch (e) {
        SnackbarController.show(
          <span>
            {this.props.t(
              'calendars.weekly.removePickupModal.pickupRemovingFailed'
            )}
          </span>,
          'danger'
        )
        logError('Error removing pickup', e)
      }
    }

    const footer = [
      {
        handler: this.props.onClose,
        text: this.props.t('common.cancel'),
      },
      {
        type: 'primary',
        handler: onRemove,
        text: this.props.t('calendars.weekly.removePickupModal.remove'),
      },
    ]

    const setCurrent = (
      e: React.ChangeEvent<HTMLInputElement>,
      value: ChangeType
    ) => this.setChangeType(value)

    return (
      <AwaitingModal
        show={true}
        onRequestHide={this.props.onClose}
        closeButton={true}
        title={this.props.t('calendars.weekly.removePickupModal.title')}
        footer={footer}
      >
        <VenloOnlyWarning />
        <div>
          {this.props.t('calendars.weekly.removePickupModal.confirmation', {
            date: this.props.startTime,
          })}
        </div>
        <RadioGroup
          onChange={setCurrent}
          defaultSelected={'current'}
          name="changeType"
        >
          <Radio
            label={this.props.t(
              'calendars.weekly.removePickupModal.forSingleDate',
              { date: this.props.startTime }
            )}
            value="current"
          />

          <Radio
            label={this.props.t(
              'calendars.weekly.removePickupModal.forWeekday',
              { date: this.props.startTime }
            )}
            value="futureDayOfWeek"
          />
          <Radio
            label={this.props.t(
              'calendars.weekly.removePickupModal.forAllFuture',
              { date: this.props.startTime }
            )}
            value="allFutureDays"
          />
        </RadioGroup>
      </AwaitingModal>
    )
  }

  private getPickupRemovedSnackbarMessage(): string {
    const date = this.props.startTime
    const calendarName = this.props.calendar.name

    switch (this.state.changeType) {
      case 'current':
        return this.props.t(
          'calendars.weekly.removePickupModal.pickupRemovedSingle',
          {
            date,
            calendarName,
          }
        )

      case 'futureDayOfWeek':
        return this.props.t(
          'calendars.weekly.removePickupModal.pickupRemovedWeekday',
          {
            date,
            calendarName,
          }
        )

      case 'allFutureDays':
        return this.props.t(
          'calendars.weekly.removePickupModal.pickupRemovedEveryday',
          {
            date,
            calendarName,
          }
        )

      default:
        throw new Error(`Unhandled switch case '${this.state.changeType}'`)
    }
  }

  private setChangeType(changeType: ChangeType) {
    this.setState({ changeType })
  }
}

export default withTranslation()(RemovePickupModal)

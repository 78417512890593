import { Tooltip } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import { useLogisticsLocation } from '../../../../locations/LocationContext'
import { getCellClasses } from '../../cell-class'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import styles from './HeaderCell.module.css'

interface Props {
  date: moment.Moment
  dayFormat: string
}

export function HeaderCell(props: Props) {
  const context = useWeeklyCalendarContext()
  const { logisticsLocation } = useLogisticsLocation()

  const year = props.date.year()
  const month = props.date.month() + 1
  const date = props.date.date()

  const dayInformation = context.multiyearInformation[year][month][date]
  const className = getCellClasses(
    moment(),
    year,
    month,
    styles.title,
    logisticsLocation.editable,
    {
      dayInformation,
      day: date,
    }
  )

  if (
    dayInformation.exceptionInformation &&
    dayInformation.exceptionInformation.reason
  ) {
    return (
      <div className={styles.cell}>
        <Tooltip contents={dayInformation.exceptionInformation.reason}>
          <div className={className}>
            <div className={styles.dayName}>{props.date.format('dd')}</div>
            <div className={styles.dayDate}>{props.date.format('D')}</div>
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={styles.cell}>
      <div className={className}>
        <div className={styles.dayName}>{props.date.format('dd')}</div>
        <div className={styles.dayDate}>{props.date.format('D')}</div>
      </div>
    </div>
  )
}

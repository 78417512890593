import moment from 'moment-timezone'
import { Moment } from 'moment-timezone/moment-timezone'
import * as React from 'react'
import DatePicker from '../../../../common/components/DatePicker'
import { PickupCalendarDaysWithLegend, PickupEventProps } from '../../../models'
import { PlantCalendarsLegend } from '../../legend/CalendarLegend'
import NavigationBar from '../../NavigationBar'
import { SelectCalendar } from '../../SelectCalendar'
import { AddPickupDialogButton } from '../AddPickupDialogButton'
import { PickupCalendarsLegend } from '../legend/PickupCalendarsLegend'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import styles from './CalendarView.module.css'
import WeeklyCalendar from './WeeklyCalendar'

export default function CalendarView() {
  const context = useWeeklyCalendarContext()
  const firstDay = context.currentDate.startOf('isoWeek')
  const nextViewFirstDay = firstDay.clone().add(7, 'days')
  const pickupEvents = context.pickupCalendarDaysWithLegend
    .filter(calendarAndDays => calendarAndDays.pickupCalendarDisplay.active)
    .map(cd => generateEvents(cd))
    .reduce((agg, curr) => agg.concat(curr), [])
    .filter(pe => pe.start.isBetween(firstDay, nextViewFirstDay, 'day', '[)'))

  const decrementWeek = () => context.changeWeek(-1)
  const incrementWeek = () => context.changeWeek(1)
  const changeToYear = () => context.changeToYearly()
  const setToCurrentWeek = () =>
    context.changeWeek(
      moment().startOf('isoWeek').diff(context.currentDate, 'week')
    )
  const goToSelectedWeek = (selected: Moment) =>
    context.changeWeek(
      selected.startOf('isoWeek').diff(context.currentDate, 'week')
    )

  return (
    <>
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 calendar-legend padding-bottom-full">
        <div className={`card ${styles.weekCalendarGroup}`}>
          <div className="card-block plant-calendar-legend">
            <SelectCalendar />
            <PlantCalendarsLegend />
            <PickupCalendarsLegend />
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-8 col-md-8 col-lg-9 flex padding-bottom-full">
        <div
          className={`card ${styles.weekCalendarGroup}`}
          style={{ width: '100%' }}
        >
          <div className="card-block flex-vertical" style={{ height: '100%' }}>
            <NavigationBar
              goToToday={setToCurrentWeek}
              toggleYear={changeToYear}
              prevButton={decrementWeek}
              nextButton={incrementWeek}
            >
              <DatePicker
                currentDate={context.currentDate}
                onDateChange={goToSelectedWeek}
              >
                {context.currentDate.format('MMMM Y [- Week] w')}
              </DatePicker>
              <div
                style={{
                  float: 'right',
                  marginRight: '32px',
                  marginTop: '-6px',
                }}
              >
                <AddPickupDialogButton />
              </div>
            </NavigationBar>
            <WeeklyCalendar
              intervalLength={60}
              firstDay={context.currentDate.startOf('isoWeek')}
              pickupEvents={pickupEvents}
              numberOfDays={7}
              dayFormat="dd., DD.MM"
              startTime={moment({ h: 0, m: 0 })}
              endTime={moment({ h: 23, m: 59 })}
              tagsEnabled={context.tagsEnabled}
            />
            <small className={styles.timezoneInfo}>
              {`(GMT${moment
                .tz(context.timezone)
                .format('Z')}) ${context.timezone.replace(
                new RegExp('_', 'g'),
                ' '
              )} `}
            </small>
          </div>
        </div>
      </div>
    </>
  )
}

function generateEvents(
  calendarAndDays: PickupCalendarDaysWithLegend
): PickupEventProps[] {
  return calendarAndDays.days.map(d => {
    return {
      start: moment(d.time),
      end: moment(d.time).add(1, 'hour'),
      calendarDisplay: calendarAndDays.pickupCalendarDisplay,
      active: calendarAndDays.pickupCalendarDisplay.active,
      color: calendarAndDays.pickupCalendarDisplay.color,
    }
  })
}

import moment from 'moment-timezone'
import * as React from 'react'
import { HeaderCell } from './HeaderCell'
import styles from './HeaderCell.module.css'

interface Props {
  firstDay: moment.Moment
  numberOfDays: number
  dayFormat: string
}

export default function HeaderRow(props: Props) {
  const weekdayColumns = [<div key="scale" className={styles.cell} />]
  for (let i = 0; i < props.numberOfDays; i++) {
    const date = moment(props.firstDay).add(i, 'd')
    const newCell = (
      <HeaderCell key={i} date={date} dayFormat={props.dayFormat} />
    )
    weekdayColumns.push(newCell)
  }

  return <>{weekdayColumns}</>
}

import Icon from '@cimpress-technology/react-streamline-icons/lib/IconBin'
import { Tooltip } from '@cimpress/react-components'
import * as React from 'react'
import auth from '../../common/auth'
import AnchorButton from '../../common/components/AnchorButton'
import {
  CanonicalCoamGroupMember,
  CanonicalCoamUser,
  roleLabelStyles,
} from '../models'
import styles from './permissions.module.css'
import { RoleDescription } from './RoleDescription'

interface Props {
  users: CanonicalCoamGroupMember[]
  editable: boolean
  onDelete(user: CanonicalCoamUser): void
  onEdit(user: CanonicalCoamUser): void
}

export default function UserList(props: Props) {
  const users = props.users.sort((a, b) => {
    return getName(a).localeCompare(getName(b))
  })

  return (
    <table className={'table table-hover ' + styles.userTable}>
      <tbody>
        {users.map(user => (
          <UserRow
            user={user}
            key={user.principal}
            editable={props.editable && !isCurrentUser(user)}
            onDelete={props.onDelete}
            onEdit={props.onEdit}
          />
        ))}
      </tbody>
    </table>
  )
}

function getName(user: CanonicalCoamUser) {
  if (user.profiles.length === 0) {
    return user.principal || user.canonical_principal
  }

  return (
    user.profiles[0].name || user.profiles[0].email || user.profiles[0].user_id
  )
}

interface UserRowProps {
  user: CanonicalCoamGroupMember
  editable: boolean
  onDelete(user: CanonicalCoamUser): void
  onEdit(user: CanonicalCoamUser): void
}

function UserRow(props: UserRowProps) {
  const onDelete = () => props.onDelete(props.user)
  const onEdit = () => props.onEdit(props.user)
  const deleteButton = (
    <AnchorButton onClick={onDelete} className={styles.deleteButton}>
      <Icon size="lg" />
    </AnchorButton>
  )

  return (
    <tr
      className={`${styles.userRow} ${props.editable ? styles.clickable : ''}`}
    >
      <td
        className={styles.userProfile}
        onClick={props.editable ? onEdit : undefined}
      >
        <UserProfile user={props.user} clickable={props.editable} />
      </td>
      <td
        style={{ width: '100%' }}
        onClick={props.editable ? onEdit : undefined}
      >
        {props.user.roles.map(role => (
          <RoleBadge role={role} key={role} />
        ))}
      </td>
      <td>{props.editable ? deleteButton : null}</td>
    </tr>
  )
}

const RoleBadge = (props: { role: string }) => {
  return (
    <Tooltip direction="top" contents={<RoleDescription role={props.role} />}>
      <span
        className={`badge badge-${roleLabelStyles.get(props.role)}`}
        key={props.role}
      >
        {props.role}
      </span>
    </Tooltip>
  )
}

interface UserProfileProps {
  user: CanonicalCoamUser
  clickable?: boolean
}

export function UserProfile(props: UserProfileProps) {
  return props.user.is_pending ? PendingUser(props) : User(props)
}

function getImageSrc(user: CanonicalCoamUser) {
  const firstTwoLetter = getName(user).substring(0, 2)

  return user.profiles[0] && user.profiles[0].picture
    ? user.profiles[0].picture
    : `https://i1.wp.com/cdn.auth0.com/avatars/${firstTwoLetter}.png?ssl=1`
}

function User(props: UserProfileProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className={styles.userAvatar}>
        <img src={getImageSrc(props.user)} alt="" />
      </div>
      <div>
        <div>
          {!props.clickable ? (
            <AnchorButton style={{ padding: 0 }}>
              {getName(props.user)}
            </AnchorButton>
          ) : (
            getName(props.user)
          )}
        </div>
        {props.user.profiles.length ? (
          <div>
            <i>{props.user.profiles[0].email}</i>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

function PendingUser(props: UserProfileProps) {
  const principal = props.user.principal || props.user.canonical_principal

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className={`${styles.userAvatar} ${styles.pendingUser}`} />
      <div>
        <div>
          {props.clickable ? (
            <AnchorButton>{principal}</AnchorButton>
          ) : (
            principal
          )}
        </div>
      </div>
    </div>
  )
}

function isCurrentUser(coamUser: CanonicalCoamUser): boolean {
  return coamUser.profiles.some(
    profile => profile.user_id === auth.getProfile().sub
  )
}

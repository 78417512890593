import moment from 'moment-timezone'
import { CalendarDisplay } from '../../common/models'
import {
  DayInformation,
  MonthInformation,
  MultiyearInformation,
  YearInformation,
} from '../models'

export function generateMultiyearInformation(
  calendarDisplay: CalendarDisplay,
  years: number[]
): MultiyearInformation {
  return years.reduce((acc, year) => {
    acc[year] = generateYearInformation(calendarDisplay, year)

    return acc
  }, {})
}

export function generateYearInformation(
  calendarDisplay: CalendarDisplay,
  year: number
) {
  const result: YearInformation = {}
  for (let i = 1; i <= 12; i++) {
    result[i] = generateMonthInformation(calendarDisplay, year, i)
  }

  return result
}

export function generateMonthInformation(
  calendarDisplay: CalendarDisplay,
  year: number,
  month: number
) {
  const result: MonthInformation = {}
  const date = moment.utc([year, month - 1, 1])
  while (date.month() < month && date.year() === year) {
    const day = date.date()

    const formattedDate = date.format('YYYY-MM-DD')
    const currentDay = calendarDisplay.days[formattedDate]
    if (!currentDay) {
      date.add(1, 'day')
      continue
    }

    const holiday = calendarDisplay.holidays[formattedDate]
    const overwrite =
      calendarDisplay.workingDaysCalendar.overwrites[formattedDate]

    const dayInformation: DayInformation = {
      isWorkingDay: currentDay.isWorkingDay,
    }

    if (holiday && !overwrite) {
      dayInformation.exceptionInformation = {
        reason: holiday.description,
        tag: 'holiday',
      }
    } else if (currentDay.description) {
      dayInformation.exceptionInformation = { reason: currentDay.description }
    } else if (overwrite) {
      dayInformation.exceptionInformation = { reason: overwrite.description }
    }

    result[day] = dayInformation
    date.add(1, 'day')
  }

  return result
}

import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment-timezone'
import { CarrierSelection } from '../carrier-configuration/components/add-carrier-flow/carrierAccountData'
import {
  FieldType,
  ValidatedField,
  ValidationResult,
} from '../common/helpers/validation'

export interface DayException {
  reason?: string

  tag?: string
}

export interface DayInformation {
  isWorkingDay: boolean
  exceptionInformation?: DayException
}

export interface MonthInformation {
  [day: number]: DayInformation
}

export interface YearInformation {
  [month: number]: MonthInformation
}

export interface MultiyearInformation {
  [year: number]: YearInformation
}

export interface DayDate {
  year: number
  month: number
  day: number
}

export interface CalendarCarrierSelection {
  carriers: string[]
  carrierServices?: string[]
}

export interface EditCalendar {
  calendarName: string
  carrierSelection: CalendarCarrierSelection
}

export interface CreateTransitCalendar {
  calendarName: string
  carrierSelection: CarrierSelection

  weeklySchedule: calendars.models.WeekdayData<boolean>
}

export interface CreatePickupCalendar {
  calendarName: string
  carrierSelection: CarrierSelection

  weeklySchedule: calendars.models.PickupWeeklySchedule
}

export interface CreateDeliveryCalendar {
  calendarName: string

  weeklySchedule: calendars.models.WeekdayData<boolean>
}

export const nameValidationField: ValidatedField = {
  name: 'name',
  required: true,
  type: FieldType.Text,
  i18nKey: 'validation.name',
}

export const carrierValidationField: ValidatedField = {
  name: 'carrier',
  required: true,
  type: FieldType.Text,
  i18nKey: 'validation.carrier',
}

export const carrierServicesValidationField: ValidatedField = {
  name: 'carrierServices',
  required: true,
  type: FieldType.Text,
  i18nKey: 'validation.carrierServices',
}

export interface CalendarValidation {
  name: ValidationResult
  carrier: ValidationResult
  carrierServices: ValidationResult
}

export type CalendarType =
  | 'transit'
  | 'working-days'
  | 'country'
  | 'pickup'
  | 'delivery'
export const calendarTypes: CalendarType[] = [
  'transit',
  'working-days',
  'country',
  'pickup',
  'delivery',
]

export interface DayCell {
  day: number
  dayInformation: DayInformation
}

export interface EditorPickupSchedule {
  weeklySchedule: calendars.models.WeeklySchedule<boolean>
  time: string
  tags: string[]
}

export type ChangeType = 'current' | 'futureDayOfWeek' | 'allFutureDays'

export type AddPickupHandler = (
  calendar: PickupCalendar,
  changeType: ChangeType,
  startTime: moment.Moment,
  tags: string[]
) => Promise<void>

export type EditEventHandler = (
  startTime: moment.Moment,
  calendar: PickupCalendar,
  changeType: ChangeType,
  tags: string[],
  targetTime: string,
  updatedTags: string[]
) => Promise<void>

export type RemoveEventHandler = (
  startTime: moment.Moment,
  calendar: PickupCalendar,
  changeType: ChangeType,
  tags: string[]
) => Promise<void>

export interface PickupCalendarDisplay {
  calendar: calendars.models.PickupCalendar
  active: boolean
  color: string
  editable: boolean
}

export interface PickupCalendarDaysWithLegend {
  days: calendars.models.Pickup[]
  pickupCalendarDisplay: PickupCalendarDisplay
}

export interface Interval {
  start: moment.Moment
  end: moment.Moment
}

export interface PickupEventProps extends Interval {
  calendarDisplay: PickupCalendarDisplay
  color: string
  active: boolean
  style?: React.CSSProperties
  onClick?: () => void
}

export type Weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export interface PickupCalendar {
  id?: string
  name: string
  etag?: string
  weeklySchedules: calendars.models.PickupWeeklySchedule[]
  overwrites: calendars.models.PickupDays
  links?: any
  _links?: any
  timezone?: string
  workingDaysCalendar?: Reference
}

export interface Reference {
  id: string
  href?: string
}

export interface CountryCalendarCustomData {
  data: LastAccessedList
  etag?: string
}

export interface LastAccessedList {
  lastAccessed: string[]
}

export interface PickupCalendarsColors {
  colorMap: {
    [calendarId: string]: string
  }
}

export const COUNTRY_CALENDARS_CUSTOM_DATA_ID = 'country-calendars'
export const PICKUP_CALENDARS_CUSTOM_DATA_ID = 'pickup-calendars-colors'

import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import { v4 } from 'uuid'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as coam from '@cimpress-technology/coam-sapidus'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import * as models from '../common/models'
import * as locationsStore from '../common/proxy/locations-store'
import NetworkPermissionsEditor from '../permissions/components/NetworkPermissionsEditor'
import { useAppContext } from '../common/AppContext'
import { bearerToken } from '../common/auth'
import DevelopmentFragment from '../common/components/DevelopmentFragment'
import NetworkDetails from './components/NetworkDetails'
import NetworkCalendarsPage from './NetworkCalendarsPage'
import { Provider, NetworkContext } from './NetworkContext'
import NetworkPackingFragment from './components/NetworkPackingFragment'

export default function ViewNetworkPage() {
  const { containerType } = useAppContext()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [network, setNetwork] = React.useState<models.Network>()
  const [networkLocations, setNetworkLocations] = React.useState<
    locations.models.LocationWithLinks[]
  >([])
  const params = useParams<{ networkId: string }>()
  const match = useRouteMatch()

  const loadNetwork = async (networkId: string) => {
    setLoading(true)

    const fetchedNetwork = await locationsStore.getNetwork(networkId)

    if (!fetchedNetwork) {
      return
    }

    const allLocations = await locationsStore.getMultipleLocations(
      fetchedNetwork.apiNetwork.logisticsLocationIds
    )

    setNetworkLocations(allLocations)
    setNetwork(fetchedNetwork)
    setLoading(false)
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadNetwork(params.networkId)
    }
    fetchData()
  }, [params.networkId])

  if (loading) {
    return <Preloader />
  }

  if (!network) {
    return <FourOhFourPage />
  }

  const reloadNetwork = async () => {
    await loadNetwork(params.networkId)
  }

  const updateNetwork = async (
    change:
      | locations.models.LogisticsNetworkWithLinks
      | ((n: locations.models.LogisticsNetworkWithLinks) => void)
  ): Promise<void> => {
    await locationsStore.updateNetwork(network.apiNetwork, change)
    await reloadNetwork()
  }

  const linkResourceToNetwork = (
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => {
    return locations.linkResourceToNetworkGroup(
      bearerToken(),
      v4(),
      network.apiNetwork.id,
      resourceId,
      resourceType
    )
  }

  const context: NetworkContext = {
    network,
    updateNetwork,
    linkResourceToNetwork,
    locations: networkLocations,
  }

  return (
    <Provider value={context}>
      <main className={`App-content flex-vertical ${containerType}`}>
        <Switch>
          <Route path={`${match.path}/calendars`}>
            <BreadcrumbsItem to="/">
              {t('common.logisticsLocations')}
            </BreadcrumbsItem>
            <BreadcrumbsItem to={`/network/${network.apiNetwork.id}`}>
              {network.apiNetwork.name}
            </BreadcrumbsItem>
            <BreadcrumbsItem to="#">
              {t('common.calendars.calendars')}
            </BreadcrumbsItem>
            <NetworkCalendarsPage />
          </Route>
          <Route path={`${match.path}/user-management`}>
            <BreadcrumbsItem to="/">
              {t('common.logisticsLocations')}
            </BreadcrumbsItem>
            <NetworkPermissionsEditor />
          </Route>
          <Route path={`${match.url}/packing`}>
            <NetworkPackingFragment />
          </Route>
          <Route path={`${match.url}/fragments`}>
            <DevelopmentFragment />
          </Route>
          <Route path={`${match.path}`}>
            <NetworkDetails />
          </Route>
        </Switch>
      </main>
    </Provider>
  )
}

import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment-timezone'
import * as _ from 'underscore'
import { clone } from '../../common/helpers/clone'
import { DayDate } from '../models'

export function modifySchedules(
  schedules: Array<calendars.models.WeeklySchedule<any>>,
  date: DayDate,
  isWorkingDay: boolean
): Array<calendars.models.WeeklySchedule<any>> {
  const dateStr = formatDayDate(date)

  const grouped: {
    [_: string]: Array<calendars.models.WeeklySchedule<any>>
  } = _.groupBy(clone(schedules), groupByDate(dateStr))

  const past = grouped.past ? _.sortBy(grouped.past, 'validFrom') : []
  const current = grouped.current ? grouped.current[0] : undefined
  const future = grouped.future ? _.sortBy(grouped.future, 'validFrom') : []

  if (past.length === 0 && !current) {
    throw new Error('modifySchedule called on date before first schedule')
  }

  const newCurrent = current || clone(past[past.length - 1])
  newCurrent.schedule[toDayKey(date)] = isWorkingDay
  newCurrent.validFrom = dateStr

  future.forEach(s => (s.schedule[toDayKey(date)] = isWorkingDay))

  return removeDuplicates([...past, newCurrent, ...future])
}

function toMoment(dayDate: DayDate): moment.Moment {
  return moment([dayDate.year, dayDate.month - 1, dayDate.day])
}

function formatDayDate(dayDate: DayDate): string {
  return toMoment(dayDate).format('YYYY-MM-DD')
}

function toDayKey(dayDate: DayDate): string {
  return toMoment(dayDate).format('dddd').toLowerCase()
}

function groupByDate(
  validFrom: string
): (weeklySchedule: calendars.models.WeeklySchedule<any>) => string {
  return (weeklySchedule: calendars.models.WeeklySchedule<any>) => {
    if (weeklySchedule.validFrom < validFrom) {
      return 'past'
    }

    if (weeklySchedule.validFrom > validFrom) {
      return 'future'
    }

    return 'current'
  }
}

function removeDuplicates(
  inputSchedules: Array<calendars.models.WeeklySchedule<any>>
): Array<calendars.models.WeeklySchedule<any>> {
  return _.reduce(
    inputSchedules.slice(1),
    (
      ss: Array<calendars.models.WeeklySchedule<any>>,
      s: calendars.models.WeeklySchedule<any>
    ) => (_.isEqual(ss[ss.length - 1].schedule, s.schedule) ? ss : [...ss, s]),
    [inputSchedules[0]]
  )
}

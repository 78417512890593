import { Alert } from '@cimpress/react-components'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'

export default function ComplexSetupView(props: {
  carrierServices: string[]
  carrierName: string
}) {
  const { t } = useTranslation()

  return (
    <div>
      <Alert
        dismissible={false}
        message={
          <Trans i18nKey="calendars.weekly.addPickupCalendarModal.complexMapping">
            This calendar has custom setup, please contact{' '}
            <a href="mailto:logisticssupport@cimpress.com">
              logisticssupport@cimpress.com
            </a>{' '}
            to change carrier services
          </Trans>
        }
      />
      <h5>{t('carrierAccounts.addCarrierFlow.carrierAccountNameLabel')}</h5>
      <div>{props.carrierName}</div>
      <h5>
        {t('carrierAccounts.carrierServiceCount', {
          count: props.carrierServices.length,
        })}
      </h5>
      <ul>
        {props.carrierServices.map(s => (
          <li key={s}>{s}</li>
        ))}
      </ul>
    </div>
  )
}

export interface CanonicalCoamUser {
  principal: string
  canonical_principal: string
  is_client: boolean
  is_pending: boolean
  profiles: CoamUserProfile[]
}

export interface CanonicalCoamGroupMember extends CanonicalCoamUser {
  is_admin: boolean
  roles: string[]
}

export interface CoamUserProfile {
  user_id: string
  name: string
  email: string
  picture: string
}

// TODO take it from configuration client pkg
export const locationRoles = [
  'Logistics Manager',
  'Logistics Supervisor',
  'Logistics Operator',
]

// TODO take it from configuration client pkg
export const networkRoles = [
  'Logistics Network Admin',
  'Logistics Network Reader',
  'Logistics Network Operator',
]

export const roleLabelStyles: Map<string, string> = new Map([
  ['Logistics Manager', 'warning'],
  ['Logistics Supervisor', 'info'],
  ['Logistics Operator', 'success'],
  ['Logistics Network Admin', 'warning'],
  ['Logistics Network Reader', 'info'],
])

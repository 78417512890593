import IconArrowLeftAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowLeftAlt'
import IconArrowRightAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowRightAlt'
import { colors, Button, ButtonGroup } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import AnchorButton from '../../common/components/AnchorButton'
import { CalendarType } from '../models'
import style from './NavigationBar.module.css'

interface Props {
  hideButtons?: boolean
  prevButton?: () => void
  nextButton?: () => void
  goToToday?: () => void

  toggleYear?: (button: any) => void
  toggleWeek?: (button: any) => void
  children?: React.ReactNode
}
export default function NavigationBar(props: Props) {
  const { t } = useTranslation()
  const { calendarType } = useParams<{ calendarType: CalendarType }>()
  const showLinks = calendarType === 'working-days' || calendarType === 'pickup'

  return (
    <div className={style.navigationBar}>
      {props.hideButtons ? null : <NavigationButtons {...props} />}
      <div style={{ flexGrow: 1 }}>
        <div className={style.header}>{props.children}</div>
      </div>
      {showLinks && (
        <div>
          <ButtonGroup type="justified">
            <Button
              className={calendarType === 'working-days' ? ' active' : ''}
              size="sm"
              onClick={props.toggleYear}
            >
              {t('calendars.yearly.navigationBar.year')}
            </Button>
            <Button
              className={calendarType === 'pickup' ? ' active' : ''}
              size="sm"
              onClick={props.toggleWeek}
            >
              {t('calendars.yearly.navigationBar.week')}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  )
}

function NavigationButtons(props: Props) {
  const { t } = useTranslation()

  return (
    <>
      <Button size="sm" type="link" onClick={props.goToToday}>
        {t('calendars.yearly.navigationBar.today')}
      </Button>
      <AnchorButton style={{ padding: '5px' }} onClick={props.prevButton}>
        <IconArrowLeftAlt color={colors.ocean.base} />
      </AnchorButton>
      <AnchorButton style={{ padding: '5px' }} onClick={props.nextButton}>
        <IconArrowRightAlt color={colors.ocean.base} />
      </AnchorButton>
    </>
  )
}

import { Radio } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import ContextMenu from '../../../common/components/ContextMenu'
import ContextMenuItem from '../../../common/components/ContextMenuItem'
import styles from '../TransitCalendarView.module.css'
import { useLogisticsLocation } from '../../../locations/LocationContext'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'

interface Props {
  selectedCalendar: calendars.models.WorkingDaysCalendar | undefined
  deliveryCalendar: calendars.models.WorkingDaysCalendar
  editable: boolean
  onChange: (calendar: string) => void
  onEdit: (calendarToEdit: calendars.models.WorkingDaysCalendar) => void
  onDelete: (calendarToDelete: calendars.models.WorkingDaysCalendar) => void
}

export default function DeliveryCalendarLegendRow(props: Props) {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  const history = useHistory()

  const onChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    deliveryCalendarId: string
  ) => {
    props.onChange(deliveryCalendarId)
  }

  const onEdit = () => {
    props.onEdit(props.deliveryCalendar)
  }

  const onDelete = () => {
    props.onDelete(props.deliveryCalendar)
  }

  const goToHistory = () => {
    const url = logisticsLocation
      ? `/location/${logisticsLocation.id}/history?resourceId=${props.deliveryCalendar.id}`
      : `/audits/calendar/${props.deliveryCalendar.id}`
    history.push(url)
  }

  const checked = props.selectedCalendar
    ? props.selectedCalendar.id === props.deliveryCalendar.id
    : undefined

  const labelText = props.deliveryCalendar.name || ''

  const label = checked ? (
    <span style={{ fontWeight: 600 }}>{labelText}</span>
  ) : (
    labelText
  )

  return (
    <div key={props.deliveryCalendar.id} className={`flex ${styles.rowHeight}`}>
      <Radio
        onCheck={onChange}
        checked={
          props.selectedCalendar
            ? props.selectedCalendar.id === props.deliveryCalendar.id
            : undefined
        }
        value={props.deliveryCalendar.id}
        className={styles.transitCalendarRow}
        label={label}
      />
      <ContextMenu className={styles.transitCalendarRowContext}>
        <ContextMenuItem onClick={onEdit} label={t('calendars.showDetails')} />
        {props.editable && (
          <ContextMenuItem
            onClick={onEdit}
            label={t('calendars.editCalendar')}
          />
        )}
        {props.editable && (
          <AdvancedUserContainer>
            <ContextMenuItem onClick={onDelete} label={t('common.delete')} />
          </AdvancedUserContainer>
        )}
        <ContextMenuItem
          onClick={goToHistory}
          label={
            <>
              {t('common.history')}{' '}
              <span className="badge badge-info">beta</span>
            </>
          }
        />
      </ContextMenu>
      <div className={styles.transitCalendarRowScrollbar} />
    </div>
  )
}

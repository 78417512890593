// Shamelessly stolen from https://github.com/YouCanBookMe/react-datetime/issues/186#issuecomment-364416887

import * as React from 'react'
import ReactDateTime from 'react-datetime'
import TetherComponent from 'react-tether'
import styles from './TetheredDateTime.module.css'
import { WrappedCalendarContainer } from './WrappedCalendarContainer'

export class TetheredDateTime extends ReactDateTime {
  // The definition for DateTime doesn't include parts of it's private implementation,
  // so as a hack we can ignore the typings all together for those parts.
  // e.g. this.openCalendar will become this.anyThis.openCalendar
  private anyThis: any = this

  public render() {
    let className = 'rdt'

    if (this.props.className) {
      className +=
        ' ' +
        (Array.isArray(this.props.className)
          ? this.props.className.join(' ')
          : this.props.className)
    }

    if (!this.props.input) {
      className += ' rdtStatic'
    }

    return (
      <div className={className}>
        <TetherComponent
          attachment="top left"
          targetAttachment="bottom left"
          constraints={[
            { to: 'scrollParent' },
            { to: 'window', pin: ['bottom'] },
          ]}
          className={styles.tetheredDateTime}
          renderTarget={(ref: any) =>
            this.props.input && (
              <input
                ref={ref}
                key="i"
                type="text"
                className="form-control"
                onFocus={this.anyThis.openCalendar}
                onChange={this.anyThis.onInputChange}
                onKeyDown={this.anyThis.onInputKey}
                value={this.state.inputValue}
                {...this.props.inputProps}
              />
            )
          }
          renderElement={(ref: any) =>
            this.state.open && (
              <div ref={ref} className="rdtPicker">
                <WrappedCalendarContainer
                  view={this.anyThis.state.currentView}
                  viewProps={this.anyThis.getComponentProps()}
                  onClickOutside={this.anyThis.handleClickOutside}
                />
              </div>
            )
          }
        />
      </div>
    )
  }
}

import { TFunction } from 'i18next'
import { Disabled, Option } from '../../common/models'
import {
  EnhancedCaasProfile,
  CarrierService,
} from '../../common/models-carriers'

export function getCarrierServicesOptions(
  t: TFunction,
  carrierServices: string[],
  filteredCaasProfiles: EnhancedCaasProfile[],
  carrierServicesToCalendarNames: { [key: string]: string },
  carrierKeys: string[],
  currentCalendarName?: string
): Array<Option & Disabled> {
  const selectedCarrierServices = new Set(carrierServices)
  const carrierData = filteredCaasProfiles.filter(carrier =>
    carrierKeys.includes(carrier.key)
  )
  if (!carrierData) {
    return []
  }

  const allServices = carrierData.flatMap(data => data.carrierServices)
  const withoutDuplicates = allServices.reduce((acc, curr) => {
    if (!acc.find(service => service.key === curr.key)) {
      acc.push(curr)
    }

    return acc
  }, [] as CarrierService[])

  return withoutDuplicates
    .map(service => {
      const calendarName = carrierServicesToCalendarNames[service.key]
      const label =
        calendarName &&
        calendarName !== currentCalendarName &&
        !selectedCarrierServices.has(service.key)
          ? t('calendars.weekly.addPickupCalendarModal.alreadyUsedIn', {
              serviceName: service.name,
              calendarName,
            })
          : `${service.name} (${service.key})`

      return {
        value: service.key,
        label,
        isDisabled: Boolean(
          calendarName && calendarName !== currentCalendarName
        ),
      }
    })
    .sort((a, b) => {
      if (a.isDisabled === b.isDisabled) {
        return a.label.localeCompare(b.label)
      }

      return Number(a.isDisabled) - Number(b.isDisabled)
    })
}

export function mapTag(tag: string): string {
  if (tag === declaredToUsCustoms.value) {
    return declaredToUsCustoms.label
  }

  return tag
}

export const declaredToUsCustoms = {
  value: 'recs:cap:declared-to-us-customs',
  label: 'Declared to US Customs',
}

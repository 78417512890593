import { TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../common/components/ValidationContainer'
import {
  toBSStyle,
  ValidationStatus,
  validateField,
} from '../../common/helpers/validation'
import { nameValidationField } from '../models'
import AwaitingModal from './AwaitingModal'

export interface Props {
  title: string
  children?: React.ReactNode
  calendarName?: string
  editable: boolean

  onSave: (calendarName: string) => Promise<void>
  onClose: () => void
}

export default function SimpleCalendarModal(props: Props) {
  const { t } = useTranslation()

  const [calendarName, setCalendarName] = React.useState(props.calendarName)
  const [validation, setValidation] = React.useState(
    props.calendarName
      ? validateField(nameValidationField, calendarName)
      : {
          fieldName: 'name',
          status: ValidationStatus.NotValidated,
        }
  )

  const onSave = async () => {
    if (calendarName) {
      await props.onSave(calendarName)

      return props.onClose()
    }

    setValidation(validateField(nameValidationField, calendarName))
  }

  const onCalendarNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCalendarName(e.currentTarget.value)
    setValidation(validateField(nameValidationField, e.currentTarget.value))
  }

  const footer = props.editable
    ? [
        {
          handler: props.onClose,
          text: t('common.cancel'),
        },
        {
          type: 'primary',
          handler: onSave,
          savingText: t('common.saving'),
          text: t('common.saveChanges'),
          disabled: validation.status === ValidationStatus.Invalid,
        },
      ]
    : [
        {
          type: 'primary',
          handler: props.onClose,
          text: t('common.close'),
        },
      ]

  return (
    <AwaitingModal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      title={props.title}
      style={{ width: '526px' }}
      footer={footer}
    >
      <ValidationContainer validationResult={validation}>
        <TextField
          name="name"
          label={t('calendars.weekly.addPickupCalendarModal.scheduleName')}
          value={calendarName}
          onChange={onCalendarNameChange}
          autoFocus={true}
          required={true}
          bsStyle={toBSStyle(validation)}
          maxLength={100}
          disabled={!props.editable}
        />
      </ValidationContainer>
      {props.children}
    </AwaitingModal>
  )
}

import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment-timezone'
import * as _ from 'underscore'
import { Weekday } from '../../models'

export function findCurrentSchedule(
  schedules: Array<calendars.models.WeeklySchedule<calendars.models.Pickup[]>>,
  date: moment.Moment
): calendars.models.WeeklySchedule<calendars.models.Pickup[]> | undefined {
  const dateString = date.format('YYYY-MM-DD')
  const previousSchedules = _.sortBy(schedules, s => s.validFrom).filter(
    s => s.validFrom <= dateString
  )
  const lastSchedule = _.last(previousSchedules)

  return lastSchedule
}

export function getLowercaseWeekday(day: moment.Moment): Weekday {
  return day.format('dddd').toLowerCase() as Weekday
}

import moment from 'moment-timezone'
import {
  Interval,
  PickupCalendarDisplay,
  PickupEventProps,
} from '../../../models'

const dateFormat = 'YYYY-MM-DD'

export function groupPickups(
  pickups: PickupEventProps[],
  startDate: string,
  endDate: string
): Map<string, Map<string, PickupEventProps[]>> {
  const result = pickups
    .filter(p => {
      const date = p.start.format(dateFormat)

      return date >= startDate && date <= endDate
    })
    .reduce(
      (acc, curr) => {
        const date = curr.start.format(dateFormat)
        const time = curr.start.format('HH:mm')
        acc.times.add(time)
        let m1 = acc.map.get(date)
        if (m1 === undefined) {
          m1 = new Map<string, PickupEventProps[]>()
          acc.map.set(date, m1)
        }
        let events = m1.get(time)
        if (events === undefined) {
          events = []
          m1.set(time, events)
        }
        events.push(curr)
        events.sort((e1, e2) =>
          e1.calendarDisplay.calendar.name.localeCompare(
            e2.calendarDisplay.calendar.name
          )
        )

        return acc
      },
      {
        map: new Map<string, Map<string, PickupEventProps[]>>(),
        times: new Set<string>(),
      }
    )

  for (const time of Array.from(result.times.keys())) {
    const calendars = getAllCalendarsForTime(result.map, time)
    for (const date of generateDatesInRange(startDate, endDate)) {
      let timeMap = result.map.get(date)
      if (timeMap === undefined) {
        timeMap = new Map<string, PickupEventProps[]>()
        result.map.set(date, timeMap)
      }
      const pickupEvents = timeMap.get(time) || []
      const start = moment(`${date} ${time}`)
      const events = addEmptyEvents(
        calendars,
        pickupEvents,
        start,
        start.clone().add(30, 'minutes')
      )
      timeMap.set(time, events)
    }
  }

  return result.map
}

function getAllCalendarsForTime(
  dateTimeEventsMap: Map<string, Map<string, PickupEventProps[]>>,
  time: string
) {
  const allEvents = Array.from(dateTimeEventsMap.keys())
    .map(date => {
      const dayMap = dateTimeEventsMap.get(date)
      if (dayMap === undefined) {
        return []
      }

      return dayMap.get(time) || []
    })
    .reduce((acc, curr) => acc.concat(curr), [])
  const uniqueCalendars = allEvents.reduce((acc, curr) => {
    if (!acc.some(x => x.calendar.id === curr.calendarDisplay.calendar.id)) {
      acc.push(curr.calendarDisplay)
      acc.sort((c1, c2) => c1.calendar.name.localeCompare(c2.calendar.name))
    }

    return acc
  }, new Array<PickupCalendarDisplay>())

  return uniqueCalendars
}

function addEmptyEvents(
  sortedCalendars: PickupCalendarDisplay[],
  dayEvents: PickupEventProps[],
  start: moment.Moment,
  end: moment.Moment
) {
  const result = new Array<PickupEventProps>()
  dayEvents.sort((e1, e2) =>
    e1.calendarDisplay.calendar.name.localeCompare(
      e2.calendarDisplay.calendar.name
    )
  )
  for (let cal = 0, ev = 0; cal < sortedCalendars.length; ) {
    if (
      ev < dayEvents.length &&
      sortedCalendars[cal].calendar.name ===
        dayEvents[ev].calendarDisplay.calendar.name
    ) {
      result.push(dayEvents[ev])
      cal++
      ev++
    } else {
      result.push({
        start,
        end,
        calendarDisplay: sortedCalendars[cal],
        active: false,
        color: 'white',
      })
      cal++
    }
  }

  return result
}

export function generateDatesInRange(startDate: string, endDate: string) {
  const result = []
  for (
    let date = startDate;
    date <= endDate;
    date = moment(date).add(1, 'day').format(dateFormat)
  ) {
    result.push(date)
  }

  return result
}
export function getIntervalsByDuration(
  duration: number,
  startTime: moment.Moment,
  endTime: moment.Moment
) {
  const startIndex = getNumberOfCells(startTime, duration, false)
  const endIndex = getNumberOfCells(endTime, duration, true)
  let start = moment({ h: 0, m: 0 }).add(duration * startIndex, 'm')
  let end
  const result: Interval[] = []

  for (let i = startIndex; i < endIndex; i++) {
    end = start.clone().add(duration, 'm')
    const interval = {
      start,
      end,
    }
    result.push(interval)
    start = end
  }
  let lastElement = result.pop()!
  if (lastElement.end.format('HH:mm') === '00:00') {
    lastElement = {
      start: lastElement.start,
      end: moment({ hour: 23, minute: 59 }),
    }
  }
  result.push(lastElement)

  return result
}

export function getNumberOfCells(
  time: moment.Moment,
  duration: number,
  isUpRound: boolean,
  offset = 0
) {
  const midnight = moment(time).startOf('day').add(offset, 'm')
  const testTime = moment(time)
  if (testTime.format('HH:mm') === '23:59') {
    testTime.add(1, 'm')
  }
  const result = isUpRound
    ? Math.ceil(testTime.diff(midnight, 'm') / duration)
    : Math.floor(testTime.diff(midnight, 'm') / duration)
  if (result < 0) {
    return 0
  }

  return result
}

import * as React from 'react'

export function useShowElement(): [boolean, () => void, () => void] {
  const [showElement, setShowElement] = React.useState(false)

  const open = () => setShowElement(true)
  const close = () => setShowElement(false)

  return [showElement, open, close]
}

import moment from 'moment-timezone'
import * as React from 'react'
import * as uuid from 'uuid'
import { DayInformation } from '../../../models'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import AddPickupDialogTooltip from './AddPickupDialogTooltip'
import FutureNonWorkingDayTooltip from './FutureNonWorkingDayDialogTooltip'
import styles from './WeeklyCalendar.module.css'

export interface Props {
  startTime: moment.Moment
  dayInformation: DayInformation
  children?: React.ReactNode
  isLastRow: boolean
  allEventsStartLater: boolean
  onClickValidClass: string
}

export const DayCell = (props: Props) => {
  const context = useWeeklyCalendarContext()

  const year = props.startTime.year()
  const month = props.startTime.month() + 1
  const date = props.startTime.date()
  const dayInformation = context.multiyearInformation[year][month][date]

  const showAddPickupTooltip =
    context.pickupCalendarDaysWithLegend.length > 0 &&
    context.modalData.modalType === 'addPickup' &&
    context.modalData.selectedStartTime &&
    props.startTime.isSame(context.modalData.selectedStartTime)

  const showFutureNonWorkingDayTooltip =
    context.modalData.modalType === 'futureNonWorkingDay' &&
    context.modalData.selectedStartTime &&
    props.startTime.isSame(context.modalData.selectedStartTime)

  const now = moment()
  const startTimeAdjusted = moment(props.startTime).tz(context.timezone, true)
  const classes = getTimeRelatedClasses(now, startTimeAdjusted, dayInformation)
  if (props.isLastRow) {
    classes.push(styles.lastRow)
  }
  if (props.allEventsStartLater) {
    classes.push(styles.lateStart)
  }
  if (showAddPickupTooltip) {
    classes.push(styles.noEmptyBody)
  }

  const cellClasses = classes.join(' ')

  const id = uuid.v4()
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = e.target as HTMLDivElement
    if (
      element.id !== id &&
      !element.classList.contains(props.onClickValidClass)
    ) {
      return
    }

    if (
      !context.pickupCalendarDaysWithLegend.some(
        pc => pc.pickupCalendarDisplay.editable
      )
    ) {
      return
    }

    if (now.isAfter(startTimeAdjusted, 'h')) {
      return
    }

    if (dayInformation.isWorkingDay) {
      context.setModal(
        showAddPickupTooltip ? undefined : props.startTime,
        'addPickup'
      )
    } else {
      context.setModal(props.startTime, 'futureNonWorkingDay')
    }
  }

  const content = (
    <div id={id} className={cellClasses} onClick={onClick}>
      {props.children}
    </div>
  )

  if (showAddPickupTooltip) {
    return (
      <AddPickupDialogTooltip
        startTime={props.startTime}
        onClose={context.setModal}
        tagsEnabled={context.tagsEnabled}
      >
        {content}
      </AddPickupDialogTooltip>
    )
  }
  if (showFutureNonWorkingDayTooltip) {
    return (
      <FutureNonWorkingDayTooltip onClose={context.setModal}>
        {content}
      </FutureNonWorkingDayTooltip>
    )
  }

  return content
}

function getTimeRelatedClasses(
  now: moment.Moment,
  startTime: moment.Moment,
  dayInformation: DayInformation
): string[] {
  const classes: string[] = [styles.dayCell]

  if (startTime.isBefore(now, 'hour')) {
    classes.push(styles.pastCell)
  }

  if (dayInformation && !dayInformation.isWorkingDay) {
    classes.push(styles.rdtDisabled)
  }

  return classes
}

import { TextField } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../common/components/ValidationContainer'
import {
  toBSStyle,
  validateField,
  validateList,
  ValidationStatus,
} from '../../common/helpers/validation'
import { EnhancedCaasProfile } from '../../common/models-carriers'
import {
  carrierServicesValidationField,
  carrierValidationField,
  CalendarValidation,
  CalendarCarrierSelection,
  EditCalendar,
  nameValidationField,
} from '../models'
import { filterAvailableCaasProfiles } from '../../common/proxy/carrier-services-store'
import AwaitingModal from './AwaitingModal'
import { getCarrierServicesOptions } from './carrier-services-options'
import CarrierAndServicesSelector from './CarrierAndServicesSelector'

interface Props {
  title: string
  primaryButtonText: string
  primaryButtonSavingText: string
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierAccountTypes: EnhancedCaasProfile[]

  children?: React.ReactNode
  onSave: (data: EditCalendar) => Promise<void>
  onClose: () => void
}

export default function CalendarCreateModal(props: Props) {
  const { t } = useTranslation()
  const [calendarName, setCalendarName] = React.useState('')

  const [carrierSelection, setCarrierSelection] = React.useState<
    CalendarCarrierSelection
  >({ carriers: [], carrierServices: [] })

  const [validation, setValidation] = React.useState<CalendarValidation>(
    getInitialCreateCalendarValidationStatus()
  )
  const onSave = async () => {
    const newValidationState = {
      name: validateField(nameValidationField, calendarName),
      carrier: validateField(carrierValidationField, carrierSelection.carriers),
      carrierServices: validateList(
        carrierServicesValidationField,
        carrierSelection.carrierServices
      ),
    }

    setValidation(newValidationState)

    const isCalendarValid = Object.values(newValidationState).every(
      field => field.status === ValidationStatus.Valid
    )

    if (isCalendarValid) {
      await props.onSave({ calendarName, carrierSelection })

      props.onClose()
    }
  }

  const footer = [
    {
      handler: props.onClose,
      text: t('common.cancel'),
    },
    {
      type: 'primary',
      handler: onSave,
      savingText: props.primaryButtonSavingText,
      text: props.primaryButtonText,
    },
  ]

  const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCalendarName(e.currentTarget.value)
    setValidation({
      ...validation,
      name: validateField(nameValidationField, e.currentTarget.value),
    })
  }

  const onCarrierServicesChange = (carrierServices: string[]) => {
    setCarrierSelection({ ...carrierSelection, carrierServices })

    setValidation({
      ...validation,
      carrierServices: validateList(
        carrierServicesValidationField,
        carrierServices
      ),
    })
  }

  const onCarrierChange = (carrier?: string) => {
    const filteredCaasProfiles = filterAvailableCaasProfiles(
      props.carrierAccountTypes
    )

    const carrierServicesOptions = carrier
      ? getCarrierServicesOptions(
          t,
          carrierSelection.carrierServices ?? [],
          filteredCaasProfiles,
          props.carrierServicesToCalendarNames,
          [carrier]
        )
      : []
    const preselectedCarrierServices =
      carrierServicesOptions.length === 1
        ? carrierServicesOptions
            .filter(o => !o.isDisabled)
            .map(cso => cso.value as string)
        : []

    const newCarrierAccountData = {
      carriers: carrier ? [carrier] : [],
      carrierServices:
        carrierSelection.carriers.length === 1 &&
        carrierSelection.carriers[0] === carrier
          ? carrierSelection.carrierServices
          : preselectedCarrierServices,
    }
    setCarrierSelection(newCarrierAccountData)
    setValidation({
      ...validation,
      carrier: validateField(carrierValidationField, carrier),
    })
  }

  return (
    <AwaitingModal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      title={props.title}
      style={{ width: '526px' }}
      footer={footer}
    >
      <ValidationContainer validationResult={validation.name}>
        <TextField
          name="name"
          label={t('calendars.weekly.addPickupCalendarModal.scheduleName')}
          value={calendarName}
          onChange={onNameChange}
          autoFocus={true}
          required={true}
          bsStyle={toBSStyle(validation.name)}
          maxLength={100}
        />
      </ValidationContainer>
      {props.children}
      <CarrierAndServicesSelector
        isDisabled={false}
        carrierSelection={carrierSelection}
        carrierAccountTypes={props.carrierAccountTypes}
        carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
        onCarrierChange={onCarrierChange}
        onCarrierServicesChange={onCarrierServicesChange}
        validation={validation}
      />
    </AwaitingModal>
  )
}

export function getInitialCreateCalendarValidationStatus(): CalendarValidation {
  return {
    name: {
      fieldName: 'name',
      status: ValidationStatus.NotValidated,
    },
    carrier: {
      fieldName: 'carrier',
      status: ValidationStatus.NotValidated,
    },
    carrierServices: {
      fieldName: 'carrierServices',
      status: ValidationStatus.NotValidated,
    },
  }
}

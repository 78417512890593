import { calendars } from '@cimpress-technology/logistics-configuration-client'
import {
  Button,
  colors,
  Radio,
  RadioGroup,
  Select,
  shapes,
} from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SnackbarController } from '../../../common/components/SnackbarController'
import { TetheredDateTime } from '../../../common/components/tethered-date-time/TetheredDateTime'
import { logError } from '../../../common/logger'
import { Option, reactSelectPortalId } from '../../../common/models'
import { useLogisticsLocation } from '../../../locations/LocationContext'
import { ChangeType } from '../../models'
import './AddPickupDialog.css'
import { DuplicatedPickupError } from './pickup-calendar-updater'
import { PickupTimeEditor } from './PickupTimeEditor'
import { VenloOnlyWarning } from './VenloOnlyWarning'
import { TagEditor } from './weekly-calendar/TagEditor'
import useWeeklyCalendarContext from './WeeklyCalendarContext'

interface Props {
  startTime?: moment.Moment
  tagsEnabled: boolean
  selectedPickupCalendar: calendars.models.PickupCalendar
  onClose: () => void
  onSelectPickupCalendar?: (
    pickupCalendar: calendars.models.PickupCalendar
  ) => void
}

export default function AddPickupDialog(props: Props) {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()
  const { logisticsLocation } = useLogisticsLocation()
  const [loading, setLoading] = React.useState(false)
  const [tags, setTags] = React.useState<string[]>([])

  const [startTime, setStartTime] = React.useState<moment.Moment | string>(
    props.startTime ||
      moment().set('hours', 9).set('minutes', 0).set('seconds', 0)
  )
  const [changeType, setChangeType] = React.useState<ChangeType>('current')

  const renderSelectStartTime = () => {
    const dateChange = (date: moment.Moment | string) => {
      if (isTimeValid(date) && isTimeValid(startTime)) {
        date
          .set('hours', startTime.get('hours'))
          .set('minutes', startTime.get('minutes'))
      }
      setStartTime(date)
    }

    const timeChange = (time: string) => {
      const hours = parseInt(time.split(':')[0], 10)
      const minutes = parseInt(time.split(':')[1], 10)
      setStartTime(
        moment(startTime).set('hours', hours).set('minutes', minutes)
      )
    }

    const pickupTime = isTimeValid(startTime) ? startTime.format('HH:mm') : ''

    const isValidDate = (currentDate: moment.Moment) =>
      currentDate.isAfter(moment().subtract(1, 'day'))

    return (
      <>
        <div style={{ marginBottom: '5px' }}>
          <strong>
            {t('calendars.weekly.addPickupDialog.selectDateTime')}
          </strong>
        </div>
        <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
          <TetheredDateTime
            value={startTime}
            onChange={dateChange}
            isValidDate={isValidDate}
            dateFormat="LL"
            timeFormat={false}
            closeOnSelect={true}
          />
          <span style={{ margin: '16px' }}>{t('at')}</span>
          <div>
            <PickupTimeEditor
              value={pickupTime}
              onChange={timeChange}
              disabled={!isTimeValid(startTime)}
            />
          </div>
        </div>
      </>
    )
  }

  const pickupCalendarDisplays = context.pickupCalendarDaysWithLegend
    .map(pcl => pcl.pickupCalendarDisplay)
    .filter(pcd => pcd.editable)

  const handleSelectPickupCalendar = (
    pickupCalendar: calendars.models.PickupCalendar
  ) => {
    if (props.onSelectPickupCalendar) {
      props.onSelectPickupCalendar(pickupCalendar)
    }
  }
  const renderSelectCalendar = () => {
    const selectCalendar = (data: Option) => {
      const pickupCalendar = pickupCalendarDisplays.find(
        c => c.calendar.id === data.value
      )!.calendar
      handleSelectPickupCalendar(pickupCalendar)
      if (props.onSelectPickupCalendar) {
        props.onSelectPickupCalendar(pickupCalendar)
      }
    }

    const calendarOptions = pickupCalendarDisplays.map(c => ({
      label: c.calendar.name,
      value: c.calendar.id!,
    }))

    return (
      <div className="calendar-select" style={{ marginBottom: '12px' }}>
        <div style={{ marginBottom: '8px' }}>
          <strong>
            {t('calendars.weekly.addPickupDialog.selectCalendar')}
          </strong>
        </div>
        <Select
          isSearchable={true}
          isClearable={false}
          name={'calendar'}
          value={
            props.selectedPickupCalendar &&
            calendarOptions.filter(
              o => o.value === props.selectedPickupCalendar.id
            )
          }
          options={calendarOptions}
          onChange={selectCalendar}
          menuPortalTarget={document.getElementById(reactSelectPortalId)}
        />
      </div>
    )
  }

  const onTagsChanged = (updatedTags: string[]) => {
    setTags(updatedTags)
  }
  const renderTagEditor = () => {
    return props.tagsEnabled ? (
      <TagEditor
        tags={tags}
        onTagsChanged={onTagsChanged}
        locationId={logisticsLocation.id}
      />
    ) : undefined
  }

  const renderButtons = () => {
    const onEdit = async () => {
      setLoading(true)

      try {
        await context.addPickupEvent(
          props.selectedPickupCalendar,
          changeType,
          startTime as moment.Moment,
          tags
        )

        props.onClose()
        SnackbarController.show(
          <span>{getPickupAddedSnackbarMessage()}</span>,
          'success'
        )
      } catch (e) {
        if (e instanceof DuplicatedPickupError) {
          SnackbarController.show(
            <span>
              {t('calendars.weekly.editPickupModal.samePickupExists', {
                date: e.conflictTime,
              })}
            </span>,
            'danger'
          )
        } else {
          SnackbarController.show(
            <span>
              {t('calendars.weekly.addPickupDialog.pickupAddingFailed')}
            </span>,
            'danger'
          )
        }
        logError('Error adding pickup', e)
        setLoading(false)
      }
    }

    return (
      <>
        <Button
          type="default"
          onClick={props.onClose}
          key="cancel-btn"
          disabled={loading}
        >
          {t('common.cancel')}
        </Button>
        <Button
          type="primary"
          onClick={onEdit}
          key="save-btn"
          disabled={loading || !isTimeValid(startTime)}
        >
          {t('calendars.weekly.addPickupDialog.title')}
        </Button>
      </>
    )
  }

  const getPickupAddedSnackbarMessage = () => {
    const date = startTime as moment.Moment
    const calendarName = props.selectedPickupCalendar.name

    switch (changeType) {
      case 'current':
        return t('calendars.weekly.addPickupDialog.extraPickupSingle', {
          date,
          calendarName,
        })
      case 'futureDayOfWeek':
        return t('calendars.weekly.addPickupDialog.extraPickupWeekday', {
          date,
          calendarName,
        })
      case 'allFutureDays':
        return t('calendars.weekly.addPickupDialog.extraPickupEveryday', {
          date,
          calendarName,
        })
    }
  }

  const isWorkingDay = (date: moment.Moment) => {
    const year = date.year()
    const month = date.month() + 1
    const day = date.date()

    return (
      context.multiyearInformation[year] &&
      context.multiyearInformation[year][month] &&
      context.multiyearInformation[year][month][day] &&
      context.multiyearInformation[year][month][day].isWorkingDay
    )
  }

  const renderSelectChangeType = () => {
    const getOnlyTodayText = (date: moment.Moment) =>
      t('calendars.weekly.addPickupDialog.forSingleDate', {
        date,
      })

    const getFutureDayOfWeekText = (date: moment.Moment) =>
      t('calendars.weekly.addPickupDialog.forWeekday', {
        date,
      })

    const allFutureWorkingDaysText = t(
      'calendars.weekly.addPickupDialog.forWorkingDays'
    )

    const setCurrent = (
      _: React.ChangeEvent<HTMLInputElement>,
      updatedChangeType: ChangeType
    ) => setChangeType(updatedChangeType)

    if (!isWorkingDay(startTime as moment.Moment)) {
      return <div />
    }

    return (
      <>
        <div style={{ marginTop: '12px', marginBottom: '5px' }}>
          <strong>
            {t('calendars.weekly.addPickupDialog.pickupAppliesTo')}
          </strong>
        </div>
        <RadioGroup
          onChange={setCurrent}
          defaultSelected={'current'}
          name="changeType"
        >
          <Radio
            label={getOnlyTodayText(startTime as moment.Moment)}
            value="current"
          />

          <Radio
            label={getFutureDayOfWeekText(startTime as moment.Moment)}
            value="futureDayOfWeek"
          />

          <Radio label={allFutureWorkingDaysText} value="allFutureDays" />
        </RadioGroup>
      </>
    )
  }

  return (
    <div className="add-pickup-dialog">
      <div className="modal-header">
        <div className="modal-title">
          {t('calendars.weekly.addPickupDialog.title')}
        </div>
        <button className="close" onClick={props.onClose}>
          <shapes.hamburger.Close
            width="18px"
            color={colors.granite.base}
            cropped={true}
          />
        </button>
      </div>

      <div className="modal-body">
        <div style={{ marginBottom: '12px' }}>
          <VenloOnlyWarning />
          {props.startTime
            ? t('calendars.weekly.addPickupDialog.addExtraPickupOn', {
                date: startTime,
              })
            : renderSelectStartTime()}
        </div>

        {renderSelectCalendar()}

        {renderTagEditor()}
        {isTimeValid(startTime) && renderSelectChangeType()}
      </div>

      <div className="modal-footer">{renderButtons()}</div>
    </div>
  )
}

function isTimeValid(time: moment.Moment | string): time is moment.Moment {
  return !(typeof time === 'string') && time.isValid()
}

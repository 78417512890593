import * as React from 'react'
import { YearInformation } from '../models'
import MonthDisplay from './MonthDisplay'

interface Props {
  year: number
  yearData: YearInformation
}

export default function YearlyCalendarView(props: Props) {
  const months = Array.from({ length: 12 }, (_, i) => i + 1)

  return (
    <div className="row scrollWrapper">
      {months.map(month => (
        <div
          className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          key={`${props.year}-${month}`}
        >
          <MonthDisplay
            month={month}
            year={props.year}
            days={props.yearData[month]}
          />
        </div>
      ))}
    </div>
  )
}

import { TextField } from '@cimpress/react-components'
import * as client from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import YearlyCalendarCard from '../YearlyCalendarCard'
import Preloader from '../../../common/components/Preloader'
import { getCountryName } from '../../../i18n'
import { CalendarType } from '../../models'
import { PlantCalendarsLegend } from '../legend/CalendarLegend'
import CountryCalendarLegendRow from '../legend/CountryCalendarLegendRow'
import { SelectCalendar } from '../SelectCalendar'
import YearlyCalendar from '../YearlyCalendar'
import { useCountryCalendarContext } from './CountryCalendarContext'
import styles from './CountryCalendarView.module.css'

interface Props {
  calendar?: client.calendars.models.WorkingDaysCalendar
  lastAccessedCalendars: string[] | undefined
  updateCalendar: (
    updater: (calendar: client.calendars.models.WorkingDaysCalendar) => void
  ) => Promise<void>
  changeCalendarView(type: CalendarType): void
}

export default function CountryCalendarView(props: Props) {
  const yearlyCalendar = props.calendar ? (
    <YearlyCalendar
      editable={true}
      updateCalendar={props.updateCalendar}
      calendar={props.calendar}
      changeCalendarView={props.changeCalendarView}
      calenderView={'working-days'}
    />
  ) : (
    <YearlyCalendarCard>
      <Preloader />
    </YearlyCalendarCard>
  )

  return (
    <>
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 calendar-legend container padding-bottom-full">
        <div
          className="card"
          style={{
            height: '100%',
          }}
        >
          <div className="card-block flex-vertical calendar-content">
            <SelectCalendar disabled={false} />
            <PlantCalendarsLegend />
            <SelectCountrySchedule
              lastAccessedCalendars={props.lastAccessedCalendars}
            />
          </div>
        </div>
      </div>
      {yearlyCalendar}
    </>
  )
}

function SelectCountrySchedule(props: {
  lastAccessedCalendars: string[] | undefined
}) {
  const { t } = useTranslation()
  const context = useCountryCalendarContext()
  const lastAccessedCalendars = props.lastAccessedCalendars
  const [search, setSearch] = React.useState('')
  if (!lastAccessedCalendars) {
    return (
      <div className={styles.selectCountrySchedule}>
        <h5>{t('calendars.country.countryCalendars')}</h5>
        <div className="overflow-y-only">
          <Preloader small={true} />
        </div>
      </div>
    )
  }

  const searchChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value)
  }

  const alphabetical = Object.keys(context.countryCalendarMapping)
    .filter(c => !lastAccessedCalendars.includes(c))
    .map(c => ({
      label: `${getCountryName(c)} (${c})`,
      value: c,
    }))
    .sort((tc1, tc2) => tc1.label.localeCompare(tc2.label))

  const lastAccessed = lastAccessedCalendars.map(c => ({
    label: `${getCountryName(c)} (${c})`,
    value: c,
  }))

  const filtered = [...lastAccessed, ...alphabetical].filter(c =>
    c.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  )

  return (
    <div className={styles.selectCountrySchedule}>
      <h5>{t('calendars.country.countryCalendars')}</h5>
      <>
        <TextField
          name="search"
          label={t('common.search')}
          onChange={searchChange}
          value={search}
        />
        <div className="overflow-y-only">
          {filtered.length === 0 ? (
            <p style={{ textAlign: 'center' }}>
              <small>{t('calendars.noMatchingCalendars')}</small>
            </p>
          ) : (
            filtered.map(tc => (
              <CountryCalendarLegendRow key={tc.value} country={tc} />
            ))
          )}
        </div>
      </>
    </div>
  )
}

import { calendars } from '@cimpress-technology/logistics-configuration-client'
import { EditorPickupSchedule } from '../../../../models'

export function mapScheduleToEditor(
  pickupSchedule: calendars.models.WeeklySchedule<calendars.models.Pickup[]>
): EditorPickupSchedule[] {
  const editorSchedules: {
    [time: string]: EditorPickupSchedule
  } = {}
  weekdays.forEach(day => {
    pickupSchedule.schedule[day].forEach((pickup: calendars.models.Pickup) => {
      if (!editorSchedules[pickup.time]) {
        editorSchedules[pickup.time] = getEmptySchedule()
        editorSchedules[pickup.time].time = pickup.time
      }
      editorSchedules[pickup.time].weeklySchedule.schedule[day] = true
    })
  })

  return Object.keys(editorSchedules).map(k => editorSchedules[k])
}

export const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export function getDefaultSchedule(): EditorPickupSchedule {
  return {
    weeklySchedule: {
      schedule: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
      },
      validFrom: '',
    },
    time: '09:00',
    tags: [],
  }
}

export function getEmptySchedule(): EditorPickupSchedule {
  return {
    weeklySchedule: {
      schedule: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      validFrom: '',
    },
    time: '',
    tags: [],
  }
}

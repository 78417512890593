import moment from 'moment-timezone'
import { DayCell } from '../models'

export function getCellClasses(
  today: moment.Moment,
  year: number,
  month: number,
  baseClass: string,
  editable: boolean,
  day?: DayCell,
  selectedDay?: number
): string {
  const classes: string[] = [baseClass]

  if (!day) {
    classes.push('empty')

    return classes.join(' ')
  }

  const current = moment([year, month - 1, day.day])
  if (current.isBefore(today, 'day')) {
    classes.push('rdtOld')
  } else if (current.isSame(today, 'day')) {
    classes.push('rdtToday')
  }
  if (day.dayInformation && !day.dayInformation.isWorkingDay) {
    classes.push('rdtDisabled')
  }
  if (day.dayInformation && day.dayInformation.exceptionInformation) {
    if (day.dayInformation.exceptionInformation.reason) {
      classes.push('exception')
    }
    if (day.dayInformation.exceptionInformation.tag) {
      classes.push(day.dayInformation.exceptionInformation.tag)
    }
  }

  if (day.day === selectedDay) {
    classes.push('selected')
  }

  if (!editable) {
    classes.push('disabledCell')
  }

  return classes.join(' ')
}

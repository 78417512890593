import { Alert } from '@cimpress/react-components'
import * as React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

interface Props {
  network: { id: string; name: string }
  isNetworkAdmin: boolean
}

export default function ReadOnlyCalendarMessage(props: Props) {
  const name = props.network.name
  const networkId = props.network.id

  const noRights = (
    <Trans i18nKey="calendars.transit.cannotChangeNoRights">
      You cannot change transit calendars as they are shared from the
      <strong>{{ name }}</strong>
      network. If you need to, please contact
      <a href="mailto:logisticssupport@cimpress.com">Logistics Support</a>
    </Trans>
  )
  const gotoNetwork = (
    <Trans i18nKey="calendars.transit.cannotChangeGotoNetwork">
      You cannot change transit calendars in this location as they are shared
      from the <strong>{{ name }}</strong> network. If you need to, go to
      <Link to={`/network/${networkId}/calendars/transit`}>
        Network Transit Calendars
      </Link>
      and change it there.
    </Trans>
  )

  return (
    <Alert
      type="info"
      dismissible={false}
      message={props.isNetworkAdmin ? gotoNetwork : noRights}
    />
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as uuid from 'uuid'
import { SnackbarController } from '../../../../../common/components/SnackbarController'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'
import * as calendarsStore from '../../../../../common/proxy/calendars-store'
import { useLogisticsLocation } from '../../../../../locations/LocationContext'
import { CreatePickupCalendar, EditCalendar } from '../../../../models'
import CalendarCreateModal from '../../../CalendarCreateModal'
import useWeeklyCalendarContext from '../../WeeklyCalendarContext'
import { getInitialPickupCalendarState } from '../../../pickup-calendar-modal-commons'
import { PickupScheduleEditorContainer } from './PickupScheduleEditor'

interface Props {
  carrierServicesToCalendarNames: { [key: string]: string }
  caasProfiles: EnhancedCaasProfile[]
  onClose: () => void
}

export default function PickupCalendarAddModalContainer(props: Props) {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()
  const { logisticsLocation, updateLocation } = useLogisticsLocation()

  const save = async (data: CreatePickupCalendar) => {
    const pickupCalendar = getInitialPickupCalendarState(logisticsLocation)
    pickupCalendar.name = data.calendarName
    pickupCalendar.weeklySchedules = [data.weeklySchedule]
    const correlationId = uuid.v4()

    const pickupCalendarId = await calendarsStore.createPickupCalendar(
      pickupCalendar,
      correlationId
    )

    try {
      const carrierServices = data.carrierSelection.carrierServices
      await updateLocation(locationToUpdate => {
        if (carrierServices && carrierServices.length > 0) {
          calendarsStore.addCalendarToMapping(
            locationToUpdate.pickupCalendars.mapping,
            pickupCalendarId,
            carrierServices
          )
        }
      })
      await context.loadCalendarData()
      SnackbarController.show(
        t('calendars.weekly.pickupCalendarsLegend.pickupCalendarCreated', {
          name: pickupCalendar.name,
        }),
        'success'
      )
      props.onClose()
    } catch (e) {
      await calendarsStore.deletePickupCalendar(pickupCalendarId, correlationId)
      SnackbarController.show(
        t(
          'calendars.weekly.pickupCalendarsLegend.creatingPickupCalendarFailed',
          { name: pickupCalendar.name }
        ),
        'danger'
      )
    }
  }

  return (
    <PickupCalendarCreateModal
      onClose={props.onClose}
      onSave={save}
      carrierAccountTypes={props.caasProfiles}
      carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
    />
  )
}

interface PickupCalendarCreateModalProps {
  carrierServicesToCalendarNames: { [key: string]: string }
  carrierAccountTypes: EnhancedCaasProfile[]
  onSave: (data: CreatePickupCalendar) => void
  onClose: () => void
}

function PickupCalendarCreateModal(props: PickupCalendarCreateModalProps) {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  const [weeklySchedule, setWeeklySchedule] = React.useState(
    getInitialPickupCalendarState(logisticsLocation).weeklySchedules[0]
  )

  const onSave = async (data: EditCalendar) => {
    await props.onSave({ ...data, weeklySchedule })
  }

  return (
    <CalendarCreateModal
      title={t('calendars.weekly.addPickupCalendarDialogButton')}
      primaryButtonText={t(
        'calendars.weekly.addPickupCalendarModal.addSchedule'
      )}
      primaryButtonSavingText={t(
        'calendars.weekly.addPickupCalendarModal.addingSchedule'
      )}
      carrierAccountTypes={props.carrierAccountTypes}
      carrierServicesToCalendarNames={props.carrierServicesToCalendarNames}
      onClose={props.onClose}
      onSave={onSave}
    >
      <div style={{ marginTop: '20px' }}>
        <h5>{t('calendars.weekly.addPickupCalendarModal.timeOfPickup')}</h5>
        <PickupScheduleEditorContainer onChange={setWeeklySchedule} />
      </div>
    </CalendarCreateModal>
  )
}

import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import * as locationsStore from '../../common/proxy/locations-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { locationRoles } from '../models'
import PermissionsEditor from './PermissionsEditor'

export default function LocationPermissionEditor() {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()

  const addUser = (email: string, role: string) =>
    locationsStore.addUser(logisticsLocation.id, email, role)
  const removeUser = (email: string) =>
    locationsStore.removeUser(logisticsLocation.id, email)

  return (
    <>
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {t('userManagement.userManagement')}
      </BreadcrumbsItem>
      <PermissionsEditor
        resourceId={logisticsLocation.id}
        isResourceEditable={logisticsLocation.editable}
        resourceGroupName={`Logistics location (${logisticsLocation.id})`}
        resourceType={coam.models.ResourceTypes.LogisticsLocation}
        roles={locationRoles}
        addUser={addUser}
        removeUser={removeUser}
      />
    </>
  )
}

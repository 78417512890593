// https://flatuicolors.com/palette/nl
export const pickupColors: string[] = [
  '#E65100',
  '#039BE5',
  '#43A047',
  '#D81B60',
  '#FBC02D',
  '#3F51B5',
  '#D32F2F',
  '#F9A825',
  '#2E7D32',
  '#9C27B0',
  '#FF6F00',
  '#C0CA33',
  '#1976D2',
  '#FF8F00',
  '#673AB7',
  '#7CB342',
  '#AD1457',
  '#BB4D00',
  '#00796B',
  '#283593',
  '#D84315',
  '#0097A7',
  '#B91400',
  '#4527A0',
  '#99AA00',
  '#6A1B9A',
  '#005B4F',
  '#1565C0',
  '#558B2F',
  '#0277BD',
  '#9A0007',
]

import { Checkbox, CheckboxProps } from '@cimpress/react-components'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import styles from './ColorCheckbox.module.css'

interface Props extends CheckboxProps {
  color: string
}

export class ColorCheckbox extends React.Component<Props> {
  public render() {
    return (
      <Checkbox
        {...this.props}
        className={
          styles['color-checkbox'] +
          ' ' +
          (this.props.checked ? styles.checked : styles.unchecked)
        }
      />
    )
  }

  public componentDidMount() {
    this.updateColor()
  }

  public componentDidUpdate() {
    this.updateColor()
  }

  private updateColor() {
    const node = ReactDOM.findDOMNode(this) as HTMLElement
    node.style.setProperty('--color', this.props.color)
  }
}

import { colors } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AnchorButton from '../../../../common/components/AnchorButton'
import { Location } from '../../../../common/models'
import { EnhancedCaasProfile } from '../../../../common/models-carriers'
import { getCaasProfiles } from '../../../../common/proxy/carrier-services-store'
import { useLogisticsLocation } from '../../../../locations/LocationContext'
import { PickupCalendarDaysWithLegend } from '../../../models'
import { useShowElement } from '../../../../common/hooks/useShowElement'
import NewCalendarButton from '../../NewCalendarButton'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import PickupCalendarDeleteModal from '../../PickupCalendarDeleteModal'
import PickupCalendarAddModalContainer from './pickup-calendar-editor/PickupCalendarAddModalContainer'
import PickupCalendarEditModalContainer from './pickup-calendar-editor/PickupCalendarEditModalContainer'
import { PickupCalendarLegendRow } from './PickupCalendarLegendRow'
import './PickupCalendarsLegend.css'

export function PickupCalendarsLegend(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <h5>{t('common.calendars.pickupCalendars')}</h5>
      <PickupCalendarsLegendList />
    </>
  )
}

export function PickupCalendarsLegendList(): JSX.Element {
  const { t } = useTranslation()
  const context = useWeeklyCalendarContext()
  const { logisticsLocation } = useLogisticsLocation()

  const [showAddModal, openAddModal, closeAddModal] = useShowElement()
  const [showEditModal, openEditModal, closeEditModal] = useShowElement()
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useShowElement()

  const [caasProfiles, setCaasProfiles] = React.useState<EnhancedCaasProfile[]>(
    []
  )
  const [loading, setLoading] = React.useState(true)

  const [pickupCalendarId, setPickupCalendarId] = React.useState<string>()
  const onEdit = (currentPickupCalendarId: string) => {
    setPickupCalendarId(currentPickupCalendarId)
    openEditModal()
  }
  const onDelete = (currentPickupCalendarId: string) => {
    setPickupCalendarId(currentPickupCalendarId)
    openDeleteModal()
  }

  React.useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      const profiles = await getCaasProfiles()
      if (mounted) {
        setCaasProfiles(profiles)
        setLoading(false)
      }
    }
    fetchData()

    return () => {
      mounted = false
    }
  }, [logisticsLocation])

  const carrierServicesToCalendarNames = mapCarrierServicesToPickupCalendarNames(
    logisticsLocation,
    context.pickupCalendarDaysWithLegend
  )

  const ModalProps = {
    caasProfiles,
    carrierServicesToCalendarNames,
  }

  const selectAll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    context.onPickupCalendarSelectAll()
  }

  const deselectAll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    context.onPickupCalendarDeselectAll()
  }

  const legendPickupCalendars = context.pickupCalendarDaysWithLegend.map(
    pc => pc.pickupCalendarDisplay
  )

  const addPickupCalendar = (
    <>
      {logisticsLocation.editable && (
        <NewCalendarButton
          onClick={openAddModal}
          text={t('calendars.weekly.addPickupCalendarDialogButton')}
        />
      )}
      {showAddModal && (
        <PickupCalendarAddModalContainer
          onClose={closeAddModal}
          {...ModalProps}
        />
      )}
    </>
  )

  if (legendPickupCalendars.length === 0) {
    return (
      <>
        <div
          className="text-muted"
          style={{
            marginTop: '16px',
            marginBottom: '16px',
            textAlign: 'center',
            color: colors.slate,
          }}
        >
          <small>
            {t('calendars.weekly.pickupCalendarsLegend.noPickupCalendars')}
          </small>
        </div>
        {addPickupCalendar}
      </>
    )
  }

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <AnchorButton onClick={selectAll}>
          {t('calendars.weekly.pickupCalendarsLegend.selectAll')}
        </AnchorButton>
        {' / '}
        <AnchorButton onClick={deselectAll}>
          {t('calendars.weekly.pickupCalendarsLegend.clear')}
        </AnchorButton>
      </div>
      <div style={{ minHeight: '0' }}>
        <div className="scrollWrapper">
          <div className="pickupCalendarList">
            <ul className="fa-ul">
              {legendPickupCalendars
                .sort((a, b) => a.calendar.name.localeCompare(b.calendar.name))
                .map(pc => (
                  <PickupCalendarLegendRow
                    loading={loading}
                    pickupCalendarDisplay={pc}
                    key={pc.calendar.id}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                ))}
            </ul>
          </div>
        </div>
      </div>
      {addPickupCalendar}
      {showEditModal && (
        <PickupCalendarEditModalContainer
          onClose={closeEditModal}
          pickupCalendarId={pickupCalendarId!}
          {...ModalProps}
        />
      )}
      {showDeleteModal && pickupCalendarId && (
        <PickupCalendarDeleteModal
          onClose={closeDeleteModal}
          calendarId={pickupCalendarId}
        />
      )}
    </>
  )
}

function mapCarrierServicesToPickupCalendarNames(
  location: Location,
  pickupCalendars: PickupCalendarDaysWithLegend[]
) {
  return Object.keys(location.pickupCalendars.mapping).reduce((acc, curr) => {
    const calendar = pickupCalendars.find(pc => {
      return (
        pc.pickupCalendarDisplay.calendar.id ===
        location.pickupCalendars.mapping[curr].id
      )
    })

    if (calendar) {
      acc[curr] = calendar.pickupCalendarDisplay.calendar.name
    }

    return acc
  }, {} as any)
}

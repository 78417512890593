import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment-timezone'
import { Moment } from 'moment-timezone/moment-timezone'
import { clone } from '../../../common/helpers/clone'
import { isSamePickup } from '../../../common/helpers/pickups'
import { ChangeType, PickupCalendar, Weekday } from '../../models'
import { findCurrentSchedule, getLowercaseWeekday } from './pickup-calendar'

type UpdateFunction = (
  o: calendars.models.Pickup[],
  checkDate: string
) => calendars.models.Pickup[]

const weekdays: Weekday[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export class DuplicatedPickupError extends Error {
  public conflictTime: Moment
  constructor(message: string, conflictTime: Moment) {
    super(message)
    this.name = 'DuplicatedPickupError'
    this.conflictTime = conflictTime
  }
}

export function removePickup(
  originalCalendar: PickupCalendar,
  changeType: ChangeType,
  date: moment.Moment,
  tags: string[]
): PickupCalendar {
  const time = date.format('HH:mm')
  const removedPickup = { time, tags }
  const updateFunction = (o: calendars.models.Pickup[]) =>
    o.filter(pickup => !isSamePickup(removedPickup, pickup))

  return updatePickup(originalCalendar, changeType, date, updateFunction)
}

export function updatePickup(
  originalCalendar: PickupCalendar,
  changeType: ChangeType,
  date: moment.Moment,
  updateFunction: UpdateFunction
): PickupCalendar {
  const dateString = date.format('YYYY-MM-DD')
  const calendar = clone(originalCalendar)

  if (changeType === 'current') {
    const overwrites = getPickupTimesForDay(calendar, date)
    const updated = updateFunction(overwrites, dateString)
    const updatedCalendar = {
      ...calendar,
      overwrites: {
        ...calendar.overwrites,
        [dateString]: updated,
      },
    }
    validateCalendar(updatedCalendar)

    return updatedCalendar
  } else {
    const schedules = splitSchedulesOnDay(calendar.weeklySchedules, date)
    const daySelector =
      changeType === 'futureDayOfWeek'
        ? (day: Weekday) => day === getLowercaseWeekday(date)
        : () => true
    const updatedOverwrites = mapOverwrites((d, times) => {
      if (d >= dateString && daySelector(getLowercaseWeekday(moment(d)))) {
        return updateFunction(times, d)
      }

      return times
    }, calendar.overwrites)
    const updatedSchedules = schedules.map(s => {
      const schedule = mapSchedule((day, times) => {
        if (s.validFrom >= dateString && daySelector(day)) {
          return updateFunction(times, s.validFrom)
        }

        return times
      }, s.schedule)

      return {
        schedule,
        validFrom: s.validFrom,
      }
    })

    const updated = {
      ...calendar,
      weeklySchedules: updatedSchedules,
      overwrites: updatedOverwrites,
    }

    validateCalendar(updated)

    return updated
  }
}

export function editPickup(
  originalCalendar: PickupCalendar,
  changeType: ChangeType,
  date: moment.Moment,
  tags: string[],
  newTime: string,
  updatedTags: string[]
): PickupCalendar {
  const time = date.format('HH:mm')
  const oldPickup = { time, tags }
  const updateFunction = (o: calendars.models.Pickup[], checkDate: string) => {
    const newPickup = { time: newTime, tags: updatedTags }
    const updated = o.map(pickup => {
      if (isSamePickup(pickup, oldPickup)) {
        return newPickup
      }

      return pickup
    })
    updated.sort((p1, p2) => p1.time.localeCompare(p2.time))

    return updated
  }

  return updatePickup(originalCalendar, changeType, date, updateFunction)
}

export function addPickup(
  originalCalendar: PickupCalendar,
  changeType: ChangeType,
  date: moment.Moment,
  tags: string[]
): PickupCalendar {
  const time = date.format('HH:mm')
  const newPickup = { time, tags }
  const updateFunction = (pickups: calendars.models.Pickup[]) => {
    if (!pickups.some(old => isSamePickup(old, newPickup))) {
      pickups.push(newPickup)
    }
    pickups.sort((p1, p2) => p1.time.localeCompare(p2.time))

    return pickups
  }

  return updatePickup(originalCalendar, changeType, date, updateFunction)
}

export function splitSchedulesOnDay(
  schedules: Array<calendars.models.WeeklySchedule<calendars.models.Pickup[]>>,
  date: moment.Moment
): Array<calendars.models.WeeklySchedule<calendars.models.Pickup[]>> {
  const dateString = date.format('YYYY-MM-DD')
  let result: Array<calendars.models.WeeklySchedule<
    calendars.models.Pickup[]
  >> = []
  const currentSchedule = findCurrentSchedule(schedules, date)
  const pastSchedules = schedules.filter(
    s => s.validFrom < dateString && s !== currentSchedule
  )
  result = result.concat(pastSchedules)
  if (currentSchedule) {
    result.push(currentSchedule)
    if (currentSchedule.validFrom !== dateString) {
      const split = {
        schedule: JSON.parse(JSON.stringify(currentSchedule.schedule)),
        validFrom: dateString,
      }
      result.push(split)
    }
  }

  const futureSchedules = schedules.filter(s => s.validFrom > dateString)
  result = result.concat(futureSchedules)

  return result
}

export function mapSchedule(
  updater: (
    day: keyof calendars.models.WeekdayData<calendars.models.Pickup[]>,
    times: calendars.models.Pickup[]
  ) => calendars.models.Pickup[],
  schedule: calendars.models.WeekdayData<calendars.models.Pickup[]>
): calendars.models.WeekdayData<calendars.models.Pickup[]> {
  const duplicate = clone(schedule)
  weekdays.forEach(w => {
    const val = duplicate[w]
    duplicate[w] = updater(w, val)
  })

  return duplicate
}

export function mapOverwrites(
  updater: (
    date: string,
    times: calendars.models.Pickup[]
  ) => calendars.models.Pickup[],
  overwrites: calendars.models.Days<calendars.models.Pickup[]>
) {
  const duplicate = clone(overwrites)
  for (const date in duplicate) {
    if (Object.prototype.hasOwnProperty.call(duplicate, date)) {
      duplicate[date] = updater(date, duplicate[date])
    }
  }

  return duplicate
}

export function getPickupTimesForDay(
  calendar: PickupCalendar,
  date: moment.Moment
): calendars.models.Pickup[] {
  const dateString = date.format('YYYY-MM-DD')
  const overwrite = calendar.overwrites[dateString]
  if (overwrite) {
    return overwrite.slice(0)
  }

  const currentSchedule = findCurrentSchedule(calendar.weeklySchedules, date)
  if (currentSchedule === undefined) {
    return []
  }

  const day = getLowercaseWeekday(date)
  const times = currentSchedule.schedule[day]

  return times.slice(0)
}

function validateCalendar(calendar: PickupCalendar) {
  Object.entries(calendar.overwrites).forEach(([key, value]) => {
    const dup = firstDuplicate(value)
    if (dup) {
      throw new DuplicatedPickupError(
        'Conflict pickup found',
        moment(`${key}T${dup}`)
      )
    }
  })

  calendar.weeklySchedules.forEach(weeklySchedule => {
    Object.entries(weeklySchedule.schedule).forEach(([key, value]) => {
      const dup = firstDuplicate(value)
      if (dup) {
        throw new DuplicatedPickupError(
          'Conflict pickup found',
          moment(`${weeklySchedule.validFrom}T${dup}`)
        )
      }
    })
  })
}

function firstDuplicate(pickups: calendars.models.PickupScheduleDay) {
  const memory = {}
  for (const pickup of pickups) {
    const key = `${pickup.time}-${pickup.tags.join(',')}`
    if (memory[key] !== undefined) {
      return pickup.time
    }
    memory[key] = pickup
  }

  return undefined
}

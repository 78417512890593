import { Radio } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ContextMenu from '../../../common/components/ContextMenu'
import ContextMenuItem from '../../../common/components/ContextMenuItem'
import { TransitCalendarWithData } from '../transit-calendar-commons'
import styles from '../TransitCalendarView.module.css'
import { useLogisticsLocation } from '../../../locations/LocationContext'
import { AdvancedUserContainer } from '../../../common/components/AdvancedUserContainer'

interface Props {
  selectedCalendar: TransitCalendarWithData | undefined
  transitCalendar: TransitCalendarWithData
  editable: boolean
  onChange: (transitCalendarId: string) => void
  onEdit: (calendarToEdit: TransitCalendarWithData) => void
  onDelete: (calendarToDelete: TransitCalendarWithData) => void
}

export default function TransitCalendarLegendRow(props: Props) {
  const { t } = useTranslation()
  const { logisticsLocation } = useLogisticsLocation()
  const history = useHistory()

  const onChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    transitCalendarId: string
  ) => {
    props.onChange(transitCalendarId)
  }

  const onEdit = () => {
    props.onEdit(props.transitCalendar)
  }

  const onDelete = () => {
    props.onDelete(props.transitCalendar)
  }

  const goToHistory = () => {
    const url = logisticsLocation
      ? `/location/${logisticsLocation.id}/history?resourceId=${props.transitCalendar.calendarId}`
      : `/audits/calendar/${props.transitCalendar.calendarId}`
    history.push(url)
  }

  const checked = props.selectedCalendar
    ? props.selectedCalendar.calendarId === props.transitCalendar.calendarId
    : undefined

  const labelText = props.transitCalendar.workingDaysCalendar.name || ''

  const label = checked ? (
    <span style={{ fontWeight: 600 }}>{labelText}</span>
  ) : (
    labelText
  )

  return (
    <div
      key={props.transitCalendar.calendarId}
      className={`flex ${styles.rowHeight}`}
    >
      <Radio
        onCheck={onChange}
        checked={
          props.selectedCalendar
            ? props.selectedCalendar.calendarId ===
              props.transitCalendar.calendarId
            : undefined
        }
        value={props.transitCalendar.calendarId}
        className={styles.transitCalendarRow}
        label={label}
      />
      <ContextMenu className={styles.transitCalendarRowContext}>
        {!props.editable && (
          <ContextMenuItem
            onClick={onEdit}
            label={t('calendars.showDetails')}
          />
        )}
        {props.editable && (
          <ContextMenuItem
            onClick={onEdit}
            label={t('calendars.editCalendar')}
          />
        )}
        {props.editable && (
          <AdvancedUserContainer>
            <ContextMenuItem onClick={onDelete} label={t('common.delete')} />
          </AdvancedUserContainer>
        )}
        <ContextMenuItem
          onClick={goToHistory}
          label={
            <>
              {t('common.history')}{' '}
              <span className="badge badge-info">beta</span>
            </>
          }
        />
      </ContextMenu>
      <div className={styles.transitCalendarRowScrollbar} />
    </div>
  )
}

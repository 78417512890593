import { Button, Modal } from '@cimpress/react-components'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AddPickupDialog from './AddPickupDialog'
import useWeeklyCalendarContext from './WeeklyCalendarContext'

export function AddPickupDialogButton() {
  const [shouldShowDialog, setShowDialog] = React.useState(false)
  const showDialog = () => setShowDialog(true)
  const hideDialog = () => setShowDialog(false)
  const { t } = useTranslation()

  const context = useWeeklyCalendarContext()

  const [selectedPickupCalendar, setSelectedPickupCalendar] = React.useState<
    calendars.models.PickupCalendar
  >()
  React.useEffect(() => {
    const pickupCalendarDisplays = context.pickupCalendarDaysWithLegend
      .map(pcl => pcl.pickupCalendarDisplay)
      .filter(pcd => pcd.editable)
    const firstActiveCalendar = pickupCalendarDisplays.find(c => c.active)
    const firstCalendar =
      pickupCalendarDisplays.length > 0
        ? pickupCalendarDisplays[0].calendar
        : undefined
    setSelectedPickupCalendar(
      firstActiveCalendar ? firstActiveCalendar.calendar : firstCalendar
    )
  }, [context.pickupCalendarDaysWithLegend])

  if (!selectedPickupCalendar) {
    return <></>
  }

  return (
    <>
      <Button size="sm" type="primary" onClick={showDialog}>
        <i className="fa fa-fw fa-plus" />
        {t('calendars.weekly.addPickupDialogButton')}
      </Button>
      {shouldShowDialog ? (
        <Modal show={true} className="add-pickup-dialog-modal">
          <AddPickupDialog
            onClose={hideDialog}
            tagsEnabled={context.tagsEnabled}
            selectedPickupCalendar={selectedPickupCalendar}
            onSelectPickupCalendar={setSelectedPickupCalendar}
          />
        </Modal>
      ) : null}
    </>
  )
}

import { Tooltip } from '@cimpress/react-components'
import { calendars } from '@cimpress-technology/logistics-configuration-client'
import moment from 'moment-timezone'
import * as React from 'react'
import { reactSelectPortalId } from '../../../../common/models'
import AddPickupDialog from '../AddPickupDialog'
import useWeeklyCalendarContext from '../WeeklyCalendarContext'
import styles from './AddPickupDialogTooltip.module.css'

interface Props {
  startTime: moment.Moment
  tagsEnabled: boolean
  onClose: () => void
  children?: React.ReactNode
}

export default function AddPickupDialogTooltip(props: Props) {
  const context = useWeeklyCalendarContext()
  const [selectedPickupCalendar, setSelectedPickupCalendar] = React.useState<
    calendars.models.PickupCalendar
  >()
  React.useEffect(() => {
    const pickupCalendarDisplays = context.pickupCalendarDaysWithLegend
      .map(pcl => pcl.pickupCalendarDisplay)
      .filter(pcd => pcd.editable)
    const firstActiveCalendar = pickupCalendarDisplays.find(c => c.active)
    const firstCalendar = pickupCalendarDisplays[0].calendar
    setSelectedPickupCalendar(
      firstActiveCalendar ? firstActiveCalendar.calendar : firstCalendar
    )
  }, [context.pickupCalendarDaysWithLegend])

  const pickupTime = props.startTime.format('HH:mm')

  const tooltipContents = (
    <AddPickupDialog
      startTime={props.startTime}
      onClose={props.onClose}
      selectedPickupCalendar={selectedPickupCalendar!}
      onSelectPickupCalendar={setSelectedPickupCalendar}
      tagsEnabled={props.tagsEnabled}
    />
  )

  return (
    <div style={{ position: 'relative' }}>
      <Tooltip
        outsideClickIgnoreClass={reactSelectPortalId}
        contents={tooltipContents}
        direction="top"
        variety="popover"
        show={true}
        style={{ display: 'block', width: '100%', height: '100%' }}
        onClickOutside={props.onClose}
        constraints={[
          {
            to: 'window',
            attachment: 'together',
            pin: true,
          },
        ]}
      >
        {props.children}
        <div className={styles.pickupEventPreview}>
          <div className={styles.carrierName}>
            {selectedPickupCalendar ? selectedPickupCalendar.name : ''}
          </div>
          <div>{pickupTime}</div>
        </div>
      </Tooltip>
    </div>
  )
}

import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationContainer } from '../../../../../common/components/ValidationContainer'
import { ValidationResult } from '../../../../../common/helpers/validation'
import { Option, reactSelectPortalId } from '../../../../../common/models'
import { EnhancedCaasProfile } from '../../../../../common/models-carriers'
import { filterAvailableCaasProfiles } from '../../../../../common/proxy/carrier-services-store'

interface Props {
  caasProfiles: EnhancedCaasProfile[]
  carriers: string[]
  carrierValidation: ValidationResult
  isDisabled: boolean
  onChange(carrier?: string): void
}

export default function CarrierSelector(props: Props) {
  const { t } = useTranslation()

  const filteredCaasProfiles = filterAvailableCaasProfiles(props.caasProfiles)

  const carrierOptions: Option[] = filteredCaasProfiles
    .map(carrier => ({
      value: carrier.key,
      label: carrier.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onCarrierChange = (changeData: Option) => {
    props.onChange(changeData.value)
  }
  if (props.carriers.length > 1) {
    const joinedNames = filteredCaasProfiles
      .filter(profile => props.carriers.includes(profile.key))
      .map(profile => profile.name)
      .join(', ')

    return (
      <div style={{ marginTop: '20px', marginBottom: '12px' }}>
        <div className="h7">{t('common.carrier')}</div>
        <div>{joinedNames}</div>
      </div>
    )
  }

  return (
    <ValidationContainer validationResult={props.carrierValidation}>
      <Select
        isDisabled={props.isDisabled}
        label={t('carrierAccounts.addCarrierFlow.selectCarrierLabel')}
        value={carrierOptions.filter(o => o.value === props.carriers[0])}
        options={carrierOptions}
        isClearable={false}
        required={true}
        onChange={onCarrierChange}
        menuPortalTarget={document.getElementById(reactSelectPortalId)}
      />
    </ValidationContainer>
  )
}

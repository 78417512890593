import moment from 'moment-timezone'
import * as React from 'react'

import { useTranslation } from 'react-i18next'
import * as models from '../models'
import './Calendars.css'
import { DayCellView } from './DayCellView'
import { useYearlyCalendarContext } from './YearlyCalendarContext'

interface Props {
  month: number
  year: number
  days: models.MonthInformation
}

export default function MonthDisplay(props: Props) {
  const { i18n } = useTranslation()
  const context = useYearlyCalendarContext()
  const momentMonth = props.month - 1

  const grid = calculateGrid(props.year, momentMonth, props.days)

  const dayRows = grid.map((row, i) => (
    <DayCellRow
      key={i.toString()}
      year={props.year}
      momentMonth={momentMonth}
      row={row}
    />
  ))
  const monthClass = getMonthCalendarClass(
    context.today,
    props.year,
    momentMonth
  )

  return (
    <div className="month-display rdtPicker" style={{ display: 'contents' }}>
      <table>
        <thead>
          <tr>
            <th className={monthClass} colSpan={7}>
              {moment.localeData(i18n.language || 'en').months()[momentMonth]}
            </th>
          </tr>
          <tr className={monthClass}>
            <WeekdayColumns />
          </tr>
        </thead>
        <tbody>{dayRows}</tbody>
      </table>
    </div>
  )
}

function DayCellRow(props: {
  row: Array<models.DayCell | undefined>
  year: number
  momentMonth: number
}) {
  const dayViews = props.row.map((day, j) => {
    return (
      <DayCellView
        dayCell={day}
        month={props.momentMonth + 1}
        key={`${j}`}
        year={props.year}
      />
    )
  })

  return <tr>{dayViews}</tr>
}

function getMonthCalendarClass(
  today: moment.Moment,
  year: number,
  month: number
) {
  if (today.year() > year || (today.year() === year && today.month() > month)) {
    return 'past-month'
  }

  return ''
}

export function calculateGrid(
  year: number,
  momentMonth: number,
  days: models.MonthInformation
): Array<Array<models.DayCell | undefined>> {
  const grid: Array<Array<models.DayCell | undefined>> = Array.from(
    { length: 6 },
    _ => Array.from({ length: 7 })
  )
  const month = moment.utc([year, momentMonth])
  // isoWeekday() for monday is 1, we need to update offset so it is 0 for monday
  const monthStartOffset = month.isoWeekday() - 2
  for (let i = 1; i <= month.daysInMonth(); i++) {
    const dayInWeek = (i + monthStartOffset) % 7
    const weekInMonth = Math.floor((i + monthStartOffset) / 7)
    grid[weekInMonth][dayInWeek] = {
      day: i,
      dayInformation: days[i],
    }
  }

  return grid
}

function WeekdayColumns() {
  const { i18n } = useTranslation()

  const dayNames = moment.localeData(i18n.language || 'en').weekdaysMin()

  return (
    <>
      {[1, 2, 3, 4, 5, 6, 0].map(i => (
        <th key={i} className="dow">
          {dayNames[i]}
        </th>
      ))}
    </>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarType, DayDate, DayInformation } from '../models'
import AddExceptionDialog from './AddExceptionDialog'
import { useYearlyCalendarContext } from './YearlyCalendarContext'

interface Props {
  dayDate: DayDate
  dayInformation: DayInformation
  addException: (modifySchedule: boolean, explanation: string) => void
  removeException: () => void
}

export default function ExceptionModal(props: Props) {
  const dayInformation = props.dayInformation
  if (!dayInformation.exceptionInformation) {
    if (dayInformation.isWorkingDay) {
      return <ClosePlant {...props} />
    } else {
      return <OpenPlant {...props} />
    }
  } else {
    if (dayInformation.isWorkingDay) {
      return <CancelOpenPlantException {...props} />
    } else {
      return <CancelClosePlantException {...props} />
    }
  }
}

function CancelOpenPlantException(props: Props) {
  const { t } = useTranslation()
  const context = useYearlyCalendarContext()

  let message: React.ReactNode = t(
    `${getTranslationPath(context.calendarView)}.itsWorkingDay`
  )
  if (
    props.dayInformation.exceptionInformation &&
    props.dayInformation.exceptionInformation.reason
  ) {
    message = (
      <>
        <div>
          {t(
            `${getTranslationPath(context.calendarView)}.itsWorkingDayBecause`
          )}
        </div>
        <div style={{ marginLeft: '8px', marginTop: '16px' }}>
          <i>"{props.dayInformation.exceptionInformation.reason}"</i>
        </div>
      </>
    )
  }

  return (
    <AddExceptionDialog
      message={message}
      actionText={t(
        `${getTranslationPath(context.calendarView)}.changeToNonWorkingDay`
      )}
      addException={props.removeException}
      dayDate={props.dayDate}
    />
  )
}

function CancelClosePlantException(props: Props) {
  const { t } = useTranslation()
  const context = useYearlyCalendarContext()

  let message: React.ReactNode = t(
    `${getTranslationPath(context.calendarView)}.itsNonworkingDay`
  )
  if (
    props.dayInformation.exceptionInformation &&
    props.dayInformation.exceptionInformation.tag === 'holiday'
  ) {
    message = t('calendars.yearly.exceptionModal.holidayReason', {
      reason: props.dayInformation.exceptionInformation.reason,
    })
  } else if (
    props.dayInformation.exceptionInformation &&
    props.dayInformation.exceptionInformation.reason
  ) {
    message = (
      <>
        <div>
          {t(
            `${getTranslationPath(
              context.calendarView
            )}.itsNonWorkingDayBecause`
          )}
        </div>
        <div style={{ marginLeft: '8px', marginTop: '16px' }}>
          <i>"{props.dayInformation.exceptionInformation.reason}"</i>
        </div>
      </>
    )
  }

  return (
    <AddExceptionDialog
      message={message}
      actionText={t(
        `${getTranslationPath(context.calendarView)}.changeToWorkingDay`
      )}
      addException={props.removeException}
      dayDate={props.dayDate}
    />
  )
}

function ClosePlant(props: Props) {
  const { t } = useTranslation()
  const context = useYearlyCalendarContext()

  return (
    <AddExceptionDialog
      message={t(`${getTranslationPath(context.calendarView)}.itsWorkingDay`)}
      actionText={t(
        `${getTranslationPath(context.calendarView)}.changeToNonWorkingDay`
      )}
      addException={props.addException}
      dayDate={props.dayDate}
    />
  )
}

function OpenPlant(props: Props) {
  const { t } = useTranslation()
  const context = useYearlyCalendarContext()

  let message = t(
    `${getTranslationPath(context.calendarView)}.itsNonworkingDay`
  )
  if (
    props.dayInformation.exceptionInformation &&
    props.dayInformation.exceptionInformation.tag === 'holiday'
  ) {
    message = t('calendars.yearly.exceptionModal.holidayReason', {
      reason: props.dayInformation.exceptionInformation.reason,
    })
  }

  return (
    <AddExceptionDialog
      message={message}
      actionText={t(
        `${getTranslationPath(context.calendarView)}.changeToWorkingDay`
      )}
      addException={props.addException}
      dayDate={props.dayDate}
    />
  )
}

function getTranslationPath(calendarType: CalendarType): string {
  const path = 'calendars.yearly.exceptionModal'

  switch (calendarType) {
    case 'transit':
      return `${path}.transit`
    case 'delivery':
      return `${path}.delivery`
    default:
      return `${path}.workingDay`
  }
}
